import { proxifyUrl } from './Utils';

export async function fetchHiddenIFrame(nonProxyUrl: string): Promise<HTMLIFrameElement> {
  const iframe = document.createElement('iframe');
  iframe.width = "800";
  iframe.height = "1324";

  iframe.style.position = 'absolute';
  iframe.style.top = '-9999px';
  iframe.style.left = '-9999px';
  iframe.style.zIndex = '-999';
  //iframe.hidden = true; // prevents canac from loading image

  // would be nice to have a way to skip images and css to be faster

  iframe.src = proxifyUrl(nonProxyUrl);

  // Hardcoded 3 second after load event, but if page takes longer to ajax after load, won't work correctly!!
  return new Promise(resolve => {
    iframe.addEventListener('load', () => {
      setTimeout(() => {
        resolve(iframe);
      }, 3000);
    });
    document.body.appendChild(iframe);
  });

  return null;

}