import { DRAWING_SCALE } from 'constants/Constants';
import { DrawingProjections } from 'constants/DrawingProjections';
import { MeasurementType } from 'constants/MeasurementType';
import { compact } from 'lodash';
import { computed, observable } from "mobx";
import { computedFn } from "mobx-utils";
import Point from "models/Point";
import Shape from "models/Shape";
import { serializable } from "serializr";
import Stores from 'stores/Stores';
import { isClockwise } from "utils/GeometryUtils";
import { OFFSET_LENGTH_FUNCTION, getFunctionParameters } from "utils/MeasurementFormatter";
import { getDrawingProjectionValue } from 'utils/MeasurementUtil';
import { getSafe } from "utils/Utils";
import Surface from './Surface';

export default class Line extends Shape {
  @serializable type = 'Line';

  @computed get isEditMode() {
    return this.stores.drawToolsStore.lineBeingAdded === this;
  }

  @computed get startPt(): Point {
    return this.points[0];
  }
  set startPt(value: Point) {
    this.points[0] = value;
  }

  @computed get endPt(): Point {
    return this.points[1];
  }
  set endPt(value: Point) {
    this.points[1] = value;
  }

  @computed get midPt(): Point {
    const retval = new Point((this.startPt.x + this.endPt.x) / 2, (this.startPt.y + this.endPt.y) / 2);
    retval.isSnappable = this.endPt.isSnappable;
    return retval;
  }

  constructor(stores: Stores, startPt: Point = new Point(), endPt: Point = new Point()) {
    super(stores, [startPt, endPt]);
  }

  @computed get length(): number { // in meters
    return this.startPt.distance(this.endPt, DRAWING_SCALE);
  }

  @computed get angleDegrees(): number {
    return -Math.atan2(this.points[1].y - this.points[0].y, this.points[1].x - this.points[0].x) * 180 / Math.PI;
  }

  @computed get roofLength(): number {
    if (!this.treeNode) {
      return this.length;
    }
    // semi duplicate
    const roofParams = [MeasurementType.RoofSlopeRatio, MeasurementType.RoofSlopeDirection, MeasurementType.DrawingProjection];

    const roofMeasurementValues = this.treeNode.ancestorOrNodeMeasurementValuesArray
      .filter(measurementValue => Object.values(roofParams).includes(measurementValue.measurementId));

    if (roofMeasurementValues.length !== 3) {
      return this.length;
    }

    return this.getLengthWithSlope(
      roofMeasurementValues.find(mv => mv.measurementId === MeasurementType.RoofSlopeRatio).metricValue,
      roofMeasurementValues.find(mv => mv.measurementId === MeasurementType.RoofSlopeDirection).metricValue,
      roofMeasurementValues.find(mv => mv.measurementId === MeasurementType.DrawingProjection).metricValue
    );
  }

  getLengthWithSlope = computedFn((slopeAngle: number, slopeDirection: number, drawingProjection: number) => {
    if (
      drawingProjection !== getDrawingProjectionValue(DrawingProjections.Plan) &&
      slopeAngle // avoids infinite lengths
    ) {
      slopeAngle = (Math.PI / 2) - slopeAngle;
    }

    // https://matthew-brett.github.io/teaching/rotation_2d.html
    // change coordinates to match slope direction
    // x2=cosβx1−sinβy1   y2=sinβx1+cosβy1

    let lengthPixels = 0;

    const shouldCloseShape = this.type === 'Surface';

    for (let i = 1; i <= (shouldCloseShape ? this.points.length : this.points.length - 1); i++) {
      const x1 = this.points[i % this.points.length].x - this.points[i - 1].x;
      const y1 = this.points[i % this.points.length].y - this.points[i - 1].y;
      const x2 = Math.cos(slopeDirection) * x1 - Math.sin(slopeDirection) * y1;
      const y2 = Math.sin(slopeDirection) * x1 + Math.cos(slopeDirection) * y1;

      // the way we rotated the line, slope will always only affect x of rotated axis
      const xLengthPixels = x2 / Math.cos(slopeAngle);
      const yLengthPixels = y2;

      lengthPixels += Math.pow((Math.pow(xLengthPixels, 2) + Math.pow(yLengthPixels, 2)), 0.5);
    }

    return lengthPixels / DRAWING_SCALE;
  })

  @computed get lengthPixels(): number {
    return this.startPt.distancePixels(this.endPt);
  }

  @computed get adjacentLines(): Line[] {
    let retval = [];
    try {
      const adjacentLine1 = this.siblingShapes[
        (this.siblingShapes.indexOf(this) + 1) % this.siblingShapes.length
      ] as Line;
      const adjacentLine2 = this.siblingShapes[
        (this.siblingShapes.indexOf(this) - 1 + this.siblingShapes.length) % this.siblingShapes.length
      ] as Line;
      retval = compact([adjacentLine1, adjacentLine2]);
    } catch (e) { }

    return retval;
  }

  @computed get siblingShapes(): Line[] {
    let retval = [];
    if (this.treeNode.ownShapes.length > 1) {
      retval = this.treeNode.ownShapes
    } else {
      retval = this.treeNode.parent.children
        .map(node => node.shape)
    }

    // this is for display only, doesn't affect calculations
    if (retval.length > 50) {
      return [];
    }

    retval = retval.filter(shape => shape instanceof Line) as Line[];

    if (isClockwise(retval.map(line => line.startPt))) {
      retval.reverse();
    }

    return retval;
  }

  // this is causimg many problems with circular references..
  @computed get lineAtStart(): Line {
    return this.adjacentLines.find(adjacentLine =>
      adjacentLine.endPt.distancePixels(this.startPt) < 0.01
    );
  }

  @computed get lineAtEnd(): Line {
    return this.adjacentLines.find(adjacentLine =>
      adjacentLine.startPt.distancePixels(this.endPt) < 0.01
    );
  }

  @computed get pathString(): string {
    return 'M' + this.points.join('L');
  }

  @computed get isHorizontal() {
    return this.startPt.y === this.endPt.y;
  }

  @computed get isVertical() {
    return this.startPt.x === this.endPt.x;
  }


  @computed get offsetPaths(): string[] {
    if (!this.treeNode || this.treeNode.hasNonDrawingRootBubbleChildren) {
      return [];
    }

    const { measurementsStore } = this.stores;

    return getSafe(() => this.treeNode.ancestorOrNodeMeasurementValuesArray
      .filter(value => value.formula.includes(OFFSET_LENGTH_FUNCTION) && value.measurement)
      .sort((a, b) => a.measurement.index - b.measurement.index))
      .map(value => {
        const paramNames = getFunctionParameters(value.formula, OFFSET_LENGTH_FUNCTION);

        const leftOffsetParamName = paramNames[0];
        const leftOffsetMeasurement = measurementsStore.getItemByNameAndPreferredCategory(leftOffsetParamName, value.measurement.category);
        const leftOffsetMeasurementValue = value.treeNode?.measurementValues?.get?.(leftOffsetMeasurement?.id);
        const leftOffsetMetricValue = leftOffsetMeasurementValue?.metricValue || 0;

        let rightOffsetMetricValue = leftOffsetMetricValue;
        if (paramNames.length > 1) {
          const rightOffsetParamName = paramNames[1];
          const rightOffsetMeasurement = measurementsStore.getItemByNameAndPreferredCategory(rightOffsetParamName, value.measurement.category);
          const rightOffsetMeasurementValue = value.treeNode?.measurementValues?.get?.(rightOffsetMeasurement?.id);
          rightOffsetMetricValue = rightOffsetMeasurementValue?.metricValue || 0;
        }

        return this.getOffsetSurface(leftOffsetMetricValue, rightOffsetMetricValue);
      })
      .map(line => line.pathString) || [''];
  }

  getAreaWithOffset = computedFn((offsetLeftMetricValue: number, offsetRightMetrickValue: number) => {
    return this.getOffsetSurface(offsetLeftMetricValue, offsetRightMetrickValue).surface;
  });

  getOffsetSurface = computedFn((offsetLeftMetricValue: number, offsetRightMetrickValue: number): Surface => {
    const offsetLeftPixels = offsetLeftMetricValue * DRAWING_SCALE;
    const offsetRightPixels = offsetRightMetrickValue * DRAWING_SCALE;

    const offsetSurfacePoints = this.getOutlinePoints(offsetLeftPixels, offsetRightPixels)
      .map(ptArray => new Point(ptArray[0], ptArray[1]));

    return new Surface(this.stores, offsetSurfacePoints);
  })


  // Originally for a Wall item only, but most lines need to have the same functionality as a wall (thickness offset, etc)
  @observable thickness = 10.0;

  startHeight: number;
  endHeight: number;

  @observable arcExtent: number;

  symmetric = false;

  public pointsCache: number[][] = null;

  @computed
  public get outlinePoints(): Point[] {
    this.pointsCache = this.getOutlinePoints(); // points in another structure expected in some functions
    return this.pointsCache.map(ptArray => new Point(ptArray[0], ptArray[1]));
  }

  get height(): number {
    return this.startHeight; // tmp
  }

  @computed
  get fillPath(): string {
    return new Shape(this.stores, this.outlinePoints).pathString;
  }

  /**
   * Returns the maximum height of the given wall.
   * @return {number}
   * @private
   */
  getWallMaximumHeight(): number {
    if (this.height == null) {
      return 0;
    } else if (this.isTrapezoidal()) {
      return Math.max(this.height, this.endHeight);
    } else {
      return this.height;
    }
  }

  /**
   * Returns the abscissa of the arc circle center of this wall.
   * If the wall isn't round, the return abscissa is at the middle of the wall.
   * @return {number}
   */
  public getXArcCircleCenter(): number {
    if (this.arcExtent == null) {
      return (this.startPt.x + this.endPt.x) / 2;
    } else {
      return this.getArcCircleCenter()[0];
    }
  }

  /**
   * Returns the ordinate of the arc circle center of this wall.
   * If the wall isn't round, the return ordinate is at the middle of the wall.
   * @return {number}
   */
  public getYArcCircleCenter(): number {
    if (this.arcExtent == null) {
      return (this.startPt.y + this.endPt.y) / 2;
    } else {
      return this.getArcCircleCenter()[1];
    }
  }

  /**
   * Returns the coordinates of the arc circle center of this wall.
   * @return {Array}
   * @private
   */
  getArcCircleCenter(): number[] {
    const startToEndPointsDistance: number = this.startPt.distancePixels(this.endPt);
    const wallToStartPointArcCircleCenterAngle = Math.abs(this.arcExtent) > Math.PI ? - (Math.PI + this.arcExtent) / 2 : (Math.PI - this.arcExtent) / 2;
    const arcCircleCenterToWallDistance = -<number>(Math.tan(wallToStartPointArcCircleCenterAngle) * startToEndPointsDistance / 2);
    const xMiddlePoint = (this.startPt.x + this.endPt.x) / 2;
    const yMiddlePoint = (this.startPt.y + this.endPt.y) / 2;
    const angle = Math.atan2(this.startPt.x - this.endPt.x, this.endPt.y - this.startPt.y);
    return [<number>(xMiddlePoint + arcCircleCenterToWallDistance * Math.cos(angle)), <number>(yMiddlePoint + arcCircleCenterToWallDistance * Math.sin(angle))];
  }

  /**
   * Returns <code>true</code> if the height of this wall is different
   * at its start and end points.
   * @return {boolean}
   */
  public isTrapezoidal(): boolean {
    return this.height != null && this.endHeight != null && !/* equals */(<any>((o1: any, o2: any) => { if (o1 && o1.equals) { return o1.equals(o2); } else { return o1 === o2; } })(this.height, this.endHeight));
  }

  private getOutlinePoints(leftOffsetPixels: number = this.thickness / 2, rightOffsetPixels: number = this.thickness / 2): number[][] {
    const epsilon = 0.01;
    const wallPoints: number[][] = this.getUnjoinedShapePoints(leftOffsetPixels, rightOffsetPixels);
    const leftSideStartPointIndex = 0;
    const rightSideStartPointIndex = wallPoints.length - 1;
    const leftSideEndPointIndex = (wallPoints.length / 2 | 0) - 1;
    const rightSideEndPointIndex = (wallPoints.length / 2 | 0);
    const limit = 2 * (leftOffsetPixels + rightOffsetPixels);
    if (this.lineAtStart != null) {
      const lineAtStartPoints: number[][] = this.lineAtStart.getUnjoinedShapePoints(leftOffsetPixels, rightOffsetPixels);
      const lineAtStartLeftSideStartPointIndex = 0;
      const lineAtStartRightSideStartPointIndex = lineAtStartPoints.length - 1;
      const lineAtStartLeftSideEndPointIndex = (lineAtStartPoints.length / 2 | 0) - 1;
      const lineAtStartRightSideEndPointIndex = (lineAtStartPoints.length / 2 | 0);
      const lineAtStartJoinedAtEnd: boolean = getSafe(() => this.lineAtStart.lineAtEnd.id) === this.id && (getSafe(() => this.lineAtStart.lineAtStart.id) !== this.id || (this.lineAtStart.endPt.x === this.startPt.x && this.lineAtStart.endPt.y === this.startPt.y));
      const lineAtStartJoinedAtStart: boolean = getSafe(() => this.lineAtStart.lineAtStart.id) === this.id && (getSafe(() => this.lineAtStart.lineAtEnd.id) !== this.id || (this.lineAtStart.startPt.x === this.startPt.x && this.lineAtStart.startPt.y === this.startPt.y));
      if (lineAtStartJoinedAtEnd) {
        this.computeIntersection(wallPoints[leftSideStartPointIndex], wallPoints[leftSideStartPointIndex + 1], lineAtStartPoints[lineAtStartLeftSideEndPointIndex], lineAtStartPoints[lineAtStartLeftSideEndPointIndex - 1], limit);
        this.computeIntersection(wallPoints[rightSideStartPointIndex], wallPoints[rightSideStartPointIndex - 1], lineAtStartPoints[lineAtStartRightSideEndPointIndex], lineAtStartPoints[lineAtStartRightSideEndPointIndex + 1], limit);
        if (this.lineAtStart.pointsCache != null) {
          if (Math.abs(wallPoints[leftSideStartPointIndex][0] - this.lineAtStart.pointsCache[lineAtStartLeftSideEndPointIndex][0]) < epsilon && Math.abs(wallPoints[leftSideStartPointIndex][1] - this.lineAtStart.pointsCache[lineAtStartLeftSideEndPointIndex][1]) < epsilon) {
            wallPoints[leftSideStartPointIndex] = this.lineAtStart.pointsCache[lineAtStartLeftSideEndPointIndex];
          }
          if (Math.abs(wallPoints[rightSideStartPointIndex][0] - this.lineAtStart.pointsCache[lineAtStartRightSideEndPointIndex][0]) < epsilon && Math.abs(wallPoints[rightSideStartPointIndex][1] - this.lineAtStart.pointsCache[lineAtStartRightSideEndPointIndex][1]) < epsilon) {
            wallPoints[rightSideStartPointIndex] = this.lineAtStart.pointsCache[lineAtStartRightSideEndPointIndex];
          }
        }
      } else if (lineAtStartJoinedAtStart) {
        this.computeIntersection(wallPoints[leftSideStartPointIndex], wallPoints[leftSideStartPointIndex + 1], lineAtStartPoints[lineAtStartRightSideStartPointIndex], lineAtStartPoints[lineAtStartRightSideStartPointIndex - 1], limit);
        this.computeIntersection(wallPoints[rightSideStartPointIndex], wallPoints[rightSideStartPointIndex - 1], lineAtStartPoints[lineAtStartLeftSideStartPointIndex], lineAtStartPoints[lineAtStartLeftSideStartPointIndex + 1], limit);
        if (this.lineAtStart.pointsCache != null) {
          if (Math.abs(wallPoints[leftSideStartPointIndex][0] - this.lineAtStart.pointsCache[lineAtStartRightSideStartPointIndex][0]) < epsilon && Math.abs(wallPoints[leftSideStartPointIndex][1] - this.lineAtStart.pointsCache[lineAtStartRightSideStartPointIndex][1]) < epsilon) {
            wallPoints[leftSideStartPointIndex] = this.lineAtStart.pointsCache[lineAtStartRightSideStartPointIndex];
          }
          if (this.lineAtStart.pointsCache != null && Math.abs(wallPoints[rightSideStartPointIndex][0] - this.lineAtStart.pointsCache[lineAtStartLeftSideStartPointIndex][0]) < epsilon && Math.abs(wallPoints[rightSideStartPointIndex][1] - this.lineAtStart.pointsCache[lineAtStartLeftSideStartPointIndex][1]) < epsilon) {
            wallPoints[rightSideStartPointIndex] = this.lineAtStart.pointsCache[lineAtStartLeftSideStartPointIndex];
          }
        }
      }
    }
    if (this.lineAtEnd != null) {
      const lineAtEndPoints: number[][] = this.lineAtEnd.getUnjoinedShapePoints(leftOffsetPixels, rightOffsetPixels);
      const lineAtEndLeftSideStartPointIndex = 0;
      const lineAtEndRightSideStartPointIndex: number = lineAtEndPoints.length - 1;
      const lineAtEndLeftSideEndPointIndex: number = (lineAtEndPoints.length / 2 | 0) - 1;
      const lineAtEndRightSideEndPointIndex: number = (lineAtEndPoints.length / 2 | 0);
      const lineAtEndJoinedAtStart: boolean = getSafe(() => this.lineAtEnd.lineAtStart.id) === this.id && (getSafe(() => this.lineAtEnd.lineAtEnd.id) !== this.id || (this.lineAtEnd.startPt.x === this.endPt.x && this.lineAtEnd.startPt.y === this.endPt.y));
      const lineAtEndJoinedAtEnd: boolean = getSafe(() => this.lineAtEnd.lineAtEnd.id) === this.id && (getSafe(() => this.lineAtEnd.lineAtStart.id) !== this.id || (this.lineAtEnd.endPt.x === this.endPt.x && this.lineAtEnd.endPt.y === this.endPt.y));
      if (lineAtEndJoinedAtStart) {
        this.computeIntersection(wallPoints[leftSideEndPointIndex], wallPoints[leftSideEndPointIndex - 1], lineAtEndPoints[lineAtEndLeftSideStartPointIndex], lineAtEndPoints[lineAtEndLeftSideStartPointIndex + 1], limit);
        this.computeIntersection(wallPoints[rightSideEndPointIndex], wallPoints[rightSideEndPointIndex + 1], lineAtEndPoints[lineAtEndRightSideStartPointIndex], lineAtEndPoints[lineAtEndRightSideStartPointIndex - 1], limit);
        if (this.lineAtEnd.pointsCache != null) {
          if (Math.abs(wallPoints[leftSideEndPointIndex][0] - this.lineAtEnd.pointsCache[lineAtEndLeftSideStartPointIndex][0]) < epsilon && Math.abs(wallPoints[leftSideEndPointIndex][1] - this.lineAtEnd.pointsCache[lineAtEndLeftSideStartPointIndex][1]) < epsilon) {
            wallPoints[leftSideEndPointIndex] = this.lineAtEnd.pointsCache[lineAtEndLeftSideStartPointIndex];
          }
          if (Math.abs(wallPoints[rightSideEndPointIndex][0] - this.lineAtEnd.pointsCache[lineAtEndRightSideStartPointIndex][0]) < epsilon && Math.abs(wallPoints[rightSideEndPointIndex][1] - this.lineAtEnd.pointsCache[lineAtEndRightSideStartPointIndex][1]) < epsilon) {
            wallPoints[rightSideEndPointIndex] = this.lineAtEnd.pointsCache[lineAtEndRightSideStartPointIndex];
          }
        }
      } else if (lineAtEndJoinedAtEnd) {
        this.computeIntersection(wallPoints[leftSideEndPointIndex], wallPoints[leftSideEndPointIndex - 1], lineAtEndPoints[lineAtEndRightSideEndPointIndex], lineAtEndPoints[lineAtEndRightSideEndPointIndex + 1], limit);
        this.computeIntersection(wallPoints[rightSideEndPointIndex], wallPoints[rightSideEndPointIndex + 1], lineAtEndPoints[lineAtEndLeftSideEndPointIndex], lineAtEndPoints[lineAtEndLeftSideEndPointIndex - 1], limit);
        if (this.lineAtEnd.pointsCache != null) {
          if (Math.abs(wallPoints[leftSideEndPointIndex][0] - this.lineAtEnd.pointsCache[lineAtEndRightSideEndPointIndex][0]) < epsilon && Math.abs(wallPoints[leftSideEndPointIndex][1] - this.lineAtEnd.pointsCache[lineAtEndRightSideEndPointIndex][1]) < epsilon) {
            wallPoints[leftSideEndPointIndex] = this.lineAtEnd.pointsCache[lineAtEndRightSideEndPointIndex];
          }
          if (Math.abs(wallPoints[rightSideEndPointIndex][0] - this.lineAtEnd.pointsCache[lineAtEndLeftSideEndPointIndex][0]) < epsilon && Math.abs(wallPoints[rightSideEndPointIndex][1] - this.lineAtEnd.pointsCache[lineAtEndLeftSideEndPointIndex][1]) < epsilon) {
            wallPoints[rightSideEndPointIndex] = this.lineAtEnd.pointsCache[lineAtEndLeftSideEndPointIndex];
          }
        }
      }
    }

    const points: number[][] = new Array(wallPoints.length);
    for (let i = 0; i < wallPoints.length; i++) {
      points[i] = /* clone */wallPoints[i].slice(0);
    }
    return points;
  }

  /**
   * Computes the rectangle or the circle arc of a wall with its thickness.
   * @return {Array}
   * @private
   */
  getUnjoinedShapePoints(leftOffsetPixels = this.thickness / 2, rightOffsetPixels = this.thickness / 2): number[][] {
    if (this.arcExtent != null && this.arcExtent !== 0 && this.startPt.distancePixels(this.endPt) > 1.0E-6) {
      const arcCircleCenter: number[] = this.getArcCircleCenter();
      let startAngle: number = <number>Math.atan2(arcCircleCenter[1] - this.startPt.y, arcCircleCenter[0] - this.startPt.x);
      startAngle += 2 * <number>Math.atan2(this.startPt.y - this.endPt.y, this.endPt.x - this.startPt.x);
      const arcCircleRadius: number = <number>this.startPt.distancePixels(new Point(arcCircleCenter[0], arcCircleCenter[1]));
      const exteriorArcRadius: number = arcCircleRadius + leftOffsetPixels;
      const interiorArcRadius: number = Math.max(0, arcCircleRadius - rightOffsetPixels);
      const exteriorArcLength: number = exteriorArcRadius * Math.abs(this.arcExtent);
      let angleDelta: number = this.arcExtent / <number>Math.sqrt(exteriorArcLength);
      let angleStepCount: number = (<number>(this.arcExtent / angleDelta) | 0);
      const wallPoints: number[][] = <any>([]);
      if (this.symmetric) {
        if (Math.abs(this.arcExtent - angleStepCount * angleDelta) > 1.0E-6) {
          angleDelta = this.arcExtent / ++angleStepCount;
        }
        for (let i = 0; i <= angleStepCount; i++) {
          this.computeRoundWallShapePoint(wallPoints, startAngle + this.arcExtent - i * angleDelta, i, angleDelta, arcCircleCenter, exteriorArcRadius, interiorArcRadius);
        }
      } else {
        let i = 0;
        for (let angle: number = this.arcExtent; angleDelta > 0 ? angle >= angleDelta * 0.1 : angle <= -angleDelta * 0.1; angle -= angleDelta, i++) {
          this.computeRoundWallShapePoint(wallPoints, startAngle + angle, i, angleDelta, arcCircleCenter, exteriorArcRadius, interiorArcRadius);
        }
        this.computeRoundWallShapePoint(wallPoints, startAngle, i, angleDelta, arcCircleCenter, exteriorArcRadius, interiorArcRadius);
      }
      return /* toArray */wallPoints.slice(0);
    } else {
      const angle: number = Math.atan2(this.endPt.y - this.startPt.y, this.endPt.x - this.startPt.x);
      const dxLeft: number = <number>Math.sin(angle) * leftOffsetPixels;
      const dyLeft: number = <number>Math.cos(angle) * leftOffsetPixels;

      const dxRight: number = <number>Math.sin(angle) * rightOffsetPixels;
      const dyRight: number = <number>Math.cos(angle) * rightOffsetPixels;

      return [
        [this.startPt.x + dxLeft, this.startPt.y - dyLeft],
        [this.endPt.x + dxLeft, this.endPt.y - dyLeft],
        [this.endPt.x - dxRight, this.endPt.y + dyRight],
        [this.startPt.x - dxRight, this.startPt.y + dyRight]
      ];
    }
  }

  /**
   * Computes the exterior and interior arc points of a round wall at the given <code>index</code>.
   * @param {Array[]} wallPoints
   * @param {number} angle
   * @param {number} index
   * @param {number} angleDelta
   * @param {Array} arcCircleCenter
   * @param {number} exteriorArcRadius
   * @param {number} interiorArcRadius
   * @private
   */
  computeRoundWallShapePoint(wallPoints: number[][], angle: number, index: number, angleDelta: number, arcCircleCenter: number[], exteriorArcRadius: number, interiorArcRadius: number) {
    const cos: number = Math.cos(angle);
    const sin: number = Math.sin(angle);
    const interiorArcPoint: number[] = [<number>(arcCircleCenter[0] + interiorArcRadius * cos), <number>(arcCircleCenter[1] - interiorArcRadius * sin)];
    const exteriorArcPoint: number[] = [<number>(arcCircleCenter[0] + exteriorArcRadius * cos), <number>(arcCircleCenter[1] - exteriorArcRadius * sin)];
    if (angleDelta > 0) {
              /* add */wallPoints.splice(index, 0, interiorArcPoint);
              /* add */wallPoints.splice(/* size */wallPoints.length - 1 - index, 0, exteriorArcPoint);
    } else {
              /* add */wallPoints.splice(index, 0, exteriorArcPoint);
              /* add */wallPoints.splice(/* size */wallPoints.length - 1 - index, 0, interiorArcPoint);
    }
  }

  /**
   * Compute the intersection between the line that joins <code>point1</code> to <code>point2</code>
   * and the line that joins <code>point3</code> and <code>point4</code>, and stores the result
   * in <code>point1</code>.
   * @param {Array} point1
   * @param {Array} point2
   * @param {Array} point3
   * @param {Array} point4
   * @param {number} limit
   * @private
   */
  computeIntersection(point1: number[], point2: number[], point3: number[], point4: number[], limit: number) {
    const alpha1: number = (point2[1] - point1[1]) / (point2[0] - point1[0]);
    const alpha2: number = (point4[1] - point3[1]) / (point4[0] - point3[0]);
    if (alpha1 !== alpha2) {
      let x: number = point1[0];
      let y: number = point1[1];
      if (Math.abs(alpha1) > 4000) {
        if (Math.abs(alpha2) < 4000) {
          x = point1[0];
          const beta2: number = point4[1] - alpha2 * point4[0];
          y = alpha2 * x + beta2;
        }
      } else if (Math.abs(alpha2) > 4000) {
        if (Math.abs(alpha1) < 4000) {
          x = point3[0];
          const beta1: number = point2[1] - alpha1 * point2[0];
          y = alpha1 * x + beta1;
        }
      } else {
        const sameSignum: boolean = /* signum */(f => { if (f > 0) { return 1; } else if (f < 0) { return -1; } else { return 0; } })(alpha1) === /* signum */(f => { if (f > 0) { return 1; } else if (f < 0) { return -1; } else { return 0; } })(alpha2);
        if ((sameSignum && (Math.abs(alpha1) > Math.abs(alpha2) ? alpha1 / alpha2 : alpha2 / alpha1) > 1.004) || (!sameSignum && Math.abs(alpha1 - alpha2) > 1.0E-5)) {
          const beta1: number = point2[1] - alpha1 * point2[0];
          const beta2: number = point4[1] - alpha2 * point4[0];
          x = (beta2 - beta1) / (alpha1 - alpha2);
          y = alpha1 * x + beta1;
        }
      }
      if (new Point(x, y).distancePixels(new Point(point1[0], point1[1])) < limit) {
        point1[0] = x;
        point1[1] = y;
      }
    }
  }
}