// ROUTES "security" IS NOT CORRECT, should load the app and assume route is allowed
// unless we find out it's not, then only we redirect


import { Button, CssBaseline } from '@material-ui/core';
import classnames from 'classnames';
import AppSnackbar from 'components/common/AppSnackbar/AppSnackbar';
import DebugView from 'components/common/DebugView/DebugView';
import DialogsContainer from 'components/common/DialogsContainer/DialogsContainer';
import ErrorDialog from 'components/common/ErrorDialog/ErrorDialog';
import LoadingOverlay from 'components/common/LoadingOverlay/LoadingOverlay';
import ObserverComponent from 'components/common/ObserverComponent';
import PaymentFailedWarningBanner from 'components/common/PaymentFailedWarningBanner/PaymentFailedWarningBanner';
import SafariErrorDialog from 'components/common/SafariErrorDialog/SafariErrorDialog';
import SubscriptionPaymentFailedDialog from 'components/common/SubscriptionPaymentFailedDialog/SubscriptionPaymentFailedDialog';
import { UnitSystem } from 'constants/UnitSystem';
import Cookies from 'js-cookie';
import 'moment/locale/fr';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import 'utils/FirebaseInitializedApp';
import { firestore } from 'utils/FirebaseInitializedApp';
import { forceReloadPage, isProduction, isSafari } from 'utils/Utils';
import 'utils/i18n';
import i18n from 'utils/i18n';
import DownloadsView from 'views/DownloadsView/DownloadsView';
import FooterView from 'views/FooterView/FooterView';
import HeaderView from 'views/HeaderView/HeaderView';
import LoginView from 'views/LoginView/LoginView';
import ProjectView from 'views/ProjectView/ProjectView';
import ProjectsListView from 'views/ProjectsListView/ProjectsListView';
import ReportView from 'views/ReportView/ReportView';
import './App.scss';

const SwitchDiv: React.SFC<{ id: string }> = ({ id, children }) =>
  <div id={id}><Switch>{children}</Switch></div>;

interface IAppState {
  error: string,
  errorCount: number,
  isOffline: boolean,
}

export default class App extends ObserverComponent<{}, IAppState> {
  state = {
    error: null,
    errorCount: 0,
    isOffline: false,
  };

  onKeyboardShortcut = (event: React.KeyboardEvent<Document>) => {
    const { settingsStore } = this.context;
    // on windows, need to use alt+ctrl+l
    if (event.ctrlKey && event.key === 'l') {
      i18n.language = i18n.language === 'fr' ? 'en' : 'fr';
    }

    if (event.ctrlKey && event.key === 'm') {
      settingsStore.settings.unitSystem = settingsStore.isImperial 
      ? UnitSystem.Metric
      : UnitSystem.Imperial;
    }
  }

  disableRightClick = event => {
    // add option to manually block certain people from right clicking
    if (!window.location.href.includes('local.')) {
    //  event.preventDefault();
    }
  };

  beforeUnload = event => {
    const { commonStore } = this.context;

    if (commonStore.hasPendingDebouncedCalls) {
      commonStore.flushDebouncedCalls();
      event.preventDefault();
      // this string doesn't seem to be shown
      return event.returnValue = i18n.t('We are currently saving your project, please wait a few seconds before leaving the page.');
    }
  };

  handleOnlineChange = event => {
    this.setState({ isOffline: !navigator.onLine });
  }

  componentWillUnmount() {
    document.removeEventListener('contextmenu', this.disableRightClick);
    document.removeEventListener('keyup', this.onKeyboardShortcut);
    window.removeEventListener('beforeunload', this.beforeUnload);
    window.removeEventListener('online', this.handleOnlineChange);
    window.removeEventListener('offline', this.handleOnlineChange);
  }

  componentDidMount() {
    document.addEventListener('keyup', this.onKeyboardShortcut);
    document.addEventListener('contextmenu', this.disableRightClick);
    window.addEventListener('beforeunload', this.beforeUnload);
    window.addEventListener('online', this.handleOnlineChange);
    window.addEventListener('offline', this.handleOnlineChange);

    this.setState({ isOffline: !navigator.onLine });

    if (isSafari() && !Cookies.get('forceSafari')) {
      this.setState({ error: 'safari', errorCount: 1 });
    }
  }

  componentDidCatch(error, info) {
    const { userInfoStore } = this.context;
    const { errorCount } = this.state;
    // Display fallback UI
    const fullError = error.message + '\\n' + error.stack;
    this.setState({ error: fullError, errorCount: errorCount + 1 });

    const email = userInfoStore.userEmail;

    const dateKey =new Date().toLocaleDateString('en-US', {month:'2-digit', year:'2-digit', day:'2-digit'}).replaceAll('/','_');

    try {
      const today = (new Date()).toDateString() + ' ' + (new Date()).toLocaleTimeString();
      // try logging error
      firestore().collection('errors').doc(dateKey).collection('users').doc(email).set({
        email,
        lastErrorDate: (new Date()).toDateString(),
        errors: firestore.FieldValue.arrayUnion(
          JSON.stringify({ date: today, error: fullError.substring(0, 500), ua: navigator.userAgent })
        ),
      }, { merge: true });
    } catch (e) {

    }

  }

  public _render() {
    const { userInfoStore, commonStore, routerStore, projectsStore } = this.context;
    let { error, errorCount, isOffline } = this.state;
    const { user } = userInfoStore;
    const { language } = i18n;
    const isLoggedOut = user === null;
    const isLoggedIn = !!user;

    const projectName = projectsStore.selectedProject?.name;

    error = error || commonStore.error;

    const ErrorDialogComponent = error === 'safari'
      ? SafariErrorDialog
      : ErrorDialog;

    const queryParams = new URLSearchParams(location.search);

    return (error)
      ? (
        // error count doesnt seem to ever get incremented, but keeping it in case it works as a fallback
        errorCount > 1
          ? (
            <div>
              <pre dangerouslySetInnerHTML={{ __html: error.replace(/\\n/g, '<br/>') }}></pre>
              <br />
              <Button onClick={() => forceReloadPage()}>{i18n.t('Try again')}</Button>
            </div>
          ) : (
            <ErrorDialogComponent
              open={!!error}
              dialogId={''}
              error={error}
            />
          )
      ) : (
        <Router ref={ref => routerStore.router = window.router = ref}>
          <Switch>
            {/* Remove trailing slash */}
            <Route exact strict path="/*/" render={props => <Redirect to={`${props.location.pathname.slice(0, -1)}`} />} />
            <Route>
              <div id="app" className={classnames({ isLoggedOut })}>
                <Helmet>
                  <html lang={language} className="notranslate" translate="no" />
                  <title>Evalumo — {i18n.t('Login')}</title>

                  <link rel="manifest" href={`/manifest${isProduction() ? '' : '-dev'}.json`} crossOrigin="use-credentials" />
                  <link rel="shortcut icon" href={`/favicon${isProduction() ? '' : '-dev'}.svg`} />
                </Helmet>

                <style id="custom-css">{user?.customCss}</style>

                {user?.shouldBlockAfterPaymentFailed && user?.quadernoUserId && (
                  <SubscriptionPaymentFailedDialog />
                )}

                <CssBaseline />

                <DialogsContainer />

                {user?.shouldWarnAfterPaymentFailed && !user?.shouldBlockAfterPaymentFailed && user?.quadernoUserId && (
                  <PaymentFailedWarningBanner />
                )}

                <SwitchDiv id="header">
                  <Route title={'abc'} path="/:countryCode/projects/:encodedProjectName/report" component={HeaderView} />
                  <Route title={'def'} path="/:countryCode/projects/:encodedProjectName" component={HeaderView} />
                  <Route title={'ghi'} path="/:countryCode/projects" component={HeaderView} />
                </SwitchDiv>

                <SwitchDiv id="content">
                  {isLoggedOut && <Route path="/:countryCode/login" component={LoginView} />}
                  {/* fix country code */}
                  {isLoggedOut && language && <Redirect to={`/ca/login`} />}

                  <Route path="/:countryCode/projects/:encodedProjectName/report" component={ReportView} />
                  <Route path="/:countryCode/projects/:encodedProjectName" component={ProjectView} />
                  <Route path="/:countryCode/projects" component={ProjectsListView} />
                  <Route path="/:countryCode/debug" component={DebugView} />
                  <Route path="/:countryCode/downloads" component={DownloadsView} />

                  {isLoggedIn && !queryParams.has('isApi') && <Redirect to={`/ca/projects`+location.search} />}
                  <div><LoadingOverlay /></div>
                </SwitchDiv>

                {/* Footer is needed for autoscroll on drag to work */}
                <SwitchDiv id="footer">
                  <Route path="/:countryCode/projects/:encodedProjectName" component={FooterView} />
                </SwitchDiv>

                {<LoadingOverlay
                  isTranslucent
                  withTransition
                  progress={commonStore.projectLoadingProgress}
                  isVisible={
                    !isOffline &&
                    !commonStore.areImportantStoresReady && !(
                      routerStore.isProjectsListPage ||
                      routerStore.isLoginPage ||
                      routerStore.isDownloadsPage
                    )}
                    // fix &amp; bug
                  message={projectName ? i18n.t('Loading {{- projectName}}...', { projectName: projectName }) : i18n.t('Loading...')}
                />}
                {isOffline && <LoadingOverlay message={i18n.t("Can't connect to Evalumo server. Check your Internet connection...")} />}
                <AppSnackbar />
              </div>
            </Route>
          </Switch>
        </Router>
      );
  }
}