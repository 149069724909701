import { IconButton, Switch, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Info';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import Measurement from 'models/Measurement';
import * as React from 'react';
import { handleCheckboxChange } from 'utils/FormUtils';
import i18n from 'utils/i18n';

const styles = require('./MeasurementOptions.module.scss');

interface IMeasurementOptionsProps {
  measurement: Measurement;
  shouldUseAsDefaultFormula: boolean,
  onUseAsDefaultValueChange: (event, checked) => void
}

// TODO when checking default: backup current default and copy formula to new default
@observer
export default class MeasurementOptions extends React.Component<IMeasurementOptionsProps> {
  render() {
    const { measurement, shouldUseAsDefaultFormula, onUseAsDefaultValueChange } = this.props;

    return (
      <div className={classnames(styles.root, {[styles.isReadonly]:measurement.isReadonly})}>

        <div>
          <Switch
            className={styles.checkbox}
            checked={measurement.shouldApplyWaste}
            onChange={handleCheckboxChange(measurement, 'shouldApplyWaste')}
            disabled={measurement.isReadonly}
          />
          <span className={styles.label}>
            <span dangerouslySetInnerHTML={{
              __html:
                i18n.t("Apply <b>waste</b> percentage to tasks using this measurement")
            }}
            />
            <Tooltip title={i18n.t(`When off, never compute waste on an item associated with this measure.`)}>
              <IconButton className={styles.infoButton}>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </span>
        </div>

        <div>
          <Switch
            className={styles.checkbox}
            checked={measurement.isSummable}
            onChange={handleCheckboxChange(measurement, 'isSummable')}
            disabled={measurement.isReadonly}
          />
          <span className={styles.label}>
            <span dangerouslySetInnerHTML={{
              __html:
                i18n.t('Compute this measurement\'s <b>total</b> across the project')
            }}
            />
            <Tooltip title={i18n.t(`Indicate if the sum of this measurement should be calculated. For example, a wall should sum the areas and length of its sections, but its height.`)}>
              <IconButton className={styles.infoButton}>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </span>
        </div>

        <div>
          <Switch
            className={styles.checkbox}
            checked={shouldUseAsDefaultFormula}
            onChange={onUseAsDefaultValueChange}
            disabled={measurement.isReadonly}
          />
          <span className={styles.label}>
            <span dangerouslySetInnerHTML={{
              __html:
                i18n.t("Use current value as default")
            }}
            />
            <Tooltip title={i18n.t(`When on, the new value for this measurement will become the default value next time the measurement is added. Doesn't affect measurements that were already added.`)}>
              <IconButton className={styles.infoButton}>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </span>
        </div>

        <div>
          <Switch
            className={styles.checkbox}
            checked={measurement.shouldAutoToggleDependencies}
            onChange={handleCheckboxChange(measurement, 'shouldAutoToggleDependencies')}
            disabled={measurement.isReadonly}
          />
          <span className={styles.label}>
            <span dangerouslySetInnerHTML={{
              __html:
                i18n.t("Automatically activate dependencies")
            }}
            />
            <Tooltip title={i18n.t(`When on, dependencies of this measurement will be automatically activated when this measurement is activated. For example, a Surface measurement can automatically activate Length and Height measurements. However, a roof perimeter shouldn't try to automatically add eaves and rakes, but let the user add them.`)}>
              <IconButton className={styles.infoButton}>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </span>
        </div>

        <div>
          <Switch
            className={styles.checkbox}
            checked={measurement.shouldSetToZeroWhenImportingTasksList}
            onChange={handleCheckboxChange(measurement, 'shouldSetToZeroWhenImportingTasksList')}
            disabled={measurement.isReadonly}
          />
          <span className={styles.label}>
            <span dangerouslySetInnerHTML={{
              __html:
                i18n.t("Needs separate identification after inserting from Tasks List")
            }}
            />
            <Tooltip title={i18n.t(`When on, force value to 0 when measurement is added from a list of tasks. Useful when the measurement applies only to a section of a drawing and not the whole drawing.`)}>
              <IconButton className={styles.infoButton}>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </span>
        </div>

        <div>
          <Switch
            className={styles.checkbox}
            checked={measurement.canBeToggledFromToolbar}
            onChange={handleCheckboxChange(measurement, 'canBeToggledFromToolbar')}
            disabled={measurement.isReadonly}
          />
          <span className={styles.label}>
            <span>{i18n.t("Show in measurements toolbar")}</span>
            <Tooltip title={i18n.t(`Allows activating this measurement from the toolbar. Some measurements cannot be applied directly to a drawing element so we can turn off this option, to make sure we don't apply them accidentally. For example, roof perimeter is calculated from the sum of eaves and gables, not applied directly on the drawing.`)}>
              <IconButton className={styles.infoButton}>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </span>
        </div>

        <div>
          <Switch
            className={styles.checkbox}
            checked={measurement?.shouldShowInReportHeaders}
            onChange={handleCheckboxChange(measurement, 'shouldShowInReportHeaders')}
            disabled={measurement.isReadonly}
          />
          <span className={styles.label}>
            <span>{i18n.t("Show in report headers")}</span>
            <Tooltip title={i18n.t(`Show the value of this measurement in report headers when "Header Measurements" option is set to visible.`)}>
              <IconButton className={styles.infoButton}>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </span>
        </div>

        <div>
          <Switch
            className={styles.checkbox}
            checked={measurement.isHidden}
            onChange={handleCheckboxChange(measurement, 'isHidden')}
            disabled={measurement.isReadonly}
          />
          <span className={styles.label}>
            <span dangerouslySetInnerHTML={{
              __html:
                i18n.t("Hide this measurement in list")
            }}
            />
            <Tooltip title={i18n.t(`Hide measurement from list, except if option to show hidden measurements is checked.`)}>
              <IconButton className={styles.infoButton}>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </span>
        </div>
      </div>
    )
  }
}