
// Only to prepare the dialog, the actual content should be done in MaterialDetailsForm
import { Checkbox, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import ReloadIcon from '@material-ui/icons/Refresh';
import { CategoryType } from 'constants/CategoryType';
import { IEditComponentProps } from 'constants/CommonProps';
import { compact, omit, remove } from 'lodash';
import Category from 'models/Category';
import * as React from 'react';
import { modelToPlainObject } from 'utils/DeserializeUtils';
import { handleChange } from 'utils/FormUtils';
import { getSafe } from 'utils/Utils';
import i18n from 'utils/i18n';
import ColorButton from '../ColorButton/ColorButton';
import ImageBox from '../ImageBox/ImageBox';
import MenuPopupButton from '../MenuPopupButton/MenuPopupButton';
import ObserverComponent from '../ObserverComponent';
import TextField from '../TextField/TextField';

const styles = require('./CategoryEditDetailsForm.module.scss');

export default class CategoryEditDetailsForm extends ObserverComponent<IEditComponentProps> {
  get category(): Category {
    return this.props.models[0] as Category;
  }

  onChangeCategoryType = (categoryType: CategoryType) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const { categoryTypes } = this.category;
    if (checked && !categoryTypes.includes(categoryType)) {
      categoryTypes.push(categoryType);
    } else if (!checked && categoryTypes.includes(categoryType)) {
      remove(categoryTypes, categoryTypeToCheck => categoryTypeToCheck === categoryType);
    }
  }

  onUserItemReset = () => {
    const { dialogId } = this.props;
    const { categoriesStore, dialogsStore } = this.context;
    categoriesStore.resetToUserItems([this.category]);

    dialogsStore.hideDialog(dialogId);
  }

  _render() {
    const { category } = this;
    const { categoriesStore } = this.context;

    const categoryExists = getSafe(() => categoriesStore.getItem(category.id));

    const isSubcategory = !!category.parentCategory;

    const categoryOrSubcategory = isSubcategory
      ? i18n.t('subcategory')
      : i18n.t('category');

    // duplicate, move to store
    const fieldsToIgnoreInModelComparisons = ['cascadeOrder', 'cascadeOrders', 'updatedMiliseconds', 'createdMiliseconds'];

    const userCollectionCategory = categoriesStore.userCollectionItemsMap.get(category.id);

    return (
      <div className={styles.root}>
        <DialogTitle>
          <div>
            {categoryExists
              ? i18n.t('Edit {{categoryOrSubcategory}}', { categoryOrSubcategory })
              : i18n.t('Create new {{categoryOrSubcategory}}', { categoryOrSubcategory })}
          </div>
          <MenuPopupButton menuItems={compact([
            userCollectionCategory && (
              JSON.stringify(omit(modelToPlainObject(userCollectionCategory), fieldsToIgnoreInModelComparisons)) !==
              JSON.stringify(omit(modelToPlainObject(category), fieldsToIgnoreInModelComparisons))
            ) && { icon: <ReloadIcon />, text: i18n.t('Reset to latest change'), handler: this.onUserItemReset },
          ])} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextField
              label={i18n.t('{{categoryOrSubcategory}} name', { categoryOrSubcategory })}
              shouldFocusOnMount
              className={styles.textField}
              value={getSafe(() => category.name)}
              onChange={handleChange(category, 'name')}
              InputProps={{
                //className: InputClassName
              }}
              margin="none"
            />
          </DialogContentText>

          <ImageBox
            model={category}
            store={categoriesStore}
            className={styles.imageBox}
            imageHeight={category.imageUrl ? 345 : 100}
          />

          <div className={styles.section}>
            <Typography variant="subtitle2">{
              i18n.t('Where should we see this {{categoryOrSubcategory}}?', { categoryOrSubcategory })
            }</Typography>

            {Object.values(CategoryType).map(categoryType => (
              <div key={categoryType} className={styles.checkboxAndLabel}>
                <Checkbox
                  className={styles.checkbox}
                  checked={category.parentCategory
                    ? category.parentCategory.categoryTypes.includes(categoryType)
                    : category.categoryTypes.includes(categoryType)}
                  onChange={this.onChangeCategoryType(categoryType)}
                />
                <span className={styles.label}>
                  {i18n.t(categoryType)}
                </span>
              </div>
            ))}
          </div>

          {[...category.parentCategory?.categoryTypes || [], ...category.categoryTypes].includes(CategoryType.Measurement) && (
            <div className={styles.section}>
              <Typography variant="subtitle2">{
                i18n.t('Color (when applied to drawing element)')
              }</Typography>

              <div className={styles.colorRow}>
                <ColorButton
                  className={styles.colorButton}
                  color={category.legendColor}
                  onChange={color => category.legendColor = color.hex}
                />

                <IconButton onClick={() => category.legendColor = ''} >
                  <ClearIcon />
                </IconButton>
              </div>
            </div>
          )}

        </DialogContent>
      </div>
    );
  }
}