import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import StarIcon from '@material-ui/icons/LocalOffer';
import { CardElement, Elements, ElementsConsumer, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { PaymentRequest, Stripe, StripeElements, loadStripe } from '@stripe/stripe-js';
import classnames from 'classnames';
import BuiltinSubscriptions from 'constants/BuiltinSubscriptions';
import { IDialogProps } from 'constants/CommonProps';
import DialogModel from 'models/Dialog';
import Subscription from 'models/Subscription';
import * as React from 'react';
import { sumPrices } from 'utils/CurrencyUtil';
import { functions } from 'utils/FirebaseInitializedApp';
import { formatCurrency } from 'utils/NumberFormatter';
import { isProduction } from 'utils/Utils';
import i18n from 'utils/i18n';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import ObserverComponent from '../ObserverComponent';
import SubscriptionFeatures from '../SubscriptionFeatures/SubscriptionFeatures';
import TextField from '../TextField/TextField';
import UpgradeSubscriptionSuccessDialog from '../UpgradeSubscriptionSuccessDialog/UpgradeSubscriptionSuccessDialog';

const styles = require('./UpgradeSubscriptionDialog.module.scss');

interface UpgradeSubscriptionDialogsState {
  selectedSubscription: Subscription,
  paymentRequests: Map<number, PaymentRequest>,
  isCardFocused: boolean,
  cardholderName: string,
  cardIsValid: boolean,
  isRequestProcessing: boolean,
}

export default class UpgradeSubscriptionDialog extends ObserverComponent<IDialogProps, UpgradeSubscriptionDialogsState> {
  state = {
    selectedSubscription: null,
    paymentRequests: new Map<number, PaymentRequest>(),
    isCardFocused: false,
    cardholderName: '',
    cardIsValid: false,
    isRequestProcessing: false,
  } as UpgradeSubscriptionDialogsState;

  stripePromise = loadStripe(isProduction() ? 'pk_live_4P8rHRxuJ0oqw8X0tfRzmbFJ' : 'pk_test_4P8rpWabnkVeRb4WcekyFkIP');

  componentDidMount() {
    this.stripePromise.then(stripe => {
      window.stripe = stripe;
      
      const { subscriptionsStore, dialogsStore } = this.context;

      const paymentRequests = new Map<number, PaymentRequest>();

      // later
      /*subscriptionsStore.items.forEach(subscription => {
        const pr = stripe.paymentRequest({
          country: 'CA',
          currency: 'cad',
          total: {
            label: 'Demo total',
            amount: 1099,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });

        // Check the availability of the Payment Request API.
        pr.canMakePayment().then(result => {
          if (result) {
            paymentRequests.set(subscription.price, pr);
            this.setState({ paymentRequests });
          }
        });
      });*/
    });

    this.setState({ cardholderName: this.context?.userInfoStore?.user?.displayName });
  }


  onSelectSubscription = (subscription: Subscription) => async () => {
    this.setState({ selectedSubscription: subscription });
  }

  onSubscribeClick = (elements: StripeElements, stripe: Stripe) => async () => {
    const { userInfoStore } = this.context;
    const { isRequestProcessing, cardholderName, selectedSubscription } = this.state;
    if (isRequestProcessing) {
      return;
    }

    this.setState({ isRequestProcessing: true });

    // voir stripe debit, se fait tout en curl!!!!!
    // ne pas utiliser setup intents!!!!
    /*
    curl https://api.stripe.com/v1/setup_intents \
  -u <live key>: \
  -d "payment_method_types[]"=acss_debit \
  -d customer="{{CUSTOMER_ID}}" \
  -d "payment_method_options[acss_debit][currency]"=cad \
  -d "payment_method_options[acss_debit][mandate_options][payment_schedule]"=interval \
  -d "payment_method_options[acss_debit][mandate_options][interval_description]"="On day 20 of every month" \
  -d "payment_method_options[acss_debit][mandate_options][transaction_type]"=business


    const {setupIntent, error} = await stripe.confirmAcssDebitSetup(
      '{secret from previous step}',
      {
        payment_method: {
          acss_debit: {
            institution_number: '000',
            transit_number: '000',
            account_number: '000',
          },
          billing_details: {
            name: 'Rénovation signée Couture inc.',
            email: 'coutures@live.fr',
          },
        },
      }
    );

    debugger;
    */


    const stripeCreateCustomer = functions.httpsCallable('stripeCreateCustomer');
    // email will be present in auth token, but sometimes need to impersonate
    // also would be stupid to hack this to pay for someone else
    const { data: { customerId } } = await stripeCreateCustomer({ name: cardholderName, email: userInfoStore.userEmail });

    const cardElement = elements.getElement(CardElement);

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: cardholderName,
      },
    })

    if (result.error) {
      console.error("credit card error", result.error);
    } else {
      this.createSubscription({
        customerId: customerId,
        paymentMethodId: result.paymentMethod.id,
        priceId: selectedSubscription.stripePriceId,
      });
    }
  }


  async createSubscription({ customerId, paymentMethodId, priceId }) {

    const stripeCreateSubscription = functions.httpsCallable('stripeCreateSubscription');
    const { data } = await stripeCreateSubscription({ customerId, paymentMethodId, priceId });

    // cc test:  4000000000009995 (refused)

    // todo 3d secure etc

    const { dialogsStore, subscriptionsStore, userInfoStore } = this.context;
    const { dialogId } = this.props;
    const { selectedSubscription } = this.state;

    dialogsStore.hideDialog(dialogId);
    // open success dialog

    const newDialog = new DialogModel(this.context);
    newDialog.dialogComponent = ({ open }) => (
      <UpgradeSubscriptionSuccessDialog
        open={open}
        dialogId={newDialog.id}
      />
    );

    dialogsStore.showDialog(newDialog);
    
    // MOVE TO BACKEND ASAP
    selectedSubscription._expirationDate = new Date(new Date().setDate(new Date().getDate() + 365)).toISOString();
    subscriptionsStore.addEditItem(selectedSubscription);

    
    window.rewardful('convert', { email: userInfoStore.userEmail });
  }

  _render() {
    const { subscriptionsStore, dialogsStore } = this.context;
    const { selectedSubscription, paymentRequests, isCardFocused, cardholderName, cardIsValid, isRequestProcessing } = this.state;
    const { open, dialogId } = this.props;

    const { activeSubscription } = subscriptionsStore;

    return (
      <Dialog
        className={styles.root}
        open={open}
      >
        {isRequestProcessing && <LoadingOverlay />}
        <DialogTitle>
          {selectedSubscription ? i18n.t('Review selected plan') : i18n.t('Choose your plan')}
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <DialogContentText className={styles.content}>
            <div className={classnames(styles.currentPlan, { [styles.hasSelectedPlan]: selectedSubscription })}>
              <div className={styles.planExplanation}>{i18n.t('Current plan')}</div>

              <div className={styles.name}>{activeSubscription.name}</div>
              <div>{activeSubscription.description}</div>
              {!!activeSubscription.price && <div>{activeSubscription.price}</div>}

              <SubscriptionFeatures subscription={activeSubscription} />
            </div>

            <div className={classnames(styles.cards, { [styles.hasSelection]: selectedSubscription })}>
              {subscriptionsStore.items
                .filter(subscription => subscription !== activeSubscription)
                .map(subscription => {
                  const paymentRequest = paymentRequests.get(subscription.price);

                  return (
                    <div className={classnames(
                      styles.cardContainer, {
                      [styles.isHidden]: selectedSubscription && selectedSubscription !== subscription,
                      [styles.isSelected]: selectedSubscription === subscription,
                    })}>
                      <Card key={subscription.id} className={styles.card}>
                        <CardContent className={styles.cardContent}>
                          {selectedSubscription !== subscription && <div>{subscription.description}</div>}

                          <div className={styles.name}>
                            {subscription.name}
                          </div>

                          {selectedSubscription !== subscription && subscription.regularPrice !== subscription.price && (
                            <div className={styles.crossedPrice}>
                              {formatCurrency(subscription.regularPrice, true, true)} <span>/{i18n.t('month')}</span>
                            </div>
                          )}
                          <div className={styles.price}>{formatCurrency(subscription.price, true, true)} <span>/{i18n.t('month')}</span></div>

                          {selectedSubscription !== subscription && subscription.regularPrice !== subscription.price && (
                            <div className={styles.offer}><StarIcon /> {i18n.t('Launch Special Offer!')}</div>
                          )}

                          {selectedSubscription !== subscription && (
                            <Button
                              onClick={this.onSelectSubscription(subscription)}
                              className={styles.actionButton}
                              color="primary"
                              variant="contained">
                              {i18n.t('Choose this plan')}
                            </Button>
                          )}

                          <div className={styles.featuresAndForm}>
                            <SubscriptionFeatures subscription={subscription} />


                            {selectedSubscription == subscription && (
                              <div className={styles.detailedPrice}>
                                <div>
                                  <div>
                                    {i18n.t('Subtotal')}
                                  </div>
                                  <div>
                                    {formatCurrency(subscription.price)}
                                  </div>
                                </div>
                                <div>
                                  <div>
                                    TPS (5%) #81501 3131 RT0001
                                  </div>
                                  <div>
                                    {formatCurrency(subscription.price * 0.05)}
                                  </div>
                                </div>
                                <div>
                                  <div>
                                    TVQ (9.975%) #1220108330 TQ0001
                                  </div>
                                  <div>
                                    {formatCurrency(subscription.price * 0.09975)}
                                  </div>
                                </div>
                                <div className={styles.total}>
                                  <div>
                                    Total
                                  </div>
                                  <div>
                                    {
                                      formatCurrency(
                                        sumPrices([subscription.price, subscription.price * 0.05, subscription.price * 0.09975])
                                      )}
                                    {i18n.t('/month')}
                                    &nbsp;
                                    {subscription.id === BuiltinSubscriptions.Monthly ? i18n.t('month-to-month') : i18n.t('with 1 year commitment')}
                                  </div>
                                </div>
                              </div>
                            )}

                            <form className={classnames({ [styles.isHidden]: selectedSubscription !== subscription })}>
                              <Elements stripe={this.stripePromise} options={{ locale: 'fr' }} >
                                {paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} />}

                                {paymentRequest && <div>ou payer par carte</div>}

                                <TextField
                                  id="cardholderName"
                                  value={cardholderName}
                                  label={i18n.t('Nom')}
                                  onChange={event => this.setState({ cardholderName: event?.target?.value || '' })}
                                  fullWidth
                                />

                                {/* All for one field, to make stripe look like Material UI */}
                                <div className={styles.cardRelative}>
                                  <TextField
                                    className={classnames(styles.fakeTextField, { [styles.isFocused]: isCardFocused })}
                                    value={'  '}
                                    label="Informations de paiement"
                                    fullWidth
                                  />

                                  <div
                                    className={classnames(styles.creditCardContainer, { [styles.isFocused]: isCardFocused })}
                                  >
                                    <CardElement
                                      className={styles.cardInput}
                                      onFocus={() => this.setState({ isCardFocused: true })}
                                      onBlur={() => this.setState({ isCardFocused: false })}
                                      onChange={(event) => {
                                        if (!event.complete && cardIsValid) {
                                          this.setState({ cardIsValid: false });
                                        } else if (event.complete && !cardIsValid) {
                                          this.setState({ cardIsValid: true });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>

                                <ElementsConsumer>
                                  {({ elements, stripe }) => (
                                    <Button onClick={this.onSubscribeClick(elements, stripe)} disabled={!cardIsValid} variant="contained" color="primary">
                                      {i18n.t('Subscribe')}
                                    </Button>
                                  )}
                                </ElementsConsumer>
                              </Elements>
                            </form>
                          </div>

                        </CardContent>
                      </Card>
                    </div>
                  );
                })}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => dialogsStore.hideDialog(dialogId)} className={styles.cancelButton}>
            {i18n.t('Cancel')}
          </Button>
        </DialogActions>
      </Dialog >
    )
  }
}
