
import DeleteIcon from '@material-ui/icons/Delete';
import Globals from 'Globals';
import ObserverComponent from 'components/common/ObserverComponent';
import { IDialogProps } from 'constants/CommonProps';
import { TreeNodeStatus } from 'constants/TreeNodeStatus';
import { compact, isEmpty } from 'lodash';
import { action, computed } from 'mobx';
import * as React from 'react';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import { waitOnStoresReady } from 'utils/StoreUtil';
import { TrackedInteractions, trackInteraction } from 'utils/TrackingUtil';
import { sleep } from 'utils/Utils';
import i18n from 'utils/i18n';
import uuidv4 from 'uuid/v4';
import ConfirmDeleteButton from '../ConfirmDeleteButton/ConfirmDeleteButton';
import DrawingEditDialogDetailsForm from '../DrawingEditDialogDetailsForm/DrawingEditDialogDetailsForm';
import EditDialogComponent from '../EditDialogComponent/EditDialogComponent';



const styles = require('./DrawingEditDialog.module.scss');

export default class DrawingEditDialog extends ObserverComponent<IDialogProps> {
  drawingKeySuffix = 'drawing_' + uuidv4();

  isPriceUpdatePausedBeforeOpeningDialog = false;

  @computed get projectTreeNode() {
    return Globals.defaultStores.treeNodesStore.selectedTreeNode;
  }

  @computed get drawingDraftID() {
    const { commonStore } = this.context;

    return commonStore.selectedProjectId + '_' + this.projectTreeNode.id + '_' + this.drawingKeySuffix;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.componentDidMount();
    }
  }

  // similar to TasksListEditDialog
  async componentDidMount() {
    const { treeNodesStore: projectTreeNodesStore, commonStore: projectCommonStore, priceUpdateStore } = Globals.defaultStores;
    const { treeNodesStore: drawingTreeNodesStore, commonStore: drawingCommonStore, shapesStore: drawingShapesStore, measurementsStore: drawingMeasurementsStore, drawToolsStore } = Globals.drawingStores;

    drawingCommonStore.selectedProjectId = '';

    await sleep(100); // blind fix root node not getting renamed
    
    drawingCommonStore.selectedProjectId = this.drawingDraftID;

    this.isPriceUpdatePausedBeforeOpeningDialog = priceUpdateStore.isPaused;
    priceUpdateStore.isPaused = true;

    await waitOnStoresReady([projectTreeNodesStore, drawingMeasurementsStore, drawingTreeNodesStore, drawingShapesStore]);

    const { rootNode } = drawingTreeNodesStore;

    drawToolsStore.reset();
    
    const batch = firestoreBatch();
    // comment if trying to load a lost draft
    drawingShapesStore.dropTable(batch);
    drawingTreeNodesStore.dropTable(batch);

    // ensure rootNode
    //drawingTreeNodesStore.onLoadCompletedAndVerified();


    // uncomment if trying to load a lost draft
    //return;


    rootNode.name = projectTreeNodesStore.selectedTreeNode.name + ' - ' + i18n.t('Reference Drawing');
    // duplicate from prepareDrawingDraft
    rootNode.isExpanded = true;
    rootNode.isDrawingNode = true;
    rootNode.shouldBubbleMeasurements = true;

    drawingTreeNodesStore.batchAddEditItem(rootNode, batch);

    // can't do that part locally, because root node gets deleted if created only locally and table is dropped to db
    // also can't drop only locally, because some items get created to db and never deleted
    batch.isUndoable = false;
    await batch.commit();

    this.prepareDrawingDraft();
  }

  @action
  prepareDrawingDraft() {
    // uncomment if trying to load a lost draft
    // return;

    const { treeNodesStore: drawingTreeNodesStore, shapesStore: drawingShapesStore, drawToolsStore } = Globals.drawingStores;

    let batch = firestoreBatch();

    const projectDrawingRootNode = this.projectTreeNode.children.find(node => node.isDrawingNode);
    if (projectDrawingRootNode) {
      // copy current drawing to edit window
      const drawingNodeCopy = projectDrawingRootNode.cloneDeep(batch, Globals.drawingStores, undefined, new Map<string, string>(), [], true);
      drawingNodeCopy.name = this.projectTreeNode.name + ' - ' + i18n.t('Reference Drawing')
      drawingNodeCopy.isRootNode = true;
      drawingNodeCopy.id = drawingTreeNodesStore.rootNode.id;
      drawingNodeCopy.isExpanded = true;
      drawingNodeCopy.isDrawingNode = true;
      drawingNodeCopy.shouldBubbleMeasurements = true;
      drawingTreeNodesStore.batchAddEditItem(drawingNodeCopy, batch);

      // fix bug that shouldn't happen in the first place
      const badDrawingNodes = drawingNodeCopy.descendants.filter(node => node.isDrawingNode);
      badDrawingNodes.forEach(node => node.isDrawingNode = false);
      drawingTreeNodesStore.batchAddEditItems(badDrawingNodes, batch);
    }


    // this can get confusing between drawing that bubbles down and project tree node that doesnt
    drawingTreeNodesStore.applyMeasurementValues(
      drawingTreeNodesStore.rootNode,
      this.projectTreeNode.ownMeasurementValuesArray,
      false,
      batch
    );

    drawingTreeNodesStore.selectedTreeNode = drawingTreeNodesStore.rootNode;

    // do locally only?
    batch.isUndoable = false;
    batch.commit();
  }

  cleanup = async () => {
    const { treeNodesStore: drawingTreeNodesStore, shapesStore: drawingShapesStore, measurementsStore: drawingMeasurementsStore, commonStore: drawingCommonStore, drawToolsStore } = Globals.drawingStores;

    const batch = firestoreBatch();
    batch.isUndoable = false;

    drawToolsStore.reset();

    // disabled to be able to restore if crash

    //drawingTreeNodesStore.dropTable(batch);
    //drawingShapesStore.dropTable(batch);

    drawingCommonStore.selectedProjectId = '';

    //return batch.commit();
  }

  onClose = async (shouldSave: boolean) => {
    const { treeNodesStore: projectTreeNodesStore, measurementsStore: projectMeasurementsStore, commonStore, priceUpdateStore } = Globals.defaultStores;
    const { treeNodesStore: drawingTreeNodesStore, measurementsStore: drawingMeasurementsStore } = Globals.drawingStores;

    priceUpdateStore.isPaused = this.isPriceUpdatePausedBeforeOpeningDialog;

    if (!shouldSave) {
      await this.cleanup();
      return;
    }

    commonStore.isBigUpdateOngoing = true;

    const batch = firestoreBatch();

    // cleanup status and notes
    [drawingTreeNodesStore.rootNode, ...drawingTreeNodesStore.rootNode.descendants].forEach(node => {
      node._description2 = '';
      node.status = TreeNodeStatus.None;
    })

    const newDrawingNode = drawingTreeNodesStore.rootNode.cloneDeep(batch, Globals.defaultStores, uuidv4(), new Map<string, string>(), [], true);
    newDrawingNode.isRootNode = false;

    if (newDrawingNode.backgroundImage && !isEmpty(newDrawingNode.shapes)) {
      trackInteraction(TrackedInteractions.DrawOnPDFPlan);
    }

    newDrawingNode.isDrawingNode = true;
    newDrawingNode.shouldBubbleMeasurements = true;

    // remove previous drawing
    this.projectTreeNode.children.filter(node => node.isDrawingNode).forEach(drawingNode => {
      projectTreeNodesStore.deleteNodeAndDescendants(drawingNode, true, batch);
    });

    projectTreeNodesStore.batchAddEditItem(newDrawingNode, batch);
    projectTreeNodesStore.appendNode(newDrawingNode, this.projectTreeNode, 0, batch);

    const measurementValues = newDrawingNode.ownMeasurementValuesArray; // copy before clearing
    newDrawingNode.ownMeasurementValues.clear();

    this.projectTreeNode.ownMeasurementValuesArray
      .filter(mv => !mv?.measurement?.isOneTimeUse)
      .forEach(mv => this.projectTreeNode.ownMeasurementValues.delete(mv.measurementId));

    projectTreeNodesStore.applyMeasurementValues(
      this.projectTreeNode,
      measurementValues,
      false,
      batch
    );

    projectTreeNodesStore.batchAddEditItems([newDrawingNode, this.projectTreeNode], batch);

    await batch.commit();

    await this.cleanup();

    commonStore.isBigUpdateOngoing = false;
  }

  loadDrawing = () => {

  }

  _render() {
    const { open, dialogId } = this.props;
    // SPECIFY DRAWING store explicitly: doesnt seem to have context yet
    const { treeNodesStore: drawingTreeNodesStore, shapesStore: drawingShapesStore, drawToolsStore, snapStore } = Globals.drawingStores;

    // could use loading icon
    return drawingShapesStore.isReady && drawingTreeNodesStore.rootNode && (
      <EditDialogComponent
        className={styles.root}
        dialogId={dialogId}
        open={open}
        // prevent creating a copy
        modelCopies={[drawingTreeNodesStore.rootNode]}
        FormComponent={DrawingEditDialogDetailsForm}
        onClose={this.onClose}
        extraButtons={
          <div className={styles.extraButtons}>
            {(
              !isEmpty(drawingTreeNodesStore.rootNode.shapes) || 
              !isEmpty(drawingTreeNodesStore.rootNode.descendants)
            ) && (
              <ConfirmDeleteButton
                className={styles.deleteButton}
                onClick={() => {
                  const batch = firestoreBatch();
                  const drawingRootNode = drawingTreeNodesStore.rootNode;

                  drawingRootNode.satelliteImageUrl = '';
                  drawingRootNode.satelliteImageAddress = '';
                  drawingRootNode.backgroundImage = null;
                  drawingRootNode.ownShapes = [];

                  const nodesWithoutMeasurements = drawingRootNode.descendants.filter(d => isEmpty(compact(d.measurementValuesArray)));

                  drawingTreeNodesStore.addEditItem(drawingRootNode, true, ['shapeId', 'ownShapesIds'], batch);
                  
                  if (isEmpty(nodesWithoutMeasurements)) {
                    drawingTreeNodesStore.deleteDescendants(drawingRootNode, true, batch);
                  } else {
                    nodesWithoutMeasurements.forEach(node => drawingTreeNodesStore.deleteNodeAndDescendants(node, true, batch));

                    drawingRootNode.descendants
                      .filter(descendant => !isEmpty(descendant.ownShapes))
                      .forEach(descendant => {
                        descendant.ownShapes= [];
                        drawingTreeNodesStore.addEditItem(descendant, true, ['shapeId', 'ownShapesIds'], batch);
                    })
                  }

                  drawToolsStore.reset();
                  snapStore._snapPointsMap.clear();
                }}
                text={i18n.t('Delete Drawing')}>
                <DeleteIcon />
              </ConfirmDeleteButton>)}
          </div>
        }
      />
    );
  }
}
