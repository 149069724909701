import Globals from "Globals";
import { functions } from "./FirebaseInitializedApp";
import { isProduction } from "./Utils";

export enum TrackedInteractions {
  AddItemFromMerchant = 'Add Item From Merchant',
  ApplyTasksLists = 'Apply Tasks Lists',
  DrawOnPDFPlan = 'Draw on PDF Plan',
  ReplaceATaskItem = 'Replace a Task Item',
  ChangeFeePercent = 'Change Fee Percent',
  UploadLogo = 'Upload Logo',
  DownloadOrSendReport = 'Download or Send Report',
  ApplyWindowOrDoorMeasurements = 'Apply Window or Door Measurements',
  CreateMeasurementFormula = 'Create Measurement Formula',
  ViewPriceUpdate = 'View Price Update',
  ApplyPriceUpdate = 'Apply Price Update'
}

export enum TraitValue {
  Yes = 'Yes',
  No = 'No'
}

export function trackInteraction(interaction: TrackedInteractions) {
  if (!isProduction()) {
    return; 
  }

  const { userInfoStore } = Globals.defaultStores;
  const user = userInfoStore.user;

  try {
    const logCustomerTraitsBackend = functions.httpsCallable('logCustomerTraits');

    let interactions = user?.interactionsCompleted || [];
    if (interactions.includes(interaction)) {
      return;
    }

    interactions.push(interaction);
    user.interactionsCompleted = interactions;

    userInfoStore.addEditItem(user, true, ['interactionsCompleted']);

    logCustomerTraitsBackend({ traitsData: [{ category: interaction, trait: TraitValue.Yes }] });
  } catch (e) {
    console.error('error logging customer trait', user?.email, e);
  }
}

// Needed because we can't send interactions to profitwell until user is a paying customer
// we track internally until that time
export async function syncInteractions() {
  const { userInfoStore } = Globals.defaultStores;
  const user = userInfoStore.user;

  if (!user || user.isInteractionsSyncSuccess) {
    // no need to full sync twice, only send new interactions from now on 
    return;
  }

  let traitsData = user.interactionsCompleted
    .map(traitCategory => ({ category: traitCategory, trait: TraitValue.Yes }));

  if (user.versionOnCreation > 0) {
    // for older user, we were not tracking, so we don't know which interaction never happened, only the ones that happen now
    // for newer users we set all other values to zero
    Object.values(TrackedInteractions)
      .filter(interaction => !user.interactionsCompleted.includes(interaction))
      .forEach(interaction => traitsData.push({ category: interaction, trait: TraitValue.No }));
  }

  try {
    const logCustomerTraitsBackend = functions.httpsCallable('logCustomerTraits');
    const result = await logCustomerTraitsBackend({ traitsData });

    if (result?.data?.success) {
      user.isInteractionsSyncSuccess = true;
      userInfoStore.addEditItem(user, true, ['isInteractionsSyncSuccess']);
    }
  } catch (e) {
    console.error('error syncing customer trait', user?.email, e);
  }
}