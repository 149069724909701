import { Button, IconButton, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/Info';
import AddIcon from '@material-ui/icons/PlaylistAdd';
import ObserverComponent from 'components/common/ObserverComponent';
import { PROXY_SUFFIX } from 'constants/Constants';
import { RightBarTabs } from 'constants/RightBarTabs';
import { Unit } from 'constants/Unit';
import { UnitType } from 'constants/UnitType';
import { first } from 'lodash';
import * as React from 'react';
import { InputNumberFormatDecimal } from 'utils/NumberFormatter';
import { areProvidedQuantitiesObjectsEqual, handleProvidedQuantityChange } from 'utils/ProvidedQuantityUtil';
import { showProvidingItemDialog } from 'utils/ProvidingItemUtil';
import { TrackedInteractions, trackInteraction } from 'utils/TrackingUtil';
import i18n from 'utils/i18n';
import DraggableProvidingItem from '../DraggableProvidingItem/DraggableProvidingItem';
import TextField from '../TextField/TextField';
import UnitComboBox from '../UnitComboBox/UnitComboBox';

const styles = require('./MerchantItemDetector.module.scss');

interface MerchantItemDetectorProps {
  iframeElement: HTMLIFrameElement,
}

const unitsByUnitType = new Map([[UnitType.Time, [Unit.Day, Unit.Week, Unit.Month, Unit.Year]]]);

export default class MerchantItemDetector extends ObserverComponent<MerchantItemDetectorProps> {
  detectionTimer;
  pageLoadStartTime = 0;
  lastLocation = '';

  componentDidMount() {
    const { iframeElement } = this.props;
    iframeElement.addEventListener('load', this.onIframeLoad);

    // TEMP!
    this.runDetection();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const { iframeElement } = this.props;
    iframeElement.removeEventListener('load', this.onIframeLoad);

    if (this.detectionTimer) {
      clearTimeout(this.detectionTimer);
    }
  }

  onIframeLoad = () => {
    this.runDetection();
  }

  runDetection = async () => {
    let nextIterationInterval = 10000;

    try {
      const { merchantsStore, settingsStore } = this.context;
      const { iframeElement } = this.props;
      const { contentDocument } = iframeElement;

      //TMP!
      /*const testMerchant = merchantsStore.getItemByDomain('homedepot.ca');
      const testContentDocument = new DOMParser().parseFromString(
        '<html></html>', //await fetchUrl('/itemdetector_test_hd.html'),
        'text/html'
      );
  
      merchantsStore.detectedItem = await merchantsStore.createDetectedItem(testContentDocument, testMerchant);
      if (merchantsStore.detectedItem) {
        return;
      }*/
      //--

      const isShopVisible = settingsStore.settings.selectedRightBarTab === RightBarTabs.SHOP;

      const previousDetectedItem = merchantsStore.detectedItem;

      if (
        isShopVisible &&
        contentDocument?.location
      ) {
        if (contentDocument.location.href !== this.lastLocation) {
          this.lastLocation = contentDocument.location.href;
          this.pageLoadStartTime = Date.now();
        }

        const merchantDomain = contentDocument.location.host
          .replace(PROXY_SUFFIX, '')
          .replace('www.', '');

        let merchant = merchantsStore.getMerchantByDomain(merchantDomain);

        const newDetectedItem = await merchantsStore.createDetectedItem(contentDocument, merchant);

        // ADD CHECK FOR EXISTING ITEM
        if ((
          // allow items with no price only if we had time to load price first
          (!newDetectedItem || newDetectedItem.price || (Date.now() - this.pageLoadStartTime > 3000))
          && (
            !newDetectedItem?.url ||
            newDetectedItem?.url !== merchantsStore.detectedItem?.url ||
            newDetectedItem?.price !== merchantsStore.detectedItem?.price ||
            newDetectedItem?.metaCached?.description !== merchantsStore.detectedItem?.metaCached?.description ||
            newDetectedItem?.name !== merchantsStore.detectedItem?.name || (
              // should not refresh if providedquantities are being edit by user, but should refresh if guessed quantity changed
              !areProvidedQuantitiesObjectsEqual(newDetectedItem.providedQuantities, merchantsStore.detectedItem.providedQuantities) &&
              !Array.from(merchantsStore.detectedItem.providedQuantities.values()).some(providedQuantity => providedQuantity.confidencePercentage >= 80)
            ) ||
            newDetectedItem.thumbUrl !== merchantsStore.detectedItem?.thumbUrl
          )
        )) {
          merchantsStore.detectedItem = newDetectedItem;
        }
      }

      if (isShopVisible) {
        nextIterationInterval = 400;
      } else {
        nextIterationInterval = 10000;
      }
    }
    catch (e) {
      console.error(e.toString());
    } finally {
      this.detectionTimer = setTimeout(() => this.runDetection(), nextIterationInterval);
    }
  }

  addItem = () => {
    const { merchantsStore, providingItemsStore } = this.context;
    const { detectedItem } = merchantsStore;

    let unitTypes = Array.from(detectedItem.providedQuantities.keys());
    unitTypes.forEach(unitType => {
      if (detectedItem.providedQuantities.get(unitType).quantity === 0) {
        detectedItem.providedQuantities.delete(unitType);
      }
    })
    unitTypes = Array.from(detectedItem.providedQuantities.keys());

    if (unitTypes.length === 1 && unitTypes[0] === UnitType.Unit) {
      detectedItem.wastePercent = 0;
    }

    providingItemsStore.addEditItem(detectedItem);

    trackInteraction(TrackedInteractions.AddItemFromMerchant);
  }

  handleRentalUnitChange = (e) => {
    const { merchantsStore } = this.context;
    merchantsStore.rentalTimeUnit = e.target.value;

    this.runDetection();
  }

  _render() {
    const { merchantsStore, providingItemsStore, userInfoStore } = this.context;
    const { detectedItem } = merchantsStore;

    const { user } = userInfoStore;
    const { hasHiddenMerchantInstructions } = userInfoStore.user || {};

    if (!detectedItem || !detectedItem.merchant) {
      // show messages
      return hasHiddenMerchantInstructions
        ? false
        : (
          <div className={styles.root}>
            <IconButton className={styles.hideButton} onClick={() => {
              user.hasHiddenMerchantInstructions = true;
              userInfoStore.addEditItem(user, true, ['hasHiddenMerchantInstructions']);
            }}>
              <CloseIcon />
            </IconButton>

            <div className={styles.notFoundMessage}>
              <Typography variant="subtitle2" component="div">
                <HelpIcon />
                {i18n.t('Use the browser above to shop for an item to use in your project.')}
              </Typography>
              <div>{i18n.t("The browser is provided for your convenience only. Evalumo is not affiliated with the website you choose to visit and is not responsible for your usage. Content remains the property of the website's owner at all time.")}</div>
            </div>
          </div>
        );
    }

    const { merchant } = detectedItem || {};

    const providedQuantity = first(
      Array.from(detectedItem.providedQuantities.values())
        .filter(quantity => quantity.unitType !== UnitType.Unit)
    ) || detectedItem.providedQuantities.get(UnitType.Unit);

    const isExistingItem = !!providingItemsStore.getItem(detectedItem.id);

    return (
      <div className={styles.root}>
        {detectedItem && (
          <div className={styles.detectedItem}>
            {merchant.isRental && (
              <div className={styles.rentalPeriod}>
                <Typography variant="subtitle2" className={styles.subtitle}>
                  {i18n.t('Rental period')}
                </Typography>

                <UnitComboBox
                  label={i18n.t('Unit')}
                  className={styles.unitComboBox}
                  value={merchantsStore.rentalTimeUnit}
                  onChange={this.handleRentalUnitChange}
                  margin="none"
                  unitTypeOptions={[UnitType.Time]}
                  unitsByUnitType={unitsByUnitType}
                />
              </div>
            )}

            <Typography variant="subtitle2" className={styles.subtitle}>
              {i18n.t('Detected Item')}
              {!detectedItem.price && <span>&nbsp;({i18n.t('Unknown Price')})</span>}
            </Typography>

            <div className={styles.providingItem}>
              <DraggableProvidingItem item={detectedItem} isEditable={false} />
            </div>
            {!isExistingItem && !merchant.isRental && (
              <>
                <Typography variant="subtitle2" className={styles.subtitle}>
                  {i18n.t('Provided Quantity')}
                  <IconButton className={styles.infoButton}>
                    <HelpIcon />
                  </IconButton>
                </Typography>

                <div className={styles.providedQuantity}>
                  <TextField
                    className={styles.textField}
                    value={providedQuantity.quantity}
                    onChange={handleProvidedQuantityChange(detectedItem, providedQuantity, 'quantity')}
                    margin="none"
                    inputProps={{ style: { textAlign: 'right' } }}
                    InputProps={{
                      inputComponent: InputNumberFormatDecimal,
                    }}
                    fullWidth
                  />
                  <UnitComboBox
                    label={i18n.t('Unit')}
                    className={styles.unitComboBox}
                    value={providedQuantity.unit}
                    onChange={handleProvidedQuantityChange(detectedItem, providedQuantity, 'unit')}
                    margin="none"
                    unitTypeOptions={Object.values(UnitType)}
                  />

                </div>
              </>
            )}


            {isExistingItem ? (
              <div className={styles.buttonWithDetails}>
                <Button disabled>
                  <CheckIcon />
                  {i18n.t('Item saved')}
                </Button>
                <Button onClick={() => showProvidingItemDialog(detectedItem, null)}>
                  {i18n.t('Details')}
                </Button>
              </div>
            ) : (
              <Button variant="contained" color="primary" className={styles.actionButton} onClick={this.addItem}>
                <AddIcon />
                {i18n.t('Save to My Items')}
              </Button>
            )}
          </div>
        )
        }
      </div>
    )
  }
}