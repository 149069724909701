import { Currency } from "constants/Currency";
import { DbLocationType } from "constants/DbLocationType";
import { UnitSystem } from "constants/UnitSystem";
import { first, isArray, isEmpty } from 'lodash';
import { computed } from "mobx";
import Fee from 'models/Fee';
import Settings from "models/Settings";
import FirebaseStore from 'stores/FirebaseStore';
import { modelToPlainObject } from 'utils/DeserializeUtils';
import { WriteBatch, firestore } from 'utils/FirebaseInitializedApp';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import i18n from "utils/i18n";


export default class SettingsStore extends FirebaseStore<Settings> {
  storeKey = 'settings';

  dbLocationsTypes = new Set([
    DbLocationType.User, 
    DbLocationType.Project
  ]);

  // optional only when no project is loaded
  isProjectDbOptional = true;

  hasLocalization = false;
  hasCategories = false;

  shouldKeepUserItems = true;

  @computed get supportedLanguages(): LanguageKey[] {
    return ['en', 'fr', 'es'];
  }

  @computed get settings(): Settings {
    return first(this.items) || new Settings(this.stores);
  }

  // not per project, but doesnt really work, because gets overwritten right away
  @computed get userSettings(): Settings {
    return first(Array.from(this.userCollectionItemsMap.values())) || this.settings;
  }

  @computed get isImperial(): boolean {
    return this.settings.unitSystem === UnitSystem.Imperial;
  }

  @computed get language(): LanguageKey {
    return this.settings.language;
  }

  @computed get countryCode(): CountryCode {
    return this.settings.countryCode;
  }

  @computed get currency(): Currency {
    return this.settings.currency;
  }

  // e.g.: 
  //globals.defaultStores.settingsStore.DEBUG_applySettingsToAllProjects('shouldHideEvalumoReportFooter', true)
  DEBUG_applySettingsToAllProjects = async (key: keyof Settings, value: any) => {
    const { projectsStore, settingsStore, userInfoStore } = this.stores;

    if (!key || !value) {
      return;
    }

    const db = firestore();
    const batch = firestoreBatch();

    const projectIds = projectsStore.items.map(i => i.id);

    await projectIds.reduce(async (previousTask, projectId) => {
      const projectsSettingsDocs = await (await db.collection(`/users/${userInfoStore.userEmail}/projects/${projectId}/settings`).get()).docs.map(doc => doc.id);

      projectsSettingsDocs.forEach(settingsId => {
        batch.set(db.doc(`/users/${userInfoStore.userEmail}/projects/${projectId}/settings/${settingsId}`), { [key]: value }, { merge: true });
      })

      return '';
    }, Promise.resolve(''));

    debugger;
    batch.commit();
  }

  addEditItemsImpl(items: Settings[], shouldSaveToDb = true, fieldsToUpdate = null, batchParam: WriteBatch = null) {
    const retval = super.addEditItemsImpl(items, shouldSaveToDb, fieldsToUpdate, batchParam);

    if (this.language !== i18n.language) {
      i18n.language = this.language;
    }

    if (this.countryCode !== i18n.countryCode) {
      i18n.countryCode = this.countryCode;
    }

    if (this.currency !== i18n.currency) {
      i18n.currency = this.currency;
    }

    return retval;
  }

  // todo make more generic and move to firebasestore
  /* override */ compressSubItemsIfNeeded(plainItem: any) {
    const defaultItem = modelToPlainObject(new Fee(this.stores));
    [
      ...(isArray(plainItem.fees) ? plainItem.fees : []),
      ...(isArray(plainItem.taxes) ? plainItem.taxes : [])
    ].forEach(fee => {
      Object.keys(fee).forEach(key => {
        if (JSON.stringify(fee[key]) === JSON.stringify(defaultItem[key])) {
          delete fee[key];
        }
      })
    });
  }

  public onLoadCompleted() {
    // double check that really root node isnt in db, can't afford to be a connection error
    // or we will overwrite whole project
    if (isEmpty(this.items)) {
      this.userCollection.doc('DEFAULT').get({ source: 'server' })
        .then(snapshot => {
          if (!snapshot.exists) {
            this.onLoadCompletedAndVerified();
          } else {
            debugger; // bad!
            window.location.reload();
          }
        }).catch(error => {
          if (navigator.onLine) {
            debugger;
            this.stores.commonStore.error = error.message;
            throw (error);
          }
        })
    } else {
      this.onLoadCompletedAndVerified();
    }
  }

  public onLoadCompletedAndVerified() {
    super.onLoadCompleted();

    const batch = firestoreBatch();
    batch.isUndoable = false;

    if (isEmpty(this.items)) {
      // create the project settings node if it doesn't exist
      const settings = new Settings(this.stores);
      settings.id = 'DEFAULT';

      this.addEditItem(settings, true, undefined, batch);

    } else if (this.settings.cascadeOrder === 0 /* user */ && this.stores.commonStore.selectedProjectId) {
      // ensure project level settings
      this.addEditItem(this.settings, true, undefined, batch);
    }
    batch.commit();

    i18n.language = this.language;
  }

  @computed get shouldUseLegacyFeesCalculation() {
    const { projectsStore, tasksStore } = this.stores;

    const { selectedProject } = projectsStore;
    const { settings } = this;

    return (
      selectedProject?.version <= 3 &&
      !settings.areSomeFeesFilteredByCategories &&
      !settings.areSomeFeesFilteredBySubtypes &&
      !tasksStore.items.some(task => task.providingItem?.isExemptFromAllFees)
    );
  }

}
