import ReportHeaderRow from 'components/reports/ReportHeaderRow';
import { ReportBodyRow } from 'components/reports/ReportInterfaces';
import { ProvidingItemTaskBehaviour } from 'constants/ProvidingItemConstants';
import { ReportGrouping, ReportSortType, ReportTasksFilter, ReportTasksVisibility, ReportTotalsVisibility } from 'constants/ReportOptionsConstants';
import { UnitType } from 'constants/UnitType';
import { deburr, isEmpty, sortBy, trim } from 'lodash';
import { transaction } from 'mobx';
import Report from 'models/Report';
import Task from 'models/Task';
import uuidv4 from 'uuid/v4';
import i18n from './i18n';

export const getTaskDescriptionColspan = (report: Report) => {
  const { shouldShowTaskCost, shouldShowQuantities, shouldShowTaskUnitPrice } = report;
  return (
    1 +
    // when hiding either task quantities or total we also hide unit cost
    (shouldShowQuantities ? 0 : 2) +
    (shouldShowTaskCost ? 0 : 1)
  );
}

export const CURRENCY_EXCEL = '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)';

export function chunkArray(data: any[], n) {
  var group = [];
  for (var i = 0, j = 0; i < data.length; i++) {
    if (i >= n && i % n === 0)
      j++;
    group[j] = group[j] || [];
    group[j].push(data[i])
  }
  return group;
};

export function b64toBlob(b64Data, contentType) {
  var byteCharacters = atob(b64Data)

  var byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    var slice = byteCharacters.slice(offset, offset + 512),
      byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    var byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  var blob = new Blob(byteArrays, { type: contentType })
  return blob;
}

export const isFilterAffectingTotal = (filter: ReportTasksFilter) => (
  filter === ReportTasksFilter.LabourOnly ||
  filter === ReportTasksFilter.MaterialOnly
);

const isSameProvidingItem = (taskA, taskB) => (
  taskA.providingItem && taskB.providingItem &&
  taskA.providingItem.id === taskB.providingItem.id &&
  taskA.providingItemDisplayName === taskB.providingItemDisplayName &&
  taskA.providingItemPrice === taskB.providingItemPrice
);

// quick fix in prod: filter out tasks that are hidden but needed to be kept to get correct total
export const getVisibleRows = (rows: (ReportBodyRow | ReportHeaderRow)[], report: Report) => {
  const { tasksFilter, totalsVisibility, tasksVisibility } = report;

  // hidden means kept in total
  // filtered out means not kept in total

  // ouf
  const retval =
    rows
      .filter(
        row => {
          const areSomeTasksHidden = [ReportTasksFilter.HideLabour, ReportTasksFilter.HideMaterial].includes(tasksFilter);
          const areAllTasksHidden = (tasksVisibility == ReportTasksVisibility.Hidden);
          const isHeader = !row?.task;
          const isHeaderRowNoHiddenTasks = isHeader && ![ReportTasksFilter.HideLabour, ReportTasksFilter.HideMaterial].includes(tasksFilter);
          const isHeaderRowNonEmptyTasksAfterHide = isHeader && areSomeTasksHidden && !isEmpty(getVisibleRows(row.tasks.map(t => ({ task: t })), report));
          const isAlwaysShow = row?.task?.providingItem?.reportTaskBehaviour === ProvidingItemTaskBehaviour.AlwaysShow;
          const isNotAlwaysHide = (totalsVisibility === ReportTotalsVisibility.Task || row?.task?.providingItem?.reportTaskBehaviour !== ProvidingItemTaskBehaviour.AlwaysHide);
          const hasNoProvidingItem = isHeader && !row?.task?.providingItem;
          const isHiddenLabourTask = (ReportTasksFilter.HideLabour === tasksFilter && !row?.task?.providingItem.isLabour);
          const isHiddenMaterialTask = (ReportTasksFilter.HideMaterial === tasksFilter && !row?.task?.providingItem.isMaterial);

          const retval2 = (
            isHeaderRowNoHiddenTasks ||
            isHeaderRowNonEmptyTasksAfterHide ||
            (isHeader && areAllTasksHidden) ||
            !isHeader && (
              isAlwaysShow || (
                !areAllTasksHidden && (
                  isNotAlwaysHide && (
                    !areSomeTasksHidden ||
                    hasNoProvidingItem || (
                      isHiddenLabourTask ||
                      isHiddenMaterialTask
                    ))))
            ));

          return retval2;
        })
    ;

  return retval;
}

export const processTasks = (tasks: Task[], sortType = ReportSortType.NoSort, tasksFilter = ReportTasksFilter.AllTasks, isEmptyCheck = false) => {
  const processedTasks = tasks[isEmptyCheck ? 'find' : 'filter'](
    task => task.isSeparator || (
      task.treeNode &&
      task.measurement &&
      task.providingItem && (
        ![ReportTasksFilter.LabourOnly, ReportTasksFilter.MaterialOnly].includes(tasksFilter) || (
          // only skip tasks that are not calculated in totals. If only hidden but calculated in total, skip at render time
          ([ReportTasksFilter.LabourOnly].includes(tasksFilter)) && task.providingItem.isLabour ||
          ([ReportTasksFilter.MaterialOnly].includes(tasksFilter)) && task.providingItem.isMaterial
        )
      ) &&
      !(task.treeNode.shouldExcludeFromReports || task.treeNode.isParentExcludedFromReports)
    ));

  if (isEmpty(processedTasks)) {
    return [];
  }

  return sortTasks(isEmptyCheck ? [processedTasks] : processedTasks, sortType);
}

export const sortTasks = (tasks: Task[], sortType = ReportSortType.NoSort): Task[] => {
  if (isEmpty(tasks)) {
    return tasks;
  }

  if (sortType !== ReportSortType.NoSort) {
    if (sortType === ReportSortType.PriceList) {
      const { providingItemsStore } = tasks[0].stores;
      tasks = sortBy(tasks, task => providingItemsStore.indexByItem.get(task.providingItem?.id));
    }

    if (sortType === ReportSortType.Alphabetical) {
      tasks = sortBy(tasks, task => deburr(trim(task.providingItem?.name || '')));
    }


    if (sortType === ReportSortType.MaterialFirst || sortType === ReportSortType.MaterialLast) {
      tasks = sortBy(tasks, task => sortType === ReportSortType.MaterialLast ? task.providingItem?.isMaterial : task.providingItem?.isLabour);
    }
  }

  return tasks;
}

// this gets called a lot for no reason
export const mergeTasksByProvidingItem = (tasks: Task[], sortType = ReportSortType.NoSort, tasksFilter = ReportTasksFilter.AllTasks, isEmptyCheck = false) => transaction(() => {
  let nonEmptyTasks = processTasks(tasks, ReportSortType.NoSort, tasksFilter, isEmptyCheck);

  if (tasks.some(task => task.isSeparator)) {
    // cannot merge when there is a separator
    return nonEmptyTasks;
  }

  return sortTasks(
    nonEmptyTasks
      .reduce<Task[]>((mergedTasks, currentTask) => {
        // change mergedTasks to Map instead of array
        // clone is slow
        const taskWithSameItem = mergedTasks.find(task => isSameProvidingItem(task, currentTask));

        if (!taskWithSameItem) {
          mergedTasks.push(currentTask);
        } else {
          const mergedTask = taskWithSameItem.clone();
          mergedTask.isMerged = true;
          mergedTask.mergedOriginalTasksIds = new Set([
            mergedTask.id,
            currentTask.id,
            ...mergedTask.mergedOriginalTasksIds,
            ...currentTask.mergedOriginalTasksIds
          ]);

          const taskWithFixedPrice = [currentTask, taskWithSameItem]
            .find(task => task.measurement.unitType === UnitType.FixedPrice);

          if (currentTask.measurement.id !== taskWithSameItem.measurement.id) {
            const newMeasurement = currentTask.measurement.clone();

            let newMeasurementName = currentTask.measurement.name === taskWithSameItem.measurement.name
              ? currentTask.measurement.name
              : i18n.t('Quantity');


            if (taskWithFixedPrice) {
              newMeasurementName = taskWithFixedPrice.measurement.name;
              newMeasurement.unitType = UnitType.FixedPrice;
              newMeasurement.id = uuidv4();
            }

            newMeasurement.name = newMeasurementName;

            newMeasurement.isOneTimeUse = true;
            mergedTask.tempMeasurement = newMeasurement;
          }

          if (taskWithFixedPrice) {
            mergedTask._quantityOverride = (
              currentTask.price +
              taskWithSameItem.price
            );
          } else {
            mergedTask._quantityOverride = (
              currentTask.roundedQuantity +
              taskWithSameItem.roundedQuantity
            );
          }


          // dynamic price and name item
          // can it merge properly 2 dynamic formulas items
          if (
            taskWithSameItem.providingItem.priceFormula ||
            taskWithSameItem.providingItemDisplayName !== taskWithSameItem.providingItem.name
          ) {
            mergedTask._nameOverride = taskWithSameItem.providingItemDisplayName;
            mergedTask._priceOverride = taskWithSameItem.providingItemPrice;
          }

          const taskIndex = mergedTasks.indexOf(taskWithSameItem);
          mergedTasks.splice(taskIndex, 1, mergedTask);
        }

        return mergedTasks;
      }, []),
    sortType);
});

export const reportHasOnlyOneGroup = (report: Report) => {
  return [ReportGrouping.Category, ReportGrouping.None].includes(report.grouping);
}