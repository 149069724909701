import { computed, observable } from "mobx";
import { list, primitive, serializable } from "serializr";
import Stores from 'stores/Stores';
import { WriteBatch } from 'utils/FirebaseInitializedApp';
import { encodeProjectName } from "utils/ProjectUtils";
import i18n from "utils/i18n";
import * as uuidv4 from 'uuid/v4';
import ModelBaseWithCategory from './ModelBaseWithCategories';
;

/* to test babel
const test = {
  a: 2
};

const test2 = {
  b: 2,
  ...test
};
*/

export default class Project extends ModelBaseWithCategory {
  @serializable type = 'Project';

  // to allow for old project to keep same total when a calculation business logic changes
  @serializable @observable version = 0;
  @serializable @observable versionOnCreation = 0;

  @serializable @observable lastBackupMiliseconds = 0;

  @serializable isSampleProject = false;

  @serializable previousName = '';

  // sharing
  @serializable(list(primitive())) @observable readers = [];
  @serializable(list(primitive())) @observable editors = [];
  // --

  @computed get encodedName(): string {
    return encodeProjectName(this.name);
  }

  @computed get isUntitled(): boolean {
    return this.name.includes(i18n.t('Untitled Project'));
  }

  @serializable @observable name = 'Projet sans titre';

  // permissions
  @serializable @observable isPrivate = false;

  // hack to sort by date desc
  @computed get index() {
    return this.updated._i && 1/this.updated._i;
  }
  set index(value) {
    //nothing
  }

  cloneDeep(
    batchParam: WriteBatch,
    targetStores: Stores, // should be different from source store when copying a project
    newId: ModelId = uuidv4(),
    shouldKeepSameTasksIds = false,
  ): this {
    // ignores target stores because all projects are stored at the same places for a user
    const projectCopy = super.clone(this.stores, newId);
    projectCopy.id = newId;

    targetStores.commonStore.selectedProjectId = projectCopy.id;
    const { rootNode } = this.stores.treeNodesStore;

    const rootNodeCopy = rootNode.cloneDeep(batchParam, targetStores, rootNode.id, undefined, undefined, undefined, undefined, shouldKeepSameTasksIds);
    
    targetStores.treeNodesStore.deleteItem(targetStores.treeNodesStore.rootNode.id, true, batchParam);
    targetStores.treeNodesStore.batchAddEditItem(rootNodeCopy, batchParam);

    // could copy categories & reports

    return projectCopy;
  }
}