import { isProduction } from 'utils/Utils';

let environment = {
  APP_NAME: 'Evalumo',
  firebase: {},
};

if (isProduction()) {
  // PROD
  environment.firebase = {
    apiKey: 'AIzaSyBOK0SjoPPxFKicRgNLodSalFQtHOaorSI',
    authDomain: 'auth.evalumo.com',
    databaseURL: 'https://evalumo-prod.firebaseio.com',
    projectId: 'evalumo-prod',
    storageBucket: 'evalumo-prod.appspot.com',
    messagingSenderId: '663211318889'
  };
} else { // DEV
  environment.firebase = {
    apiKey: 'AIzaSyA4NbNWdAh1ucAZgMDAk74dNBsNSiLTpCw',
    //authDomain: 'auth-test.evalumo.com',
    authDomain: 'estimo-cb0b2.firebaseapp.com',
    databaseURL: 'https://estimo-cb0b2.firebaseio.com',
    projectId: 'estimo-cb0b2',
    storageBucket: 'estimo-cb0b2.appspot.com',
    messagingSenderId: '261994850344',
  };
}

export default environment;