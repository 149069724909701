// Only to prepare the dialog, the actual content should be done in MaterialDetailsForm

import { Button } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { IDialogProps } from 'constants/CommonProps';
import { UnitType } from 'constants/UnitType';
import { isEmpty, trim } from 'lodash';
import Measurement from 'models/Measurement';
import ModelBase from 'models/ModelBase';
import Task from 'models/Task';
import TreeNode from 'models/TreeNode';
import * as React from 'react';
import DbItemFactory from 'utils/DbItemFactory';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import { formulaIsNumber } from 'utils/MeasurementFormatter';
import { duplicateMeasurement, measurementHasDefaultName, showMeasurementDialog } from 'utils/MeasurementUtil';
import { areModelsDifferent } from 'utils/Utils';
import i18n from 'utils/i18n';
import EditDialogComponent from '../EditDialogComponent/EditDialogComponent';
import MeasurementDetailsForm from '../MeasurementDetailsForm/MeasurementDetailsForm';
import ObserverComponent from '../ObserverComponent';

const styles = require('./MeasurementEditDialog.module.scss');

interface IMeasurementEditDialog extends IDialogProps {
  measurement: Measurement,
  treeNode: TreeNode,
  task?: Task,
}

interface IMeasurementEditDialogState {
  modelCopies: ModelBase[]
}

export default class MeasurementEditDialog extends ObserverComponent<IMeasurementEditDialog, IMeasurementEditDialogState> {
  state = {
    modelCopies: []
  };

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    const { measurement, treeNode, task } = this.props;

    const models = [measurement, treeNode, task];
    if (isEmpty(this.state.modelCopies)) {
      const modelCopies = models.map(model => !!model && model.clone());
      this.setState({
        modelCopies
      });
    }
  }

  handleUserItemReset = async () => {
    const { measurementsStore, treeNodesStore, dialogsStore } = this.context;
    const { measurement, dialogId } = this.props;

    await measurementsStore.resetToUserItems([measurement]);

    const treeNodesToSave = [];
    treeNodesStore.allNodes.forEach(node => {
      const measurementValue = node.ownMeasurementValues.get(measurement.id);
      if (measurementValue) {
        measurementValue.formula = measurement.defaultFormula;
        treeNodesToSave.push(node);
      }
    })

    treeNodesStore.addEditItems(treeNodesToSave, true, ['__formula']);

    // would be better to reload item, but not sure people will use this function much right now
    dialogsStore.hideDialog(dialogId);
  }

  onClose = (shouldSave: boolean, modelCopies: ModelBase[]) => {
    if (!shouldSave || isEmpty(modelCopies)) {
      return;
    }

    // NEED TO SHARE save CODE WITH MeasurementComponent

    const originalMeasurement = this.props.measurement;
    const batch = firestoreBatch();

    
    let measurement = modelCopies[0] as Measurement;
    const treeNodeCopy = modelCopies[1] as TreeNode;
    const taskCopy = modelCopies[2] as Task;

    const shouldSaveDefaultFormulaEvenIfReadonly = (
      formulaIsNumber(originalMeasurement.defaultFormula) && 
      formulaIsNumber(measurement.defaultFormula) &&
      measurement.defaultFormula !== originalMeasurement.defaultFormula
    );

    const { measurementsStore, treeNodesStore, tasksStore, userInfoStore } = this.context;

    // trim because name wll be used as a variable later
    measurement.name = trim(measurement.name);

    const measurementValue = treeNodeCopy.measurementValues.get(measurement.id);

    // stop using copy
    const treeNode = treeNodesStore.getItem(treeNodeCopy.id);

    if (
      measurement.isOneTimeUse &&
      !measurementHasDefaultName(measurement) &&
      measurement.unitType !== UnitType.FixedPrice
    ) {
      measurement.isOneTimeUse = false;
    }

    if (!measurement.isReadonly) {
      measurementsStore.batchAddEditItem(measurement, batch);
    } else if (shouldSaveDefaultFormulaEvenIfReadonly) {
      measurementsStore.addEditItem(measurement, true, ['__defaultFormula'], batch);
    }

    measurementValue.measurement = measurement;

    treeNodesStore.applyMeasurementValues(treeNode, [measurementValue], false, batch);

    if (taskCopy?.adjustment) {
      tasksStore.batchAddEditItem(taskCopy, batch);
    }

    batch.commit();

    measurementValue.tempMeasurement = null;
    measurementValue.tempTreeNode = null;
  }

  resetFormula = () => {
    const { dialogsStore } = this.context;
    const { measurement, dialogId, treeNode, task } = this.props;
    const measurementValue = treeNode.measurementValues.get(measurement.id);

    measurementValue.__formula = Object.assign({}, measurement.__defaultFormula);
    treeNode.ownMeasurementAdjustments.delete(measurement.id);

    this.onClose(true, [measurement, treeNode, task]);
    dialogsStore.hideDialog(dialogId);
  }

  // move to store
  handleFactoryReset = async () => {
    const { measurementsStore } = this.context;
    const { modelCopies } = this.state;

    let measurement = modelCopies[0] as Measurement;
    const treeNode = modelCopies[1] as TreeNode;

    const masterMeasurementData = measurementsStore.cachedMasterData[measurement.id];

    if (!masterMeasurementData) {
      return;
    }

    measurement = DbItemFactory.create(masterMeasurementData, this.context) as Measurement;
    measurement.cascadeOrder = 0;
    measurement.cascadeOrders = new Set([0]);

    const measurementValue = treeNode.measurementValues.get(measurement.id);

    measurementValue.__formula = Object.assign({}, measurement.__defaultFormula);

    this.setState({
      modelCopies: [
        measurement,
        modelCopies[1],
        modelCopies[2]
      ]
    });
  }

  _render() {
    const { measurementsStore, tasksStore } = this.context;
    const { open, dialogId, measurement, treeNode, task } = this.props;
    const { modelCopies } = this.state;
    let measurementValue = treeNode.measurementValues.get(measurement.id);
    const userCollectionMeasurement = measurementsStore.userCollectionItemsMap.get(measurement.id);
    

    if (isEmpty(modelCopies)) {
      return null;
    }

    const extraButtons = (
      <>
        {(
          measurementValue &&
          !measurementValue.areDescendantsDefaultFormula &&
          !measurement.isOneTimeUse
        ) ? (
          <Button onClick={this.resetFormula} color="primary">
            {i18n.t('Reset to default')}
          </Button>
        ) : (
          measurement.isReadonly && (
            <>
              <div className={styles.readonlyMessage}>
                <LockIcon />
                <div>
                  <p>
                    {i18n.t('Some fields are not editable for this measure')}
                  </p>
                  <p>
                    {i18n.t('Use the "Adjustments" section or duplicate to create an editable copy')}
                  </p>
                </div>
              </div>

              <Button style={{ marginLeft: 0 }} onClick={() => {
                const measurementCopy = duplicateMeasurement(measurement, this.context, dialogId)()?.[0];
                if (task) {
                  task.measurement = measurementCopy;
                  tasksStore.addEditItem(task, true, ['measurementId']);
                }
                
                showMeasurementDialog(measurementCopy, treeNode, task);
              }}>{i18n.t('Duplicate')}</Button>
            </>
          ))}
        <div style={{ flex: 1 }} />
      </>
    );

    return (
      <EditDialogComponent
        className={styles.root}
        dialogId={dialogId}
        open={open}
        // overkill creating a copy of the whole treenode, when we only need measurementValue
        //models={[measurement, treeNode, task]}
        modelCopies={modelCopies}
        FormComponent={MeasurementDetailsForm}
        FormComponentProps={{
          onFactoryReset: measurement.cascadeOrders.has(0) && measurement.cascadeOrders.size > 1 && this.handleFactoryReset,
          onUserItemReset: areModelsDifferent(userCollectionMeasurement, measurement) && this.handleUserItemReset
        }}
        onClose={this.onClose}
        extraButtons={extraButtons}
      />
    );
  }
}