import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, IconButton, Tooltip } from '@material-ui/core';
import PlusIcon from '@material-ui/icons/Add';
import AcceptIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import SquareIcon from '@material-ui/icons/CropSquare';
import HelpIcon from '@material-ui/icons/Info';
import MinusIcon from '@material-ui/icons/Remove';
import MarkerIcon from '@material-ui/icons/Room';
import LengthIcon from '@material-ui/icons/Timeline';
import Globals from 'Globals';
import classnames from 'classnames';
import { CategoryType } from 'constants/CategoryType';
import { IEditComponentProps } from 'constants/CommonProps';
import { Unit } from 'constants/Unit';
import { UnitType } from 'constants/UnitType';
import { noop } from 'lodash';
import { computed } from 'mobx';
import Category from 'models/Category';
import Measurement from 'models/Measurement';
import MeasurementValue from 'models/MeasurementValue';
import Task from 'models/Task';
import TreeNode from 'models/TreeNode';
import * as React from 'react';
import { allowDrop } from 'utils/DragUtils';
import { handleChange } from 'utils/FormUtils';
import MeasurementFormatter, { COUNT_FUNCTION, LENGTH_FUNCTION, SURFACE_FUNCTION, enquoteVar, formulaIsNumber } from 'utils/MeasurementFormatter';
import { shouldUseAsDefaultFormula } from 'utils/MeasurementUtil';
import { TrackedInteractions, trackInteraction } from 'utils/TrackingUtil';
import { getUnitTypeForUnit } from 'utils/UnitFormatter';
import i18n from 'utils/i18n';
import AdjustmentFormItem from '../AdjustmentFormItem/AdjustmentFormItem';
import CategoriesCombobox from '../CategoriesCombobox/CategoriesCombobox';
import ColorButton from '../ColorButton/ColorButton';
import { DivideIcon, FunctionIcon, LeftParenthesisIcon, MultiplyIcon, RightParenthesisIcon } from '../Icons';
import ImageBox from '../ImageBox/ImageBox';
import MeasurementOptions from '../MeasurementOptions/MeasurementOptions';
import MeasurementPopupMenu from '../MeasurementPopupMenu/MeasurementPopupMenu';
import MenuPopupButton from '../MenuPopupButton/MenuPopupButton';
import ObserverComponent from '../ObserverComponent';
import SelectedMeasurements from '../SelectedMeasurements/SelectedMeasurements';
import TextField from '../TextField/TextField';
import UnitComboBox from '../UnitComboBox/UnitComboBox';

const styles = require('./MeasurementDetailsForm.module.scss');

interface IMeasurementDetailsFormState {
  isEditingFormula: boolean,
  isDraggingOverFormula: boolean,
  // disable auto check logic, if user checks manually
  shouldAutoCheckDefaultFormula: boolean,
  // manual value
  isDefaultFormulaManuallyChecked: boolean,
  defaultFormulaCopy: string,
  isAdvancedOptionsExpanded: boolean,
}

interface IMeasurementDetailsFormProps {
  onFactoryReset: () => void,
  onUserItemReset: () => void,
}

export default class MeasurementDetailsForm extends ObserverComponent<IEditComponentProps & IMeasurementDetailsFormProps, IMeasurementDetailsFormState> {
  state = {
    isEditingFormula: false,
    isDraggingOverFormula: false,
    shouldAutoCheckDefaultFormula: true,
    isDefaultFormulaManuallyChecked: false,
    defaultFormulaCopy: '0',
    isAdvancedOptionsExpanded: false,
  };

  formulaInputRef;

  get treeNode(): TreeNode {
    return this.props.models[1] as TreeNode;
  }

  get task(): Task {
    return this.props.models[2] as Task;
  }

  componentDidMount() {
    this.setState(this.getState());
  }

  getState() {
    const measurement = this.props.models[0] as Measurement;
    const treeNode = this.props.models[1] as TreeNode;

    let measurementValue = treeNode.measurementValues.get(measurement.id);

    if (!measurementValue) {
      measurementValue = new MeasurementValue(
        this.context,
        treeNode,
        measurement,
      );

      // vérifier si n'affecte pas node orig
      treeNode.ownMeasurementValues.set(measurement.id, measurementValue)
    }

    measurementValue.tempMeasurement = measurement;
    measurementValue.tempTreeNode = treeNode;

    return {
      defaultFormulaCopy: measurement.defaultFormula,
    }
  }

  @computed get isNewMeasurement() {
    const { measurementsStore } = this.context;
    return !measurementsStore.getItem(this.measurement.id);
  }

  @computed get shouldUseAsDefaultFormula() {
    const { isDefaultFormulaManuallyChecked, shouldAutoCheckDefaultFormula } = this.state;

    if (!shouldAutoCheckDefaultFormula) {
      return isDefaultFormulaManuallyChecked;
    }

    return shouldUseAsDefaultFormula(
      this.isNewMeasurement,
      this.measurementValue.formula,
      this.measurement.defaultFormula,
      this.measurement.unitType !== UnitType.Projection && this.measurement.unitType !== UnitType.Angle
    );
  }

  @computed
  get measurement(): Measurement {
    return this.props.models[0] as Measurement;
  }

  @computed
  get measurementValue(): MeasurementValue {
    return (this.props.models[1] as TreeNode)
      .measurementValues.get(this.measurement.id) as MeasurementValue;
  }

  handleFormulaClick = () => {
    const { isEditingFormula } = this.state;
    if (!isEditingFormula) {
      this.setState({ isEditingFormula: true });
    }
  }

  handleBlur = (event) => {

  }

  handleBlurCapture = (event) => {

  }

  saveFormulaChange = () => {
    this.exitFormulaEditMode();
  }

  undoFormulaChange = () => {
    this.exitFormulaEditMode();
  }

  exitFormulaEditMode = () => {
    this.setState({ isEditingFormula: false });
  }

  onFormulaDragEnter = () => {
    this.setState({ isDraggingOverFormula: true });
  }

  onFormulaDragLeave = () => {
    this.setState({ isDraggingOverFormula: false });
  }

  onFormulaKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      this.props.handleClose(true);
    }
  }

  beforeClose = (shouldSave = true) => {
    if (shouldSave && this.shouldUseAsDefaultFormula) {
      this.measurement.defaultFormula = this.measurementValue.formula;
    }
  }

  onFormulaDrop = (event: React.DragEvent<HTMLElement>) => {
    const { dragAndDropStore } = this.context;

    this.setState({ isDraggingOverFormula: false });

    if (!(dragAndDropStore.dragObject instanceof Measurement)) {
      dragAndDropStore.dragObject = null;
      return;
    }

    const dragObject = dragAndDropStore.dragObject as Measurement;

    if (dragObject.id === this.measurement.id) {
      dragAndDropStore.dragObject = null;
      return; // should show a message too
    }

    let { formula } = this.measurementValue;

    this.measurementValue.formula =
      (formula.match(/^[ 0-9]+$/) ? '' : formula.trim() + ' ') +
      enquoteVar(dragObject.name);

    dragAndDropStore.dragObject = null;

    trackInteraction(TrackedInteractions.CreateMeasurementFormula);
  }

  addFunctionToFormula = (functionWithParams: string) => () => {
    let { formula } = this.measurementValue;

    this.measurementValue.formula =
      (formula.match(/^[ 0-9]+$/) ? '' : formula.trim() + ' ') +
      functionWithParams;
  }

  handleFormulaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as any;
    const stringValue = value !== undefined ? '' + value : undefined;

    this.measurementValue.formula = stringValue;
  }

  handleMathOperatorClick = (operator: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
    const caretPos = this.formulaInputRef.selectionStart;
    const formula = this.measurementValue.formula;

    if (!caretPos) {
      // never want to add at the beginning, so assume adding at the end
      this.measurementValue.formula += ' ' + operator;
    } else {
      this.measurementValue.formula = formula.slice(0, caretPos) + ' ' + operator + ' ' + formula.slice(caretPos);
    }

    this.formulaInputRef.focus();
  }

  handleChangeUnit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newUnit = event.target.value as Unit;

    this.measurement.unitType = getUnitTypeForUnit(newUnit);
    this.measurement.displayUnit = newUnit;
  }

  handleChangeCategory = (category: Category) => {
    this.measurement.category = category;
  }

  handleSubcategoryChange = (subcategory: Category) => {
    this.measurement.subcategory = subcategory;
  }

  handleUseAsDefaultValueChange = (event, checked: boolean) => {
    if (!checked) {
      this.measurement.defaultFormula = this.state.defaultFormulaCopy;
    }
    this.setState({
      shouldAutoCheckDefaultFormula: false,
      isDefaultFormulaManuallyChecked: checked
    },
      () => this.handleFormulaChange({ target: { value: this.measurementValue.formula } })
    );
  }

  _render() {
    const { onFactoryReset, onUserItemReset } = this.props;
    const { dragAndDropStore, measurementsStore } = this.context;
    const { dialogId } = this.props;
    const { isEditingFormula, isDraggingOverFormula, isAdvancedOptionsExpanded } = this.state;
    const isDraggingDimension = dragAndDropStore.isDragging && (dragAndDropStore.dragObject instanceof Measurement)
    const measurement = this.measurement;
    const measurementValue = this.measurementValue;

    if (!measurementValue) {
      return null;
    }

    const valueWithoutUnit = MeasurementFormatter.getValueWithoutUnit(measurementValue);
    const formula = measurementValue.formula;
    const isNumeric = !measurementValue.formula || formulaIsNumber(measurementValue.formula);

    return (
      <div className={
        classnames(styles.root, {
          [styles.isEditingFormula]: isEditingFormula,
          [styles.isReadonly]: measurement.isReadonly,
        })}>
        <div className={styles.title} id="draggable-dialog-title">
          <TextField
            shouldFocusOnMount={this.isNewMeasurement}
            id="measurement_name"
            label={i18n.t('Measurement name')}
            className={classnames(styles.textField, styles.nameField, { [styles.isEmptyTitle]: !measurement.name })}
            value={measurement.name}
            disabled={measurement.isReadonly}
            onChange={handleChange(measurement, 'name')}
          />

          <CategoriesCombobox
            className={styles.categoryCombo}
            categoryTypes={[CategoryType.Measurement]}
            category={measurement.category}
            disabled={measurement.isReadonly}
            onChange={this.handleChangeCategory}
          />

          <CategoriesCombobox
            className={styles.categoryCombo}
            parentCategory={measurement.category}
            category={measurement.subcategory}
            disabled={measurement.isReadonly}
            onChange={this.handleSubcategoryChange}
          />

          <MeasurementPopupMenu
            onFactoryReset={!measurement.isReadonly && onFactoryReset}
            onUserItemReset={onUserItemReset}
            item={measurement}
            task={this.task}
            dialogId={dialogId}
          />
        </div>

        <div className={styles.content}>
          <div className={styles.column}>
            {/*
            <MeasurementImages
              className={styles.image}
              measurementType={measurement.measurementType}
            />*/}
            <ImageBox
              model={measurement}
              store={measurementsStore}
              className={styles.image}
              imageHeight={measurement.imageUrl ? 350 : 175}

              style={{ pointerEvents: measurement.isReadonly ? undefined : 'none' }}
            />

            <TextField
              label="Description"
              multiline
              rowsMax="3"
              className={styles.textField + ' ' + styles.descriptionField}
              value={measurement.description}
              //disabled={measurement.isReadonly}
              onChange={handleChange(measurement, 'description')}
            />

            {!isAdvancedOptionsExpanded && (
              <span
                className={styles.enterQuantityLink}
                onClick={() => this.setState({ isAdvancedOptionsExpanded: !isAdvancedOptionsExpanded })}
              >
                {i18n.t('Advanced options')}
              </span>
            )}

            <ExpansionPanel className={styles.expansionPanel} expanded={isAdvancedOptionsExpanded}>
              <ExpansionPanelSummary />
              <ExpansionPanelDetails className={styles.expansionPanelContent}>
                <MeasurementOptions
                  measurement={measurement}
                  shouldUseAsDefaultFormula={this.shouldUseAsDefaultFormula}
                  onUseAsDefaultValueChange={this.handleUseAsDefaultValueChange}
                />
                <div>
                  <span className={styles.label}>
                    <span>{i18n.t("Color (when applied to drawing element)")}</span>
                    <Tooltip title={i18n.t(`Normally, color should be applied to the category or subcategory containing a measurement. But it's possible to choose a single measurement if needed.`)}>
                      <IconButton className={styles.infoButton}>
                        <HelpIcon />
                      </IconButton>
                    </Tooltip>
                  </span>

                  <div className={styles.colorButtonContainer}>
                    <ColorButton
                      width={13}
                      className={styles.colorButton}
                      color={measurement.legendColor}
                      onChange={color => measurement.legendColor = color.hex}
                    />

                    <IconButton onClick={() => measurement.legendColor = ''} >
                      <ClearIcon />
                    </IconButton>
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            {/*<FormControl className={styles.textField} margin="normal">
              <InputLabel htmlFor="filled-age-simple">Catégorie</InputLabel>
              <Select
                value={5}
                //onChange={this.handleChange}
                input={<FilledInput name="age" id="filled-age-simple" />}
              >
                {// todo: waste dropdown should include a button to waste by categories menu
                }
                <MenuItem value={0}>None</MenuItem>
                <MenuItem value={5}>Murs</MenuItem>
                <MenuItem value={10}>10 %</MenuItem>
                <MenuItem value={15}>15 %</MenuItem>
                <MenuItem value={30}><em>Waste settings...</em></MenuItem>
              </Select>
            </FormControl>
            */}
          </div>

          <div className={styles.column + ' ' + styles.column2}>
            <div className={styles.formulaDimensions}>
              <div className={styles.formulaDimensionsHeader}>
                {i18n.t('Available measurements')}
                <IconButton onClick={this.saveFormulaChange}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className={styles.formulaDimensionsContent}>
                <SelectedMeasurements />
              </div>
            </div>

            <div className={classnames(
              styles.tabContent, {
              [styles.isFormula]: true,
            })}>
              <div className={styles.formulaContainer}>
                <div />
                <TextField
                  disabled={measurement.isReadonly && !isNumeric}
                  shouldFocusOnMount={!this.isNewMeasurement}
                  shouldSelectAllOnFocus={measurement.isReadonly && isNumeric}
                  label={isNumeric ? i18n.t('Value') : i18n.t('Formula')}
                  inputRef={ref => this.formulaInputRef = ref}
                  className={classnames(styles.textField, styles.formulaField, {
                    [styles.isDraggingDimension]: isDraggingDimension,
                    [styles.isDraggingDimensionOver]: isDraggingOverFormula,
                    [styles.isEditingFormula]: isEditingFormula,
                    [styles.isDisabled]: measurement.isReadonly,
                  })}
                  value={formula}
                  onChange={this.handleFormulaChange}
                  onClick={measurement.isReadonly ? noop : this.handleFormulaClick}
                  onBlurCapture={this.handleBlurCapture}
                  onBlur={this.handleBlur}
                  onDragEnter={this.onFormulaDragEnter}
                  onDragLeave={this.onFormulaDragLeave}
                  onDragOver={allowDrop}
                  onDrop={this.onFormulaDrop}
                  onKeyDown={this.onFormulaKeyDown}
                  multiline
                  //rowsMax={3}
                  fullWidth

                  InputProps={{
                    inputComponent: (measurement.isReadonly && isNumeric)
                      ? measurement.inputFormatter
                      : undefined
                  }}
                />
                <div className={styles.formulaAcceptButtons}>
                  {/*<IconButton className={styles.cancelButtonIcon} onClick={this.undoFormulaChange}><CancelIcon /></IconButton>*/}
                  <IconButton className={styles.acceptButtonIcon} onClick={this.saveFormulaChange}><AcceptIcon /></IconButton>
                </div>


              </div>

              <div className={styles.formulaMathButtons}>
                <IconButton className={styles.mathButtonIcon} onClick={this.handleMathOperatorClick('+')}>
                  <PlusIcon />
                </IconButton>
                <IconButton className={styles.mathButtonIcon} onClick={this.handleMathOperatorClick('-')}>
                  <MinusIcon />
                </IconButton>
                <IconButton className={styles.mathButtonIcon} onClick={this.handleMathOperatorClick('*')}>
                  <MultiplyIcon />
                </IconButton>
                <IconButton className={styles.mathButtonIcon} onClick={this.handleMathOperatorClick('/')}>
                  <DivideIcon />
                </IconButton>
                <IconButton className={styles.mathButtonIcon} onClick={this.handleMathOperatorClick('(')}>
                  <LeftParenthesisIcon />
                </IconButton>
                <IconButton className={styles.mathButtonIcon} onClick={this.handleMathOperatorClick(')')}>
                  <RightParenthesisIcon />
                </IconButton>

                <div style={{ flex: 1 }} />

                <MenuPopupButton
                  icon={<FunctionIcon />}
                  className={styles.formulaButton}
                  menuItems={[
                    { icon: <LengthIcon />, text: i18n.t('Length from drawing'), handler: this.addFunctionToFormula(`${LENGTH_FUNCTION}()`) },
                    { icon: <SquareIcon />, text: i18n.t('Surface from drawing'), handler: this.addFunctionToFormula(`${SURFACE_FUNCTION}()`) },
                    { icon: <MarkerIcon />, text: i18n.t('Count from drawing'), handler: this.addFunctionToFormula(`${COUNT_FUNCTION}()`) },
                  ]}
                />
              </div>

              <UnitComboBox
                className={styles.unitComboBox}
                value={measurement.displayUnit}
                onChange={this.handleChangeUnit}
                disabled={measurement.isReadonly}
                // could allow to change unit in same unittype eventually
                //unitTypeOptions={measurement.isReadonly ? [measurement.unitType] : undefined}
                withLabel
              />

              <div className={styles.adjustmentsGroup}>
                <div className={classnames(
                  styles.adjustmentsGroupTitle,
                  { [styles.isReadonlyMeasurement]: measurement.isReadonly }
                )}>{i18n.t('Adjustments')}</div>
                <AdjustmentFormItem
                  shortLabel={i18n.t('Group Adjustment')}
                  longLabel={i18n.t('Group Adjustment (applies to all tasks in "{{-groupName}}")', {
                    // very ugly hack for tasks list
                    groupName: this.context === Globals.listDraftStores
                      ? Globals.defaultStores.treeNodesStore.selectedTreeNode.name
                      : this.treeNode.name
                  })}
                  measurement={measurement}
                  model={measurementValue}
                  treeNode={this.treeNode}
                />
                {this.task && (
                  <AdjustmentFormItem
                    shortLabel={i18n.t('Task Adjustment')}
                    longLabel={i18n.t('Task Adjustment (applies to current task)')}
                    measurement={measurement}
                    model={this.task}
                    treeNode={this.treeNode}
                  />
                )}

              </div>

              <TextField
                label={i18n.t('Result')}
                className={styles.textField + ' ' + styles.textFieldResult}
                value={MeasurementFormatter.format(measurementValue, this.task?.adjustment)}
                fullWidth
                disabled
              //onChange={this.handleChange(providedQuantity, 'quantity')}
              />

            </div>

          </div>


        </div>
      </div >
    )
  }
}