
import { Button, ButtonProps } from '@material-ui/core';
import classnames from 'classnames';
import ObserverComponent from 'components/common/ObserverComponent';
import * as React from 'react';
import i18n from 'utils/i18n';

const styles = require('./ConfirmDeleteButton.module.scss');

interface ConfirmDeleteButtonState {
  isAskingToDelete: boolean
}

interface ConfirmDeleteButtonProps {
  text: string,
  style?: any,
}

export default class ConfirmDeleteButton extends ObserverComponent<ButtonProps & ConfirmDeleteButtonProps, ConfirmDeleteButtonState> {
  state = {
    isAskingToDelete: false
  }

  _render() {
    const { isAskingToDelete } = this.state;
    const { text, children, className } = this.props;

    return isAskingToDelete
      ? (
        <Button
          {...this.props}
          className={classnames(styles.root, className, styles.isAskingToDelete)}
          onBlur={() => this.setState({ isAskingToDelete: false })}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              this.setState({ isAskingToDelete: false })
            }
          }}
          onClick={e => {
            this.setState({ isAskingToDelete: false });
            this.props.onClick(e);
          }}
        >
          {children}&nbsp;
          {i18n.t('Really delete?')}
        </Button >
      ) : (
        <Button
          {...this.props}
          className={classnames(styles.root, className)}
          onClick={() => this.setState({ isAskingToDelete: true })}>
          {children}&nbsp;
          {text}
        </Button>
      )
  }
}