import { DbLocationType } from 'constants/DbLocationType';
import { ReportSubtypes } from 'constants/ReportOptionsConstants';
import { first } from 'lodash';
import { computed, observable } from 'mobx';
import Report from 'models/Report';
import FirebaseStore from 'stores/FirebaseStore';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import i18n from 'utils/i18n';
const { Estimate, Invoice } = ReportSubtypes;

const DEFAULT_KEY = 'DEFAULT';

export default class ReportsStore extends FirebaseStore<Report> {
  storeKey = 'reports';

  dbLocationsTypes = new Set([
    DbLocationType.User, 
    DbLocationType.Project
  ]);

  shouldKeepUserItems = true;

  @observable isGeneratingExcel = false;

  @observable selectedItem: Report;

  // alias because currently only one report, even if someday we might allow more
  @computed get report() {
    return this.selectedItem;
  }

  set report(report) {
    this.selectedItem = report;
  }

  // not per project
  @computed get userReport(): Report {
    return first(Array.from(this.userCollectionItemsMap.values())) || this.selectedItem;
  }
  public onLoadCompleted() {
    let report = this.getItem(DEFAULT_KEY);
    // double check that really report isnt in db, can't afford to be a connection error
    // or we will overwrite default report

    if (!report || !report.cascadeOrders.has(this.collections.length - 1)) {
      this.projectCollection.doc(DEFAULT_KEY).get({ source: 'server' })
        .then(snapshot => {
          if (!snapshot.exists) {
            this.onLoadCompletedAndVerified();
          } else {
            debugger; // bad!
            window.location.reload();
          }
        }).catch(error => {
          if (navigator.onLine) {
            debugger;
            this.stores.commonStore.error = error.message;
            throw (error);
          }
        })
    } else {
      this.onLoadCompletedAndVerified();
    }
  }

  public onLoadCompletedAndVerified() {
    let report = this.getItem(DEFAULT_KEY);

    // ensure project && user cascade object
    if (!report || !report.cascadeOrders.has(this.collections.length - 1)) {
      report = report || new Report(this.stores, DEFAULT_KEY, Estimate, i18n.t('Document 1'));
      report.clientName = report.clientAddress = report.number = report.description = '';

      const batch = firestoreBatch();
      batch.isUndoable = false;
      this.addEditItem(report, true, undefined, batch);
      batch.commit();
    }

    this.selectedItem = first(this.items);

    // hack, find a way to have proper user settings for reports
    this.selectedItem.shouldShowProvidingItemsIcons = this.userReport.shouldShowProvidingItemsIcons;

    super.onLoadCompleted();
  }
}
