import { Unit } from 'constants/Unit';
import { computed, observable } from 'mobx';
import { list, primitive, serializable } from 'serializr';
import { localized } from 'utils/localized';
import ModelBase from './ModelBase';



export default class Merchant extends ModelBase {
  type = 'Merchant';

  @serializable @observable domain = '';
  @serializable @observable isMetric = false;

  @serializable @observable isRental = false;
  @serializable @observable isNew = false;

  @serializable @observable countryCode = 'CA';

  @localized landingProxyUrl = '';

  @computed get isImperial() {
    return !this.isMetric;
  }

  @serializable @observable priceXpath = '';
  @serializable @observable priceDivider = 1;
  @serializable @observable unitStringXpath = '';
  @serializable @observable idXpath = '';
  @serializable @observable idRegex = '';
  @serializable @observable imageXpath = '';
  @serializable @observable imageSearch = '';
  @serializable @observable imageReplace = '';
  @serializable @observable thumbXpath = '';
  @serializable @observable thumbSearch = '';
  @serializable @observable thumbReplace = '';
  @serializable @observable priceSearch = '';
  @serializable @observable priceReplace = '';
  @serializable @observable languageLinkXpath = '';
  @serializable @observable surfaceCoverageRegex = '';
  @serializable @observable surfaceCoverageUnit = Unit.SquareFoot;
  @serializable @observable lengthCoverageRegex = '';
  @serializable @observable lengthCoverageUnit = Unit.Foot;
  @serializable @observable nameSuffixXpath = '';

  @serializable(list(primitive())) @observable nameXpaths = [];
  @serializable(list(primitive())) @observable priceXpaths = [];
  @serializable(list(primitive())) @observable descriptionXpaths = [];
  @serializable(list(primitive())) @observable specsXpaths = [];

  @serializable @observable shouldKeepUpperCaseName = false;

  // guesser
  @localized lumberLengthRegex = '';
  @localized featureLengthRegex = '';
  @localized depthRegex = '';
  @localized widthRegex = '';
  @localized heightRegex = '';
}