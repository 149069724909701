import FirebaseUIAuth from 'components/common/FirebaseUIAuth';
import environment from 'environment';
import firebase from "firebase/compat/app";
import 'firebaseui/dist/firebaseui.css';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import firebaseApp from 'utils/FirebaseInitializedApp';
import i18n from 'utils/i18n';
import { getFirebaseUiLanguageCode } from 'utils/LanguageUtil';
import { isProduction } from 'utils/Utils';
const styles = require('./LoginView.module.scss');

require('./LoginView.css');

export default class LoginView extends React.Component {
  // Configure FirebaseUI.
  private uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: window.location.href.includes('app.evalumo.com') ? 'redirect' : 'popup',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        clientId: isProduction()
          ? '663211318889-io5m2bc4fgekvgmfdudhvn61gble5o6c.apps.googleusercontent.com'
          : '261994850344-3r6hso6k2l8rhf2i4vnvkbuho8k92e5a.apps.googleusercontent.com',
      },
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    //credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
    callbacks: {
      signInSuccessWithAuthResult(authResult: any, redirectUrl?: string) {
        return false;
      }
    }
  };

  render() {
    const { APP_NAME } = environment;
    return (
      <div id="LoginView" className={styles.root}>
        {isProduction() && (
          <>
            <Helmet>
              <script src="https://www.googletagmanager.com/gtag/js?id=G-6HH9LQ9HVX"></script>
            </Helmet>

            <script>
              {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'G-6HH9LQ9HVX');
            `}
            </script>
          </>
        )}

        <header className={styles.logo}>
          <img src="/images/logo.svg" width="90" height="90" alt="" />
          {APP_NAME}
        </header>
        
        <h3>{i18n.t('Login or create an account using one of these methods')} :</h3>

        <FirebaseUIAuth
          lang={getFirebaseUiLanguageCode(i18n.language)}
          config={this.uiConfig}
          auth={firebaseApp.auth()}
          firebase={firebase}
        />
        
        <div className={styles.bottomText}>
          <p>{i18n.t('Need help?')}</p>
          <p>{i18n.t('Our team is ready to answer your questions!')}</p>
          <p>&nbsp;</p>
          <p><a className={styles.phone} href="tel:1-866-766-3313">1-866-766-3313</a></p>
          <p><a className={styles.email} href="mailto:support@evalumo.com">support@evalumo.com</a></p>
        </div>

        <div style={{flex: 1}}/>
        <div className={styles.terms}>
          {i18n.t('By logging in to Evalumo, you agree to our')} <a href="https://evalumo.com/terms" target="_blank" rel="noopener noreferrer">{i18n.t('terms of service')}</a>.
        </div>
      </div>
    );
  }
}

