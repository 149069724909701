import { BuiltinMeasurementSubcategories } from 'constants/BuiltinCategories';
import { Unit } from 'constants/Unit';
import { compact, isEmpty, uniqBy } from 'lodash';
import Category from 'models/Category';
import Stores from 'stores/Stores';
import { DocumentReference, firestore } from './FirebaseInitializedApp';
import firestoreBatch from './FirestoreBatchUtil';
import i18n from './i18n';
import { getMeasurementsWithDependencies } from './MeasurementUtil';
import { waitOnStoresReady } from './StoreUtil';

// DONT FORGET  TO UPDATE PROJECT LATEST VERSION CONSTANT IN Constants.ts

export const runProjectCompatibilityCheck = async (stores: Stores) => {
  const { userInfoStore, projectsStore, providingItemsStore, priceUpdateStore, categoriesStore, tasksListsStore, measurementsStore, treeNodesStore } = stores;
  const { selectedProject } = projectsStore;
  const { version } = selectedProject;

  const batch = firestoreBatch();
  batch.isUndoable = false;

  if (!userInfoStore.user) {
    debugger;
    window.location.reload();
  }

  const userId = userInfoStore.user.id;

  let wasOutdated = false;

  if (version < 2) {
    // manually update firestore, to avoid overwriting user item data with outdated project item data

    const surfaceQuantity = { quantityFormula: 1, confidencePercentage: 80, unit: "SquareFoot", unitType: "Surface" };

    async function convertToSurfacePrice(ref: DocumentReference, itemData: any, surfacePrice: number): Promise<any> {
      if (!itemData || !itemData.providedQuantities) {
        return null;
      }

      let { providedQuantities, labourRate, priceFormula } = Object.assign({}, itemData);
      // check item was not too heavily modified
      if (!providedQuantities.Surface && providedQuantities.Time?.quantityFormula == '1') {
        labourRate = parseFloat(priceFormula);
        priceFormula = '' + surfacePrice;
        providedQuantities.Surface = surfaceQuantity
        providedQuantities.Time.quantityFormula = '' + surfacePrice / labourRate;

        await ref.set({ providedQuantities, priceFormula, labourRate }, { merge: true });
      }
    }
    const projectStructureLabour = await firestore().doc(`/users/${userId}/projects/${selectedProject.id}/providingItems/58015719-73b7-4380-be65-eef7d59ac36f`).get();
    await convertToSurfacePrice(projectStructureLabour.ref, projectStructureLabour.data(), 1.80);

    const userStructureLabour = await firestore().doc(`/users/${userId}/providingItems/58015719-73b7-4380-be65-eef7d59ac36f`).get();
    await convertToSurfacePrice(userStructureLabour.ref, userStructureLabour.data(), 1.80);

    const projectInsulationLabour = await firestore().doc(`/users/${userId}/projects/${selectedProject.id}/providingItems/USER:c42cdc61-a476-4717-b9a3-2e99df517800`).get();
    await convertToSurfacePrice(projectInsulationLabour.ref, projectInsulationLabour.data(), 0.60);

    const userInsulationLabour = await firestore().doc(`/users/${userId}/providingItems/USER:c42cdc61-a476-4717-b9a3-2e99df517800`).get();
    await convertToSurfacePrice(userInsulationLabour.ref, userInsulationLabour.data(), 0.60);

    wasOutdated = true;
    selectedProject.version = 2;
  }

  if (version < 3) {
    // homedepot bad link tag creates bad price update
    const badLinkItems = providingItemsStore.items
      .filter(i => i.merchant?.id == 'homedepot.ca' && !i.url.includes(i.id.split(':')?.[1] || ''));

    badLinkItems.forEach(i => {
      i._url.en = `https://www.homedepot.ca/product/get/${i.merchantSku}`;
      i._url.fr = `https://www.homedepot.ca/produit/get/${i.merchantSku}`;
      i.priceUpdatedMiliseconds = 1;

      priceUpdateStore.priceUpdate._priceMap.delete(i.id);
      priceUpdateStore.priceUpdate._priceMapUpdatedMiliseconds.delete(i.id);
    });

    if (!isEmpty(badLinkItems)) {
      priceUpdateStore.batchAddEditItem(priceUpdateStore.priceUpdate, batch);
      providingItemsStore.batchAddEditItems(badLinkItems, batch);
    }

    selectedProject.version = 3;
    wasOutdated = true;
  }

  // -- general cleanup when loading any project
  if (categoriesStore.generalCategory.name !== i18n.t('General')) {
    categoriesStore.generalCategory.name = i18n.t('General');
    categoriesStore.addEditItem(categoriesStore.generalCategory, true, ['_name'], batch);
    wasOutdated = true;
  }

  const measurementsNotSavedInProjectCollection = getMeasurementsWithDependencies(compact(
    uniqBy(
      treeNodesStore.rootNode.tasks
        .map(task => task.measurement)
        .filter(measurement => measurement && !measurement.cascadeOrders.has(measurementsStore.projectCollectionIndex)),
      'id')
  ));
  if (measurementsNotSavedInProjectCollection.length > 0) {
    measurementsStore.ensureSavedInProjectCollection(measurementsNotSavedInProjectCollection, batch);
    wasOutdated = true;
  }

  /*
  // does more harm than good... in most cases we dont want to reconfigure categories each time and it rarely affects total
  categoriesStore.ensureSavedInProjectCollection(compact(uniqBy([
    ...treeNodesStore.rootNode.nonOneTimeUseMeasurementValuesArray.map(mv => [mv.measurement?.category, mv.measurement?.subcategory]).flat(),
    ...treeNodesStore.rootNode.tasks.map(task => task.category)
  ], 'id')), batch);
  */

  await waitOnStoresReady([tasksListsStore]);

  // put orphan subcategories to their parent categories when we can
  const categoriesToSave = new Set<Category>();
  [...measurementsStore.items, ...providingItemsStore.items, ...projectsStore.items, ...tasksListsStore.items]
    .filter(i => (
      i.subcategory.id !== 'General' &&
      i.category &&
      i.category?.id !== 'General' &&
      !i.subcategory?.parentCategory
    )).forEach(i => {
      const userCollectionChildrenIds = categoriesStore.userCollectionItemsMap.get(i.category.id).childrenIds;

      i.category.childrenIds = [...new Set([
        ...i.category.childrenIds,
        ...userCollectionChildrenIds,
        ...categoriesStore.items.filter(category => category.parentCategoryId === i.category.id).map(category => category.id),
        i.subcategoryId
      ])];

      categoriesToSave.add(i.category)
    });

  // check if still some orphan categs that we can salvage
  categoriesStore.items
    .filter(category => (
      ![...Object.values(BuiltinMeasurementSubcategories), 'General'].includes(category.id) &&
      isEmpty(category.childrenIds) &&
      category.parentCategory &&
      category.parentCategory.id !== 'General' &&
      !category.parentCategory.childrenIds.includes(category.id)
    )).forEach(subcateg => {
      const userCollectionChildrenIds = categoriesStore.userCollectionItemsMap.get(subcateg.parentCategoryId).childrenIds;

      subcateg.parentCategory.childrenIds = [...new Set([
        ...subcateg.parentCategory.childrenIds,
        ...userCollectionChildrenIds,
        subcateg.id
      ])];

      categoriesToSave.add(subcateg.parentCategory);
    })

  if (!isEmpty(categoriesToSave)) {
    categoriesStore.saveToDb([...categoriesToSave], undefined, ['childrenIds'], batch);
    wasOutdated = true;
  }
  // --


  if (wasOutdated || !isEmpty(batch._batch.mutations)) {
    projectsStore.addEditItem(selectedProject, true, ['version'], batch);
    batch.commit();
  }
}

export const runUserCompatibilityCheck = async (stores: Stores) => {
  const { userInfoStore, priceUpdateStore, providingItemsStore } = stores;
  const { user } = userInfoStore;

  if (!user) {
    throw new Error('User not loaded exception');
  }

  const { version } = user;

  let wasOutdated = false;

  const batch = firestoreBatch();
  batch.isUndoable = false;

  if (version < 1) {
    const badPricesItems = providingItemsStore
      .nonMasterItemsWithMerchant
      .filter(i => (i.isLabour && i.rentalTimeUnit !== Unit.Day));

    badPricesItems.forEach(i => {
      priceUpdateStore.priceUpdate._priceMap.delete(i.id);
      priceUpdateStore.priceUpdate._priceMapUpdatedMiliseconds.delete(i.id);
    })

    if (!isEmpty(badPricesItems)) {
      priceUpdateStore.batchAddEditItem(priceUpdateStore.priceUpdate, batch);
    }

    user.version = 1;
    wasOutdated = true;
  }

  if (version < 2) {
    const badPricesItems = Array.from(priceUpdateStore.priceUpdate._priceMap.keys().filter(k => k.includes('bmr')));

    badPricesItems.forEach(key => {
      priceUpdateStore.priceUpdate._priceMap.delete(key);
      priceUpdateStore.priceUpdate._priceMapUpdatedMiliseconds.delete(key);
    })

    if (!isEmpty(badPricesItems)) {
      priceUpdateStore.batchAddEditItem(priceUpdateStore.priceUpdate, batch);
    }

    user.version = 2;
    wasOutdated = true;
  }

  if (wasOutdated) {
    userInfoStore.addEditItem(user, true, ['version'], batch);
    batch.commit();
  }
}