import { Button, Checkbox, LinearProgress } from '@material-ui/core';

import PauseIcon from '@material-ui/icons/Pause';
import ResumeIcon from '@material-ui/icons/PlayArrow';
import ReloadIcon from '@material-ui/icons/Refresh';
import RadarIcon from '@material-ui/icons/RssFeed';
import WarningIcon from '@material-ui/icons/Warning';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { HelmetIcon, WoodStackIcon } from 'components/common/Icons';
import SearchToolbar from 'components/common/SearchToolbar/SearchToolbar';
import { CategoryType } from 'constants/CategoryType';
import { ProvidingItemSubtype } from 'constants/ProvidingItemConstants';
import { Unit } from 'constants/Unit';
import { UnitType } from 'constants/UnitType';
import { saveAs } from 'file-saver';
import { compact, isEmpty, last, maxBy, remove, uniq, zipObject } from 'lodash';
import Category from 'models/Category';
import Dialog from 'models/Dialog';
import { ProvidedQuantity } from 'models/ProvidedQuantity';
import ProvidingItem from 'models/ProvidingItem';
import moment from 'moment';
import * as React from 'react';
import { roundPrice } from 'utils/CurrencyUtil';
import DbItemFactory from 'utils/DbItemFactory';
import { modelToPlainObjectNonNested } from 'utils/DeserializeUtils';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import { TrackedInteractions, trackInteraction } from 'utils/TrackingUtil';
import { formatUnit, getUnitTypeForUnit } from 'utils/UnitFormatter';
import { removeNonAlphanumericCharacters, round } from 'utils/Utils';
import { stox } from 'utils/XLSXUtils';
import i18n from 'utils/i18n';
import * as uuidv4 from 'uuid/v4';
import XLSX from 'xlsx';
import DraggableProvidingItem from '../DraggableProvidingItem/DraggableProvidingItem';
import GroupedList from '../GroupedList/GroupedList';
import ObserverComponent from '../ObserverComponent';
import PriceUpdateDialog from '../PriceUpdateDialog/PriceUpdateDialog';
import ProvidingItemEditDialog from '../ProvidingItemEditDialog/ProvidingItemEditDialog';

const styles = require('./MyItems.module.scss');

interface IMyItemsState {
  searchQuery: string,
}

class MyItemsList extends ObserverComponent<{}> {
  _render() {
    const { providingItemsStore, userInfoStore } = this.context;
    const { nonImpersonatedUser } = userInfoStore;

    return (
      <GroupedList
        ungroupedItems={providingItemsStore.searchedItems}
        // these will be all
        itemsByCategSubcategFlattened={providingItemsStore.itemsByCategSubcategFlattened}
        className={styles.list}
        store={providingItemsStore}
        onDuplicateItem={item => {
          const copy = item.clone(item.stores, uuidv4());
          copy.name = item.name + ' ' + i18n.t('(Copy)');

          if (!nonImpersonatedUser.shouldAutoSyncProvidingItems) {
            providingItemsStore.saveToDb(
              [copy],
              providingItemsStore.collections.filter(collection => collection === providingItemsStore.userCollection)
            );
          } else {
            providingItemsStore.addEditItem(copy);
          }
        }}
        onChangeItemCategory={item => providingItemsStore.openChangeCategoryDialog([item])}
        shouldAllowCheckboxes
        ItemRenderer={DraggableProvidingItem}
        canDeleteMasterItems
        shouldAllowReorderItems
      />
    );
  }
}

// broken in 2 pieces to avoid refreshing the list when top part changes
export class MyItemsTopPart extends ObserverComponent<{}, IMyItemsState> {
  state = {
    searchQuery: '',
  }

  onTabClick = (event, subtypeFilter: ProvidingItemSubtype) => {
    if (!subtypeFilter) {
      return;
    }
    const { providingItemsStore } = this.context;
    providingItemsStore.subtypeFilter = subtypeFilter;
    providingItemsStore.categoryFilter = null;
    providingItemsStore.subcategoryFilter = null;
  }

  addBlankItem = () => {
    const { providingItemsStore } = this.context;
    const { subtypeFilter } = providingItemsStore;

    const isMaterial = subtypeFilter === ProvidingItemSubtype.Material;
    const newItem = new ProvidingItem(this.context);
    const { categoryFilter, subcategoryFilter } = providingItemsStore;

    newItem.id = 'USER:' + uuidv4();
    newItem.subtype = isMaterial
      ? ProvidingItemSubtype.Material
      : ProvidingItemSubtype.Labour;

    if (!isMaterial) {
      newItem.providedQuantities.clear();
    }

    if (categoryFilter) {
      newItem.category = categoryFilter;
    }
    if (subcategoryFilter) {
      newItem.subcategory = subcategoryFilter;
    }

    if (!isMaterial) {
      newItem.wastePercent = 0;
    }

    this.openDialogForItem(newItem);
  }

  //should fix to match output from excel export!
  processExcelWorkbookOLD_Labour = (wb) => {
    const { providingItemsStore, categoriesStore } = this.context;
    /* convert to x-spreadsheet form */
    var data = stox(wb);

    const batch = firestoreBatch();

    const items = compact(Object.values(data[0].rows).map((row, index) => {
      const labourRate = 76;

      const { cells } = row;
      let [id, categ, subcateg, name, timeConstant, unit, imageUrl] = Object.values(cells).map(cell => cell.text);

      timeConstant = parseFloat(timeConstant);

      if (providingItemsStore.getItem(id)) {
        return; // skip existing item for now
      }

      const newItem = new ProvidingItem(this.context);
      newItem.subtype = ProvidingItemSubtype.Labour;
      newItem.labourRate = labourRate;
      newItem.id = id;
      newItem.name = name;
      newItem.imageUrl = newItem.thumbUrl = imageUrl;

      const categoryId = 'Demolition'; //removeNonAlphanumericCharacters(categ);
      const categoryName = categ;
      const subcategoryId = removeNonAlphanumericCharacters(subcateg);
      const subcategoryName = subcateg;

      let category = categoriesStore.getItem(categoryId);
      if (!category) {
        category = new Category(this.context, categoryName, [CategoryType.Material], categoryId, index);
        categoriesStore.batchAddEditItem(category, batch);
      }

      let subcategory = categoriesStore.getItem(subcategoryId);
      if (!subcategory) {
        subcategory = new Category(this.context, subcategoryName, [CategoryType.Material], subcategoryId, index);
        categoriesStore.batchAddEditItem(subcategory, batch);

        category.childrenIds.push(subcategory.id);
        categoriesStore.batchAddEditItem(category, batch);
      }

      newItem.categoryId = categoryId;
      newItem.subcategoryId = subcategoryId;

      const timeProvidedQuantity = new ProvidedQuantity(UnitType.Time, parseFloat(timeConstant), Unit.Hour);
      newItem.providedQuantities.set(UnitType.Time, timeProvidedQuantity);

      if (unit !== Unit.Unit) {
        newItem.providedQuantities.delete(UnitType.Unit);

        const timeConstantUnitType = getUnitTypeForUnit(unit);
        newItem.providedQuantities.set(timeConstantUnitType, new ProvidedQuantity(timeConstantUnitType, 1, unit));
      }

      newItem.price = roundPrice(newItem.labourRate * timeConstant);

      return newItem;
    }));

    providingItemsStore.addEditItems(items, true, undefined, batch);

    batch.commit();

    /* update x-spreadsheet */
    // xspr.loadData(data);
  }

  // not just bmr also gagnon, homehardware 

  processExcelWorkbookBMR = async (wb) => {
    let items = [];
    const { providingItemsStore, categoriesStore } = this.context;
    /* convert to x-spreadsheet form */
    var data = stox(wb, true);

    let columnsNames = [];

    await compact(Object.values(data[0].rows))
      .reduce(async (previousTask, row, index) => {
        await previousTask;

        let { cells } = row;

        if (isEmpty(cells)) {
          return '';
        }
        // cells is object indexed by column index and missing empty cells
        // so it looks like {0: {text: 'allo'}, 2: {text: 'bye'}}. convert to
        // proper array [{text: allo}, undefined, {text: 'bye'}
        const cellsArray = new Array(parseInt(last(Object.keys(cells))) + 1);

        Object.keys(cells).forEach(key => {
          cellsArray[parseInt(key)] = cells[key].text;
        });

        console.log('importing', index);

        if (index == 0) {
          columnsNames = cellsArray;
          return '';
        }

        let plainObject = zipObject(columnsNames, cellsArray.map(cell => cell[0] == "{" ? JSON.parse(cell) : cell));

        if (!plainObject.price) {
          return '';
        }

        const categoryId = removeNonAlphanumericCharacters(plainObject.categoryId);
        const categoryName = plainObject.categoryId;
        const subcategoryId = removeNonAlphanumericCharacters(plainObject.subcategoryId);
        const subcategoryName = plainObject.subcategoryId;

        let category = categoriesStore.getItem(categoryId);
        if (!category) {
          category = new Category(this.context, categoryName, [CategoryType.Material], categoryId, index - 1000);
          categoriesStore.addEditItem(category, false);
        }

        let subcategory = categoriesStore.getItem(subcategoryId);
        if (!subcategory) {
          subcategory = new Category(this.context, subcategoryName, [CategoryType.Material], subcategoryId, index - 1000);
          categoriesStore.addEditItem(subcategory, false);

          category.childrenIds.push(subcategory.id);
          categoriesStore.addEditItem(category, false);
        }

        // comment out if not homehardware
        /*
        const hwimagedata = await fetchUrl(`https://homehardware.sirv.com.merchants.evalumo.com/products/${plainObject.id.split(':')[1].substring(0,4)}/${plainObject.id.split(':')[1]}.view?nometa&info=view-1_1322584764`);

        if (!hwimagedata.includes('error_pages/NotFound.svg')) {
          if (JSON.parse(hwimagedata).assets?.[0]?.name) {
            plainObject._imageUrls = {en: [`https://homehardware.sirv.com/products/${plainObject.id.split(':')[1].substring(0,4)}/${plainObject.id.split(':')[1]}/${JSON.parse(hwimagedata).assets[0].name}`]};
          }
        }
        await sleep(1000); */
        // ----

        Object.keys(plainObject).forEach(key => {
          let value = plainObject[key]?.trim?.() || plainObject[key];
          if (key === 'categoryId') {
            value = categoryId;
          }
          if (key === 'subcategoryId') {
            value = subcategoryId;
          }

          /*if (key == 'price') {
            item._priceFormula = {'en': plainObject.price};
            return;
          }*/

          if (key == 'name') {
            plainObject._name = { 'en': value };
            return '';
          }

          if (key == 'url') {
            plainObject._url = { 'en': value };
            return '';
          }

          plainObject[key] = value;
        });

        plainObject.index = index;
        plainObject.priceUpdatedMiliseconds = Date.now();
        plainObject.type = 'ProvidingItem';

        items.push(DbItemFactory.create(plainObject, this.context, () => { ; }) as ProvidingItem);
        return '';
      }, Promise.resolve(''));

     
    providingItemsStore.saveToDb(items, [providingItemsStore.userCollection]);
    // every category and subcategory related to items
    categoriesStore.addEditItems(uniq(items.map(i => [i.categoryId, i.subcategoryId]).flat()).map(key => categoriesStore.getItem(key)));
  }

  processExcelWorkbook = async (wb) => {
    const { providingItemsStore } = this.context;
    /* convert to x-spreadsheet form */
    var data = stox(wb, true);

    let columnsNames = [];

    const items = compact(Object.values(data[0].rows).map((row, index) => {
      let { cells } = row;
      // cells is object indexed by column index and missing empty cells
      // so it looks like {0: {text: 'allo'}, 2: {text: 'bye'}}. convert to
      // proper array [{text: allo}, undefined, {text: 'bye'}
      const cellsArray = new Array(parseInt(last(Object.keys(cells))) + 1);

      Object.keys(cells).forEach(key => {
        cellsArray[parseInt(key)] = cells[key].text;
      });

      if (index == 0) {
        columnsNames = cellsArray;
        return;
      }

      const plainObject = zipObject(columnsNames, cellsArray.map(cell => cell[0] == "{" ? JSON.parse(cell) : cell));

      // DANGEROUS to overwrite a lot of data at once!
      // Also need to update cascade order to be latest cascade
      //providingItemsStore.onItemChangeFromDb(plainObject.id, plainObject, "added", providingItemsStore.collections.length - 1);

      return providingItemsStore.getItem(plainObject.id);
    }));

    //providingItemsStore.addEditItems(items);

    /* update x-spreadsheet */
    // xspr.loadData(data);
  }

  batchAddItems = (e) => {
    var f = e.target.files[0];
    var reader = new FileReader();
    const _this = this;
    reader.onload = function (e) {
      if (typeof console !== 'undefined') console.log("onload", new Date());
      var data = e.target.result;
      data = new Uint8Array(data);
      _this.processExcelWorkbookBMR(XLSX.read(data, { type: 'array' }));
      //_this.processExcelWorkbookOLD_Labour(XLSX.read(data, { type: 'array' }));
    };
    reader.readAsArrayBuffer(f);
  }

  batchExport = () => {
    const { providingItemsStore } = this.context;

    const shouldDebug = false;

    let plainItems = [];

    if (shouldDebug) {
      plainItems = providingItemsStore.items
        .map(i => modelToPlainObjectNonNested(i));
    } else {
      plainItems = providingItemsStore.itemsByCategSubcategFlattened
        .map(({ item }) => item)
        .filter(i => i)
        .map(i => {
          const providedQuantities = Array.from(i.providedQuantities.values());

          if (i.isLabour && providedQuantities.length > 1) {
            remove(providedQuantities, pq => pq.unitType === UnitType.Time);
          }

          const mainProvidedQuantity = maxBy(providedQuantities, pv => pv.confidencePercentage);

          return ({
            id: i.id,
            sousType: i.subtype,
            categorie: i.categoryName,
            sousCategorie: i.subcategoryName,
            nom: i.name,
            prix: i.price,
            quantiteFournie: (Infinity == mainProvidedQuantity?.quantity) ? 0 : (mainProvidedQuantity?.quantity || 0),
            unite: formatUnit(mainProvidedQuantity?.unit || ''),
            tauxHoraire: i.labourRate,
            fournisseur: i.merchant?.domain || '',
            sku: i.merchantSku,
            url: i.url,
            pourcentageAjustementPrix: i._priceAdjustmentPercent,
            dateModificationPrix: moment(Math.max(i.priceUpdatedMiliseconds, i.updatedMiliseconds, new Date(2020, 0, 0).getTime())).format('LL'),
            dateCreation: moment(i.createdMiliseconds).format('LL'),
            createur: i.owner,
            thumbUrl: i.thumbUrl,
            imageUrl: i.imageUrl,
          }
          );
        });
    }

    /* make the worksheet */
    var ws = XLSX.utils.json_to_sheet(plainItems, {});

    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Items");

    /* write workbook (use type 'binary') */
    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    /* generate a download */
    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }

    saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), "sheetjs.xlsx");
  }

  openDialogForItem(item: ProvidingItem) {
    const { dialogsStore } = this.context;
    const newDialog = new Dialog(this.context);
    newDialog.dialogComponent = ({ open }) => (
      <ProvidingItemEditDialog open={open} dialogId={newDialog.id} providingItem={item} />
    )
    dialogsStore.showDialog(newDialog);
  }


  openPriceUpdateDialog = () => {
    const { dialogsStore } = this.context;
    const newDialog = new Dialog(this.context);
    newDialog.dialogComponent = ({ open }) => (
      <PriceUpdateDialog
        open={open}
        dialogId={newDialog.id}
      />
    );
    dialogsStore.showDialog(newDialog);

    trackInteraction(TrackedInteractions.ViewPriceUpdate);
  }

  _render() {
    const { providingItemsStore, priceUpdateStore, settingsStore } = this.context;
    const { subtypeFilter } = providingItemsStore;
    const { userSettings } = settingsStore;

    const priceUpdateMessage = compact([
      priceUpdateStore.numberOfOutdatedPrices && i18n.t('{{count}} items have new prices', { count: priceUpdateStore.numberOfOutdatedPrices }),
      priceUpdateStore.unsyncableItems.length && i18n.t('{{count}} items could not be updated automatically', { count: priceUpdateStore.unsyncableItems.length })
    ]).join(', ');

    if (!priceUpdateStore) {
      return null;
    }

    return (
      <>
        {(
          isEmpty(priceUpdateStore.itemsToUpdate) || priceUpdateStore.isPaused
        ) && (
            !!priceUpdateStore.numberOfOutdatedPrices || !isEmpty(priceUpdateStore.unsyncableItems)
          ) && (
            <div className={styles.priceUpdateMessage}>
              <WarningIcon />
              <div>{priceUpdateMessage}</div>

              <>
                <Button onClick={this.openPriceUpdateDialog}>
                  <ReloadIcon />
                  {i18n.t('View changes')}
                </Button>

                {userSettings?.shouldAllowClearPriceUpdate && !priceUpdateStore.isPaused && (
                  <Button onClick={priceUpdateStore.launchNewUpdate}>
                    <RadarIcon />
                    {i18n.t('Launch new update')}
                  </Button>
                )}
              </>
            </div>
          )}

        {!isEmpty(priceUpdateStore.itemsToUpdate) && (
          <div className={styles.priceUpdateMessage}>
            {(priceUpdateStore.isPaused)
              ? (<>
                <div>{i18n.t('Price update paused')}</div>
                {userSettings?.shouldAllowClearPriceUpdate && (
                  <Button onClick={priceUpdateStore.launchNewUpdate}>
                    <RadarIcon />
                    {i18n.t('Launch new update')}
                  </Button>
                )}

                <Button onClick={() => {
                  priceUpdateStore.checkPrices();
                }}>
                  <ResumeIcon />
                  {i18n.t('Resume')}
                </Button>
              </>) : (<>
                <div className={styles.priceUpdateProgressContainer} title={i18n.t('To avoid overloading merchant\'s websites, we check 1 item every 15 seconds. You can continue to work during this time or leave the app running in background.')}>
                  <LinearProgress variant="determinate" className={styles.priceUpdateProgressBar} value={priceUpdateStore.updatePercent} />
                  <div className={styles.updatePercent}>{round(priceUpdateStore.updatePercent, 1)}&nbsp;%</div>
                </div>
                <div>{i18n.t('Price update in progress')}</div>
                <Button onClick={() => priceUpdateStore.isPaused = true}>
                  <PauseIcon />
                  {i18n.t('Pause')}
                </Button>
              </>)
            }
          </div>
        )}

        <div className={styles.nav}>
          <ToggleButtonGroup
            value={subtypeFilter}
            exclusive
            onChange={this.onTabClick}
          >
            <ToggleButton value={ProvidingItemSubtype.Material}>
              <WoodStackIcon />
              <div className={styles.tabLabel}>{i18n.t('Material')}</div>
            </ToggleButton>
            <ToggleButton value={ProvidingItemSubtype.Labour}>
              <HelmetIcon />
              <div className={styles.tabLabel}>{i18n.t('Labour')} {i18n.t('& misc.')}</div>
            </ToggleButton>

          </ToggleButtonGroup>
        </div>

        <SearchToolbar
          store={providingItemsStore}
          categoryType={subtypeFilter === ProvidingItemSubtype.Material
            ? CategoryType.Material
            : CategoryType.Labour
          }
          shouldFilterBySubcategories
          onAdd={this.addBlankItem}
          onBatchImport={this.batchAddItems}
          onBatchExport={this.batchExport}
          optionsMenuItems={[{
            icon: <Checkbox checked={providingItemsStore.shouldShowHiddenItems} />,
            text: i18n.t('Show archived items'),
            handler: () => { providingItemsStore.shouldShowHiddenItems = !providingItemsStore.shouldShowHiddenItems }
          }, {
            icon: <Checkbox checked={providingItemsStore.shouldMarkMasterItems} />,
            text: i18n.t('Identify original Evalumo items'),
            handler: () => { providingItemsStore.shouldMarkMasterItems = !providingItemsStore.shouldMarkMasterItems },
          }]}
        //extraFilters={<ProvidingItemsExtraFilters />}
        />
      </>
    )
  }
}


export default class MyItems extends ObserverComponent<{}> {
  _render() {
    return (
      <div className={styles.root}>
        <MyItemsTopPart />
        <MyItemsList />
      </div>
    );
  }
}
