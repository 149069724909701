import Globals from 'Globals';
import { groupBy, isEmpty, mapValues, maxBy, sortBy } from 'lodash';
import { flow } from 'mobx';
import Category from "models/Category";
import TasksList from "models/TasksList";
import TreeNode from "models/TreeNode";
import Stores from 'stores/Stores';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import { formulaHasVariables } from 'utils/MeasurementFormatter';
import { onAddOrDeleteImage } from 'utils/ProjectUtils';
import { waitOnStoresReady } from 'utils/StoreUtil';
import { TrackedInteractions, trackInteraction } from 'utils/TrackingUtil';
import { getSafe } from 'utils/Utils';
import uuidv4 from 'uuid/v4';
;

// TODO: Evaluate if caching from projects.json could be used
export const copyTasksFromList = flow(function* (
  tasksList: TasksList,
  targetNode: TreeNode,
  targetStores: Stores = getSafe(() => targetNode.stores),
  targetCategory?: Category // unused
) {
  const { listStores, defaultStores } = Globals;
  const { userInfoStore } = targetStores;
  const { tasksListsStore } = defaultStores;
  const batch = firestoreBatch();

  listStores.commonStore.selectedProjectId = tasksList.id;

  if (tasksListsStore.isCopyingList) {
    return;
  }

  tasksListsStore.isCopyingList = true;

  // don't know the source but tasks store doesnt load correctly when we just created the tasks list
  listStores.tasksStore.attemptLoadItems();

  yield waitOnStoresReady([
    listStores.treeNodesStore,
    listStores.tasksStore,
    listStores.measurementsStore,
    listStores.providingItemsStore,
    listStores.categoriesStore,
  ]);

  const selectedItems = Globals.listDraftStores.tasksStore.selectedItemsArray;

  const tasksListNodeCopy = listStores.treeNodesStore.rootNode.cloneDeep(
    batch,
    targetStores,
    uuidv4(),
    new Map(),
    isEmpty(selectedItems) ? null : selectedItems.map(task => task.id)
    // should eventually skip non master measurements to avoid overwriting them with bad value in tasks list
  );
  tasksListNodeCopy.isRootNode = false;

  if (targetCategory) {
    tasksListNodeCopy.tasks.forEach(task => {
      task.category = targetCategory
    });
    targetStores.tasksStore.batchAddEditItems(tasksListNodeCopy.tasks, batch);
  }

  const tasksListTasksByCateg = mapValues(groupBy(tasksListNodeCopy.ownTasks, 'categoryId'), tasks => sortBy(tasks, 'index'));
  const targetNodeMaxTasksIndexByCateg = mapValues(groupBy(targetNode.ownTasks, 'categoryId'), tasks => maxBy(tasks, 'index').index);

  Object.keys(tasksListTasksByCateg).forEach(categId => {
    tasksListTasksByCateg[categId].forEach((task, taskIndex) => {
      task.index = (targetNodeMaxTasksIndexByCateg[categId] || 0) + taskIndex + 1;
    })
  })

  targetStores.tasksStore.addEditItems(tasksListNodeCopy.tasks, true, ['index'], batch);

  targetNode.ownTasksIds = sortBy(targetNode.ownTasksIds.concat(tasksListNodeCopy.ownTasksIds), 'index');

  const measurementValues = tasksListNodeCopy.ownMeasurementValuesArray
    .filter(measurementValue => (
      measurementValue.measurementId &&
      !targetNode.measurementValues.has(measurementValue.measurementId)
    ));

  measurementValues.forEach(mv => {
    if (mv.measurement?.shouldSetToZeroWhenImportingTasksList) {
      mv.formula = "0";
    } else if (formulaHasVariables(mv.formula) && mv.measurement && !mv.measurement.isOneTimeUse) {
      // always use the latest formula, not the one stored in taskslist
      mv.formula = mv.measurement.defaultFormula;
    }
  })

  targetStores.treeNodesStore.applyMeasurementValues(
    targetNode,
    measurementValues,
    true,
    batch
  );

  // copy image
  if (tasksList.imageUrl) {
    targetNode.imageUrls = [...targetNode.imageUrls, tasksList.imageUrl];
    targetNode.thumbUrls = [...targetNode.thumbUrls, tasksList.thumbUrl || tasksList.imageUrl];

    if (targetNode.imageUrl === tasksList.imageUrl) {
      targetNode.shouldSkipImageInReport = true;
    }

    onAddOrDeleteImage(listStores);
  }

  // copy notes
  if (tasksList.description2 && !targetNode.description2 && userInfoStore.user?.shouldCopyNotesFromTasksLists) {
    targetNode.description2 = tasksList.description2;
  }

  targetStores.treeNodesStore.addEditItem(targetNode, true, ['ownMeasurementValues', 'ownTasksIds', '_imageUrls', '_thumbUrls', 'shouldSkipImageInReport', '__description2'], batch);

  yield batch.commit();

  trackInteraction(TrackedInteractions.ApplyTasksLists);

  defaultStores.dragAndDropStore.dragObject = null;
  tasksListsStore.isCopyingList = false;
});
