import { Button, Checkbox, IconButton } from '@material-ui/core';
import GroupAddIcon from '@material-ui/icons/CreateNewFolder';
import DeleteIcon from '@material-ui/icons/Delete';
import CopyIcon from '@material-ui/icons/LibraryBooksOutlined';
import ListIcon from '@material-ui/icons/List';
import Globals from 'Globals';
import classnames from 'classnames';
import { BuiltinCategories, BuiltinTaskCategories } from 'constants/BuiltinCategories';
import { CategoryType } from 'constants/CategoryType';
import { RightBarTabs } from 'constants/RightBarTabs';
import { compact, isEmpty, size } from 'lodash';
import { computed } from 'mobx';
import Category from 'models/Category';
import Dialog from 'models/Dialog';
import Task from 'models/Task';
import TasksList from 'models/TasksList';
import * as React from 'react';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import { getMeasurementValueAtNode, getMeasurementsWithDependencies } from 'utils/MeasurementUtil';
import { formatCurrency } from 'utils/NumberFormatter';
import { getSafe } from 'utils/Utils';
import i18n from 'utils/i18n';
import CategoriesCombobox from '../CategoriesCombobox/CategoriesCombobox';
import ConfirmDeleteButton from '../ConfirmDeleteButton/ConfirmDeleteButton';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import { CollapseAllIcon, ExpandAllIcon } from '../Icons';
import MenuPopupButton from '../MenuPopupButton/MenuPopupButton';
import ObserverComponent from '../ObserverComponent';
;

const colors = require('../../../Colors.scss');
const styles = require('./TasksHeader.module.scss');

interface TasksHeaderState {
  isCategoryFilterEnabled: boolean,
}

export default class TasksHeader extends ObserverComponent<{}, TasksHeaderState> {
  state = {
    isCategoryFilterEnabled: false,
  }

  toggleCheckboxes = () => {
    const { tasksStore } = this.context;
    tasksStore.isSelectingWithCheckboxes = !tasksStore.isSelectingWithCheckboxes;
  }

  toggleDimensionsColumn = () => {
    const { tasksStore } = this.context;
    tasksStore.isTaskMeasurementColumnVisible = !tasksStore.isTaskMeasurementColumnVisible
  }

  // duplicate
  handleSelectedChange = (event, checked) => {
    const { tasksStore, treeNodesStore } = this.context;

    const tasks = getSafe(() => treeNodesStore.selectedTreeNode.ownTasks);
    tasks.forEach(
      task => checked
        ? tasksStore.selectedItems.add(task)
        : tasksStore.selectedItems.delete(task)
    );
  }

  deleteZeroValueTasks = () => {
    this.askToDeleteTasks(this.zeroValueTasks, i18n.t('Do you really want to delete all tasks with a quantity of 0?'));
  }

  deleteTasks = (tasks: Task[]) => () => {
    const { treeNodesStore, tasksStore } = this.context;
    const { ownTasks } = treeNodesStore.selectedTreeNode;
    const taskIdsToDelete = [];
    tasks.forEach(task => {
      taskIdsToDelete.push(task.id);
      const taskIndexInSelectedNode = ownTasks.indexOf(task);

      if (taskIndexInSelectedNode >= 0) {
        ownTasks.splice(taskIndexInSelectedNode, 1);
      }
    });

    treeNodesStore.addEditItem(treeNodesStore.selectedTreeNode);
    tasksStore.deleteItems(taskIdsToDelete);

    tasksStore.selectedItems.clear();
  }

  askToDeleteTasks = (tasks: Task[], title = i18n.t('Do you really want to delete all selected tasks?')) => {
    const { treeNodesStore, tasksStore, dialogsStore } = this.context;
    // TODO confirm dialog until we have undo feature

    const newDialog = new Dialog(this.context);
    newDialog.dialogComponent = ({ open }) => (
      <ConfirmDialog
        open={open}
        dialogId={newDialog.id}
        onConfirm={this.deleteTasks(tasks)}
        title={title}
        actionButtonColor={colors.red}
        yesLabel={i18n.t('Delete')}
      />
    );
    dialogsStore.showDialog(newDialog);
  }

  // NEED TO COMBINE WITH SAVING TASKS LISTS DETAILS DIALOG
  // because of how we want to save non master items and such
  copySelectedTasksToList = async () => {
    const { tasksStore: projectTasksStore, treeNodesStore: projectTreeNodesStore, tasksListsStore, settingsStore } = this.context;
    const { listStores } = Globals;

    tasksListsStore.searchQuery = '';

    const batch = firestoreBatch();

    const sourceNode = projectTreeNodesStore.selectedTreeNode;
    const tasks = projectTasksStore.selectedItemsArray;

    // create a temporary node that only has selected tasks and related measurements
    const nodeCopy = sourceNode.clone();
    // O(2)
    const measurements = getMeasurementsWithDependencies(tasks.map(task => task.measurement))
      // not all dependencies are guaranteed to be currently activated
      .filter(measurement => sourceNode.measurements.includes(measurement));

    // flatten measurements (ie. move leaf measurement up to this selected node
    nodeCopy.ownMeasurementValues.clear();
    measurements.forEach(measurement => {
      const rootMeasurementValue = getMeasurementValueAtNode(sourceNode, measurement);
      nodeCopy.ownMeasurementValues.set(
        measurement.id,
        rootMeasurementValue
      )
    });
    nodeCopy.ownTasks = tasks;
    nodeCopy.ownShapesIds.clear();
    nodeCopy.children = [];


    const newTasksList = new TasksList(this.context);
    newTasksList.category = tasksListsStore.categoryFilter;
    newTasksList.subcategory = tasksListsStore.subcategoryFilter;

    // copy image
    if (nodeCopy.imageUrl) {
      newTasksList.imageUrls = [...nodeCopy.imageUrls];
      newTasksList.thumbUrls = [...nodeCopy.thumbUrls];
    }

    const { commonStore, treeNodesStore } = listStores;

    // this will ensure root node gets created
    commonStore.selectedProjectId = newTasksList.id;

    const taskListRootNode = nodeCopy.cloneDeep(
      batch,
      listStores,
      undefined,
      undefined,
      undefined,
      //should actually skip master measurements, but function takes care of giving new ids and updating tasks and node references
      //so just ignoring master measurements when copying back instead
      false,
      // skip providingItems to be able to save only non master items
      true
    );

    taskListRootNode.isRootNode = true;

    newTasksList.isMultiCategs = Object.keys(listStores.tasksStore.itemsByCateg).length > 1;
    newTasksList.index = size(tasksListsStore.itemsByCateg[BuiltinCategories.General]);
    newTasksList.name = i18n.t('New list');

    tasksListsStore.batchAddEditItem(newTasksList, batch);
    treeNodesStore.batchAddEditItem(taskListRootNode, batch);

    // not usable now because using a separate providing items store currently means reloading the full list of item again (about 3-4 seconds each time)
    /*const nonMasterProvidingItems = uniqBy(compact(tasks.filter(t => !t.providingItem?.cascadeOrders?.has(0)).map(t => t.providingItem)), i => i.id);
    listStores.providingItemsStore.addEditItems(nonMasterProvidingItems, true, null, batch);
    const nonMasterCategories = uniqBy(compact(tasks.filter(t => !t.category?.cascadeOrders?.has(0)).map(t => t.category)), c => c.id);
    listStores.categoriesStore.addEditItems(nonMasterCategories, true, null, batch);*/


    projectTasksStore.isSelectingWithCheckboxes = false;
    projectTasksStore.selectedItems.clear();

    await batch.commit();

    const approxDelay = settingsStore.settings.selectedRightBarTab === RightBarTabs.LISTS ? 0 : 500;
    settingsStore.settings.selectedRightBarTab = RightBarTabs.LISTS;
    tasksListsStore.tasksListBeingEdited = newTasksList;

    // HACK.. we don't have the ref yet if the tab is about to be changed. It will take a few seconds.
    // Because the autosizer is a child of a display: none component, its height is 0 and children don't render
    // Proper way to fix would be to move autosizer higher up, but complicated if moving out of GroupedList component
    setTimeout(() => tasksListsStore.listComponentRef?.scrollToItem?.(newTasksList.index), approxDelay);
  }

  toggleFilter = () => {
    const { categoriesStore } = this.context;
    const isCategoryFilterEnabled = !this.state.isCategoryFilterEnabled;
    this.setState({
      isCategoryFilterEnabled
    });

    if (!isCategoryFilterEnabled) {
      categoriesStore.categoryBeingFilteredIn = null;
    }
  }

  addCategoryToTasksList = () => {
    const { categoriesStore, treeNodesStore } = this.context;
    const newCateg = categoriesStore.getItem(BuiltinTaskCategories.NewCategory);
    newCateg.index = -1; // make sure always first;
    const categoriesIds = treeNodesStore.selectedTreeNode.ownTaskCategoriesIds;

    if (!categoriesIds.includes(newCateg.id)) {
      categoriesIds.unshift(newCateg.id);
    }

    treeNodesStore.addEditItem(treeNodesStore.selectedTreeNode);
    categoriesStore.taskCategoryBeingEdited = newCateg;
  }

  onChangeCategoryFilter = (category: Category) => {
    const { categoriesStore } = this.context;
    categoriesStore.categoryBeingFilteredIn = category;
  }

  @computed get zeroValueTasks() {
    const { treeNodesStore } = this.context;
    const { selectedTreeNode } = treeNodesStore;

    return selectedTreeNode?.ownTasks?.filter(task => selectedTreeNode.measurementValues.get(task.measurementId)?.metricValue === 0) || [];
  }

  _render() {
    const { tasksStore, treeNodesStore, categoriesStore } = this.context;
    const { isSelectingWithCheckboxes } = tasksStore;
    const { selectedTreeNode } = treeNodesStore;
    const { selectedItems } = tasksStore;
    const { isCategoryFilterEnabled } = this.state;
    let numberOfItems = 0, price = 0;
    const tasks = getSafe(() => selectedTreeNode.ownTasks) || [];

    // duplicate
    numberOfItems = tasks.filter(task => task && !task.isSeparator).length;
    price = tasks.reduce((total, task) => (
      total + task.price
    ), 0);

    const isChecked = tasks.length > 0 && tasks.every(task => selectedItems.has(task));
    const isIndeterminate = !isChecked && tasks.some(task => selectedItems.has(task));

    const shouldShowBatchTasksTools = isChecked || isIndeterminate;

    const hasMoreThanOneTaskCategories = treeNodesStore.selectedNodeCategories.length > 1;


    const selectedTasksMenuItems = compact([
      !isEmpty(this.zeroValueTasks) && { icon: <DeleteIcon />, text: i18n.t('Delete tasks with a quantity of 0'), handler: this.deleteZeroValueTasks },
    ]);

    return (
      <div className={styles.root}>
        <div className={styles.title}>
          {selectedTreeNode && true /*isSelectingWithCheckboxes*/ && (
            <Checkbox
              className={styles.checkbox}
              // duplicate
              // weird material UI feature that indeterminate needs to also be checked
              checked={isChecked || isIndeterminate}
              indeterminate={isIndeterminate}
              onChange={this.handleSelectedChange}
            />
          )}

          <ListIcon className={styles.icon} />

          {i18n.t('Work To Do')}
          {/*<IconButton className={styles.button}>
          <FunnelIcon />
        </IconButton>*/}

          {/*<FormControl margin="normal">
                {//<InputLabel htmlFor="filled-age-simple">Type</InputLabel>
              }
                <Select
                  native
                  value={0}
                  //onChange={this.handleChange}
                  input={<FilledInput name="age" id="filled-age-simple" className={styles.textFieldNoLabel} />}
                >
                  {// todo: waste dropdown should include a button to waste by categories menu
                  }
                  <option value={0} key={0}>Toutes les tâches</option>
                </Select>
              </FormControl>*/}
        </div>

        {shouldShowBatchTasksTools ? (
          <div className={styles.buttons}>
            {this.context === Globals.defaultStores && (
              <Button onClick={this.copySelectedTasksToList} className={styles.button}>
                <CopyIcon />
                {i18n.t('Copy to List')}
              </Button>
            )}

            <ConfirmDeleteButton
              onClick={this.deleteTasks(tasksStore.selectedItemsArray)}
              className={styles.button + ' ' + styles.deleteButton}
              text={i18n.t('deleteTask', { count: selectedItems.size })}>
              <DeleteIcon />
            </ConfirmDeleteButton>

            {!isEmpty(selectedTasksMenuItems) && (
              <MenuPopupButton
                className={styles.moreButton}
                menuItems={selectedTasksMenuItems}
              />
            )}
          </div>
        ) : (
          <>
            {isCategoryFilterEnabled && (
              <CategoriesCombobox
                className={styles.filterCombobox}
                categoryTypes={[CategoryType.Task]}
                category={categoriesStore.categoryBeingFilteredIn}
                onChange={this.onChangeCategoryFilter}
                shouldFocusOnMount
              />
            )}

            {/* disabled for now
            <IconButton className={styles.funnelIcon} onClick={this.toggleFilter}
            >
              {isCategoryFilterEnabled
                ? <FunnelRemoveIcon className={categoriesStore.categoryBeingFilteredIn ? styles.funnelRemove : ''} />
                : <FunnelIcon />
              }
            </IconButton>
  */}

            <div className={styles.buttons}>
              {/*<IconButton className={styles.button} onClick={this.toggleDimensionsColumn}>
                <SettingsIcon />
              </IconButton> */
                // should be replaced by clicking on separator bar
              }

              {/*<IconButton className={styles.button} onClick={this.toggleCheckboxes}>
                <CheckboxIcon />
            </IconButton>*/}

              {<IconButton className={styles.button} onClick={this.addCategoryToTasksList}>
                {/*<CategoryAddIcon />*/}
                {<GroupAddIcon />}
                {/*i18n.t('Add a category')*/}
              </IconButton>}

              {hasMoreThanOneTaskCategories && (
                <IconButton
                  className={classnames(
                    styles.button,
                    styles.expandAllButton,
                  )}
                  onClick={categoriesStore.toggleAllTaskCategories}
                >
                  {categoriesStore.areAllVisibleTaskCategoriesCollapsed ? <ExpandAllIcon /> : <CollapseAllIcon />}
                </IconButton>
              )}
            </div>

            {<div className={styles.details}>
              {formatCurrency(price || 0)}
            </div>}
          </>
        )}

      </div>
    );
  }
}