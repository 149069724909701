import { Unit } from 'constants/Unit';
import { UnitType } from 'constants/UnitType';
import { ProvidedQuantity } from 'models/ProvidedQuantity';
import ProvidingItem from 'models/ProvidingItem';
import { roundPrice } from './CurrencyUtil';
import { getUnitTypeForUnit } from './UnitFormatter';

export const handleProvidedQuantityChange = (providingItem: ProvidingItem, providedQuantity: ProvidedQuantity, propertyName: string) => event => {
  let { value, formattedValue } = event.target;
  const { providedQuantities } = providingItem;

  switch (propertyName) {
    case 'quantityFormula':
      providedQuantity.quantityFormula = value;
      break;
    case 'quantity':
      providedQuantity.quantity = formattedValue;
      break;
    case 'unit':
      const newUnitType = getUnitTypeForUnit(value);
      if (newUnitType !== providedQuantity.unitType) {
        providedQuantities.delete(providedQuantity.unitType);
      }
      providedQuantity.unit = value;
      providedQuantity.unitType = getUnitTypeForUnit(value);
      break;
  }

  if (
    propertyName === 'quantity' &&
    providedQuantity.quantity &&
    providedQuantity.unitType === UnitType.Time &&
    providingItem.isLabour &&
    providingItem.price &&
    providingItem.labourRate
  ) {
    // balance out labour rate x labour time = labour total
    providingItem.labourRate = roundPrice(providingItem.price / providedQuantity.quantity);
  }

  providedQuantity.confidencePercentage = 80;

  providedQuantities.set(providedQuantity.unitType, providedQuantity);
};

export function areProvidedQuantitiesObjectsEqual(providedQuantitiesA: Map<UnitType, ProvidedQuantity>, providedQuantitiesB: Map<UnitType, ProvidedQuantity>) {
  return Array.from(providedQuantitiesA.values())
    .every(providedQuantityA => {
      const providedQuantityB = providedQuantitiesB.get(providedQuantityA.unitType);
      return (['quantity', 'confidencePercentage', 'unit'] as (keyof ProvidedQuantity)[]).every(key => providedQuantityA[key] === providedQuantityB[key]);
    })
}

export function getBaseQuantities() {
  return new Map<UnitType, ProvidedQuantity>([[
    UnitType.Unit,
    new ProvidedQuantity(UnitType.Unit, 1, Unit.Unit, 50),
  ]]);
}