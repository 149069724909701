import { SortField } from 'constants/SortField';
import FileSaver from 'file-saver';
import { compact, debounce, deburr, Dictionary, forEach, groupBy, isEmpty, uniq } from 'lodash';
import { action, computed, observable, reaction } from "mobx";
import { computedFn } from 'mobx-utils';
import Category from "models/Category";
import Dialog from 'models/Dialog';
import ModelBase, { DEFAULT_SUBTYPE } from "models/ModelBase";
import ModelBaseWithCategory from "models/ModelBaseWithCategories";
import * as React from 'react';
import { flattenItemsByCategSubcateg, groupItemsByCateg, groupItemsByCategSubcateg } from "utils/CategoryUtil";
import i18n from 'utils/i18n';
import { modelSortFunction } from "utils/Utils";
import FirebaseStore from "./FirebaseStore";
const lunr = require('lunr');
const lunrMutable = require('lunr-mutable-indexes');

const searchFieldExtractor = (field: string) => doc => {
  let value = deburr(doc?.[field] || '');
  if (field == 'id') {
    value = value.split(':')?.[1] || '';
  }

  // remove apostrophes because search works by whole word e.g. d'attaches wouldn't be found by typing attaches
  return deburr(value).replace(/[,''":]/g, " ");
};

// This is only client-side search.. should be backend if too many items
// https://firebase.google.com/docs/firestore/solutions/search
// possibly firebase cache will be enough to keep everything frontend
export default class SearchableFirebaseStore<T extends (ModelBase | ModelBaseWithCategory)> extends FirebaseStore<T> {
  subtypes: string[] = [DEFAULT_SUBTYPE];

  fieldsToIndex = []; // other than usual ones
  searchDelay = 500;

  @observable _isSearchReady = false;

  @computed get isSearchReady() {
    return this.storeBaseInstance ? this.storeBaseInstance.isSearchReady : this._isSearchReady;
  }
  set isSearchReady(value) {
    this._isSearchReady = value;
  }

  _searchIndex;

  get searchIndex() {
    return this.storeBaseInstance ? this.storeBaseInstance.searchIndex : this._searchIndex
  }

  set searchIndex(value) {
    this._searchIndex = value;
  }

  @observable _searchQuery: string = '';
  @observable debouncedSearchQuery: string = '';
  @observable categoryFilter: Category;
  @observable subcategoryFilter: Category;

  @observable shouldSearchId = false;

  @observable sortFields: SortField[] = [];

  @observable _subtypeFilter: string;

  @computed get subtypeFilter(): string {
    return this._subtypeFilter;
  }
  set subtypeFilter(value: string) {
    this._subtypeFilter = value;
    this.selectedItems.clear();
  }

  @computed get searchQuery(): string {
    return this._searchQuery;
  }

  set searchQuery(value: string) {
    if (this._searchQuery !== value) {
      this._searchQuery = value;
      this.setDebouncedSearchQuery(value);
    }
  }

  setDebouncedSearchQuery = debounce(value => {
    this.debouncedSearchQuery = value;
  }, this.searchDelay)

  @computed get preparedSearchQuery(): (q) => any {
    // remove characters that crash search
    let query = deburr(this.debouncedSearchQuery.replace(/[\^|\-:#]/g, ' ').trim().toLowerCase());
    // make query be AND instead of OR

    const fields = this.debouncedSearchQuery.startsWith('#') ? ['id'] : ['name', 'categoryName', 'subcategoryName'];

    return (lunrQuery) => query
      .split(/ |'/)
      .filter(term => term.length > 1)
      .forEach(term => lunrQuery.term(term, {
        fields,
        boost: 10,
        wildcard: lunr.Query.wildcard.TRAILING,
        presence: lunr.Query.presence.REQUIRED
      }))
  }

  public onLoadCompleted() {
    this._searchQuery = '';
    this.debouncedSearchQuery = '';
    super.onLoadCompleted();
  }

  reset() {
    this._searchQuery = '';

    if (!this.storeBaseInstance) {
      this.createSearchIndex();
    }

    super.reset();
  }

  categoryFilterFunction = (categoryFilter = this.categoryFilter, subcategoryFilter = this.subcategoryFilter) =>
    item => (
      !this.hasCategories || (
        item && item.category && (
          // for some reason object category is not always the same, so compare by id (projects view bugs when filter selected and coming back from selected project )
          (!categoryFilter || categoryFilter.id === item.category.id) &&
          (!subcategoryFilter || subcategoryFilter.id === item.subcategory.id)
        )));

  subtypeFilterFunction = (subtypeFilter = this.subtypeFilter) => (
    (item: T) => !subtypeFilter || item.subtype === subtypeFilter
  );

  extraFilterFunction = () => (item) => true;

  createSearchIndex = () => {
    if (this.searchIndex && this.isSearchReady && isEmpty(this.searchIndex.invertedIndex)) {
      return;
    }

    this.isSearchReady = false;

    lunrMutable.Pipeline = lunr.Pipeline;

    // BAD: always recreating the whole index from scratch instead of correctly updating it
    this.searchIndex = lunrMutable(instance => {
      instance.pipeline.remove(lunr.stemmer)
      instance.ref('id');

      if (this.shouldSearchId) {
        instance.field('id', { extractor: searchFieldExtractor('id') });
      }
      instance.field('name', { extractor: searchFieldExtractor('name') });
      instance.field('categoryName', { extractor: searchFieldExtractor('categoryName') });
      instance.field('subcategoryName', { extractor: searchFieldExtractor('subcategoryName') });

      this.fieldsToIndex.forEach(fieldName => {
        instance.field(fieldName, { extractor: searchFieldExtractor(fieldName) });
      })

    });

    this.isSearchReady = true;
  }

  getItemsImpl(): T[] {
    return (
      this.shouldSearchUserItems
        ? [...this.itemsMap.values()]
          .filter(i => [...i.cascadeOrders].some(cascadeOrder => cascadeOrder != this.projectCollectionIndex))
          .map(i => this.userCollectionItemsMap.get(i.id) || i)
        : [...this.itemsMap.values()]
    ).filter(item => item && (!this.shouldHideUnnamedItems || item.name) && !item.isDeleted);
  }

  @computed get itemsByCategSubcateg(): { [index: string]: { [index: string]: T[] } } {
    return groupItemsByCategSubcateg(this.searchedItems);
  }

  @computed get itemsByCateg(): { [index: string]: T[] } {
    return groupItemsByCateg(this.searchedItems);
  }

  @computed get itemsByCategSubcategFlattened() {
    return flattenItemsByCategSubcateg(this.itemsByCategSubcateg, this.stores);
  }

  // 'all' means not only searched items, doesn't mean deleted items too
  @computed get allItemsByCategSubcategFlattened() {
    return flattenItemsByCategSubcateg(groupItemsByCategSubcateg(this.items), this.stores);
  }

  getItemByNameAndPreferredCategory = computedFn((name: string, category: Category) => (
    this.getItemsByName(name).find(item => item.category.id === category.id) ||
    this.getItemByName(name)
  ))
  /*
  getItemByNameAndPreferredCategory = computedFn((name: string, category: Category) => {
    // needs to be only one of the same name per category or can cause cycle dependencies
    const items = this.getItemsByName(name);
    const itemsInSameCateg = items.filter(item => item.category.id === category.id);

    if (itemsInSameCateg.length === 1) {
      return itemsInSameCateg[0];
    } else if (items.length === 1) {
      return items[0];
    } else {
      return null;
    }
  });
*/

  downloadIndex = () => {
    var blob = new Blob(this.searchIndex.toJSON(), { type: "text/plain;charset=utf-8" });
    FileSaver.saveAs(blob, `searchIndex${i18n.language}.json`);
  }

  // indexes are really large, so probably will take as much time downloading it than building it
  applyCachedData(data: any = {}, cascadeOrder = 0) {
    const { routerStore } = this.stores;
    this.isSearchReady = false;


    if (routerStore.isRescueMode) {
      data = {};
    }

    super.applyCachedData(data, cascadeOrder);

    this.isSearchReady = true;

    this.updateIndexWithManyItems(Object.keys(data));
  }

  updateIndexWithManyItems = (keys) => {
    if (!this.searchIndex && !this.storeBaseInstance) {
      this.createSearchIndex();
    }

    keys.forEach((key, index) => {
      // spread out indexing in time
      setTimeout(
        () => {
          if (isEmpty(this.collections)) {
            return;
          }

          const item = this.getItem(key);
          if (!item) {
            return;
          }

          this.searchIndex.update(item);

          if (index === keys.length - 1) {
            console.log('data indexing done - ' + this.storeKey);
          }
        },
        3000 + index * 25
      );
    });
  }

  updateItemsMap(item: T, itemId: ModelId, changeType: string, cascadeOrder: number) {
    const { routerStore } = this.stores;

    const retval = super.updateItemsMap(item, itemId, changeType, cascadeOrder);

    if (routerStore.isRescueMode) {
      return retval;
    }

    if (
      isEmpty(this.collections) ||
      this.shouldSearchUserItems && cascadeOrder === this.projectCollectionIndex
    ) {
      return retval;
    }

    switch (changeType) {
      case 'added':
      case 'modified':
        this.searchIndex.update(item);
        break;
      case 'removed':
        this.searchIndex.remove(item)
        break;
    }

    return retval;
  }

  @action openChangeCategoryDialog = (models = this.selectedItemsArray) => {
    const CategoryMoveDialog = require('components/common/CategoryMoveDialog/CategoryMoveDialog').default;

    const { dialogsStore } = this.stores;

    const newDialog = new Dialog(this.stores);
    newDialog.dialogComponent = ({ open }) => (
      <CategoryMoveDialog
        open={open}
        dialogId={newDialog.id}
        models={models}
      />
    )
    dialogsStore.showDialog(newDialog);
  }

  @computed get searchedItems(): T[] {
    const listen = this.stores.categoriesStore.items;
    const listen2 = this.items.length;

    if (!this.isSearchReady) {
      return [];
    }

    if (isEmpty(this.debouncedSearchQuery)) {
      return this.items
        .filter(this.subtypeFilterFunction())
        .filter(this.categoryFilterFunction())
        .filter(this.extraFilterFunction());
    }

    let results = this.searchIndex.query(this.preparedSearchQuery);

    return results
      .map(result => result.ref)
      .map(id => {
        const retval = (this.shouldSearchUserItems && this.userCollectionItemsMap.get(id)) || this.getItem(id);
        if (!retval) { //not normal unless deleting
          //debugger;
        }
        return retval as T;
      })
      .filter(item => item && !item.deleted && (this.shouldShowHiddenItems || !item.isHidden)) // compact
      .filter(this.subtypeFilterFunction())
      .filter(this.categoryFilterFunction())
      .filter(this.extraFilterFunction());
  }

  @computed get itemsCategories() {
    if (!this.hasCategories) {
      return [];
    }

    return uniq(compact(
      super.items
        .map(item => item.category)
    )).sort(modelSortFunction);
  }

  @computed get subcategoriesByCategory(): Dictionary<Category[]> {
    if (!this.hasCategories) {
      return {};
    }

    const itemsWithCategoryAndSubcategory = this.items
      .filter(item => item.category && item.subcategory);
    const itemsByCategory = groupBy(itemsWithCategoryAndSubcategory, 'category.id');
    const subcategoriesByCategory: Dictionary<Category[]> = {};

    forEach(
      itemsByCategory,
      (group, categoryId) => (
        subcategoriesByCategory[categoryId] =
        uniq(
          group.map(item => item.subcategory),
        ).sort(modelSortFunction)
      )
    );

    return subcategoriesByCategory;
  }

  i18nObserver = reaction(
    () => i18n.language,
    () => {
      if (!this.storeBaseInstance) {
        this.createSearchIndex();
        this.updateIndexWithManyItems(this.itemsIds);
      }
    }
  );

}