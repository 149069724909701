import { UnitType } from "./UnitType";

// when adding a measurementType, make sure to update the function getUnitTypeForMeasurementType
// VERY SIMILAR TO BuiltinMeasurements enum
export enum MeasurementType {
  General = 'General',
  WallLength = 'WallLength',
  WallSurface = 'WallSurface',
  WallHeight = 'WallHeight',
  WallStudsSpacing = 'WallStudsSpacing',
  WallFurringSpacing = 'WallFurringSpacing',
  WallOpeningsTotalWidth = 'WallOpeningsTotalWidth',
  WallOpeningsTotalSurface = 'WallOpeningsTotalSurface',
  WallOpeningsQuantity = 'WallOpeningsQuantity',
  WallCornersQuantity = 'WallCornersQuantity',
  WallExtremitiesQuantity = 'WallExtremitiesQuantity',
  WallStudsQuantity = 'WallStudsQuantity',
  WallFurringLength = 'WallFurringLength',
  FloorSurface = 'FloorSurface',
  CeilingSurface = 'CeilingSurface',
  FloorPerimeter = 'FloorPerimeter',
  CeilingPerimeter = 'CeilingPerimeter',
  CeilingAndWallSurfaces = 'CeilingAndWallSurfaces',
  GeneralLength = 'GeneralLength', // refers the total length of the drawing as opposed to a unit type of length (like feet inchees etc)
  GeneralPerimeter = 'GeneralPerimeter',
  GeneralSurface = 'GeneralSurface',
  Height = 'Height',
  ExcavationOffsetWidth = 'ExcavationOffsetWidth',
  ExcavationSoilDepth = 'ExcavationSoilDepth',
  ExcavationTotalDepth = 'ExcavationTotalDepth',
  ExcavationSidesAngle = 'ExcavationSidesAngle',
  ExcavationDrainageSlope = 'ExcavationDrainageSlope',
  ExcavationTotalVolume = 'ExcavationTotalVolume',
  ExcavationSandVolume = 'ExcavationSandVolume',
  ExcavationSoilVolume = 'ExcavationSoilVolume',
  FoundationFootingPerimeter = 'FoundationFootingPerimeter',
  FoundationFootingHeight = 'FoundationFootingHeight',
  FoundationFootingWidth = 'FoundationFootingWidth',
  FoundationWallWidth = 'FoundationWallWidth',
  FoundationWallVolume = 'FoundationWallVolume',
  // Wall length & height, réutiliser de regular walls pour fondation.... ?
  WindowWidth = 'WindowWidth',
  WindowHeight = 'WindowHeight',
  WindowFraming = 'WindowFraming',
  WindowSurface = 'WindowSurface',
  RoofSlopeRatio = 'RoofSlopeRatio',
  RoofSlopeDirection = 'RoofSlopeDirection',
  RoofRealLength = 'RoofRealLength',
  RoofRealSurface = 'RoofRealSurface',
  DrawingProjection = 'DrawingProjection'
}

export function getUnitTypeForMeasurementType(measurementType: MeasurementType) {
  switch (measurementType) {
    case MeasurementType.WallLength:
    case MeasurementType.WallHeight:
    case MeasurementType.GeneralLength:
    case MeasurementType.GeneralPerimeter:
    case MeasurementType.Height:
    case MeasurementType.WindowWidth:
    case MeasurementType.WindowFraming:
    case MeasurementType.WindowHeight:
    case MeasurementType.WallStudsSpacing:
    case MeasurementType.WallFurringSpacing:
    case MeasurementType.WallOpeningsTotalWidth:
    case MeasurementType.RoofRealLength:
    case MeasurementType.CeilingPerimeter:
    case MeasurementType.FloorPerimeter:
    case MeasurementType.WallFurringLength:
      return UnitType.Length;

    case MeasurementType.WallSurface:
    case MeasurementType.CeilingSurface:
    case MeasurementType.FloorSurface:
    case MeasurementType.CeilingAndWallSurfaces:
    case MeasurementType.GeneralSurface:
    case MeasurementType.WindowSurface:
    case MeasurementType.WallOpeningsTotalSurface:
    case MeasurementType.RoofRealSurface:
      return UnitType.Surface;

    case MeasurementType.WallCornersQuantity:
    case MeasurementType.WallExtremitiesQuantity:
    case MeasurementType.WallOpeningsQuantity:
    case MeasurementType.WallStudsQuantity:
      return UnitType.Unit;

    case MeasurementType.RoofSlopeRatio:
    case MeasurementType.RoofSlopeDirection:
      return UnitType.RoofSlope;

    case MeasurementType.DrawingProjection:
      return UnitType.Projection;

    default:
      return UnitType.Unknown;
  }
}