import BuiltinSubscriptions from 'constants/BuiltinSubscriptions';
import { computed, observable } from 'mobx';
import moment from 'moment';
import { serializable } from 'serializr';
import { localized, localizedList } from 'utils/localized';
import ModelBase from './ModelBase';

export default class Subscription extends ModelBase {
  type = 'Subscription';

  @localized description = '';
  @localizedList features = [];

  @observable @serializable stripePriceId = '';

  @observable @serializable isWeekly = false;

  @observable @serializable monthlyPrice = 0;
  @observable @serializable regularMonthlyPrice = 0;

  @observable @serializable weeklyPrice = 0;
  @observable @serializable regularWeeklyPrice = 0;

  @observable @serializable _expirationDate = '';
  @observable @serializable _startDate = '';

  @computed get price() {
    return this.isWeekly ? this.weeklyPrice : this.monthlyPrice;
  }

  @computed get regularPrice() {
    return this.isWeekly ? this.regularWeeklyPrice : this.regularMonthlyPrice;
  }

  @computed get expirationDate() {
    return moment(this._expirationDate);
  }

  @computed get isTrial() {
    return this.id === BuiltinSubscriptions.Trial;
  }
}