// order is important, last ones overwrite previous ones (unless readonly)
export enum DbLocationType {
    // master/<here> (ex. categories, items)
    Master = 'Master',
    // master/projectid/<here> (ex. projects, tasks shapes and treenodes contained in a taskslist)
  // can't have both masterproject and master location at the same time
    MasterProject = 'MasterProject',
    // user but can in fact be a company
    // always a user, but external to the company
    ExternalUser = 'ExternalUser',
    User = 'User',
    Project = 'Project',
    Custom = 'Custom'
}