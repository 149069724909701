// Any time we use firebase we should import this file too
// To make sure the app is initialized
import environment from 'environment';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import { BatchInstance } from './FirestoreBatchUtil';

const firebaseApp: firebase.app.App = firebase.initializeApp(environment.firebase);

// data loss?
firebase.firestore().settings({
  cacheSizeBytes: 60 * 1048576, // 50 mb
  merge: true
});

// dangerous to use with things like rootNode and default report
// that get created as new if not present in DB, but offline we can't know for sure
// whether they exist in DB or not.
//firebase.firestore().enablePersistence({ synchronizeTabs: true });

export default firebaseApp;

export const functions = firebase.functions();

export const auth = firebase.auth;
export const firestore = firebase.firestore;

export type WriteBatch = BatchInstance;
export type CollectionReference = firebase.firestore.CollectionReference;
export type FieldPath = firebase.firestore.FieldPath;
export type DocumentReference = firebase.firestore.DocumentReference;
export type Query = firebase.firestore.Query;
export type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
export type UserInfo = firebase.User & { parentAccountEmail?: string };



